import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import axios from "axios";
import { City, Country, Education, University } from "../pages/Users/EditEmployee";
import i18n from "../i18n";
import dayjs, { Dayjs } from 'dayjs';
import countries from '../localData/countries.json';

export type EducationType = {
  country: string,
  city: string,
  university: string,
  profession: string,
  degree: string,
  startDate: Dayjs,
  endDate: Dayjs,
}

class EducationStore {
  isLoading: boolean = false;
  ApiStore!: ApiStore;
  countries!: Country[];
  cities!: City[];
  universities!: University[];
  education!: EducationType

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.countries = [];
    this.resetEducation();
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  setCountries = (countries: Country[]) => {
    this.countries = countries;
  }

  setCountry = (country: Country) => {
    this.education = { ...this.education, country: country.name, city: "", university: "" };
    this.getCities()
  }

  setCities = (cities: City[]) => {
    this.cities = cities;
  }

  setCity = (city: City) => {
    this.education = { ...this.education, city: city.name, university: "" };
    this.getUniversities();
  }

  setUniversities = (universities: University[]) => {
    this.universities = universities;
  }

  setUniversity = (university: string) => {
    this.education = { ...this.education, university: university };
  }

  setDegree = (degree: string) => {
    this.education = { ...this.education, degree: degree };
  }

  setProfession = (profession: string) => {
    this.education = { ...this.education, profession: profession };
  }

  setStartYear = (start: Dayjs) => {
    this.education = { ...this.education, startDate: start };
  }

  setEndYear = (end: Dayjs) => {
    this.education = { ...this.education, endDate: end };
  }

  resetEducation = () => {
    this.education = {
      country: "",
      city: "",
      university: "",
      profession: "",
      degree: "",
      startDate: dayjs(),
      endDate: dayjs(),
    };
  }

  getCountries = () => {
    // this.isLoading = true;
    const newCountries = countries.map((country: any) => ({
      code: country.cca2,
      name: country.name.common,
    }));
    this.countries = newCountries;
    return newCountries
    // return this.ApiStore.CommonApiClient.getCountries().then(response => {
    //   const newCountries = response.data.map((country: any) => ({
    //     code: country.cca2,
    //     name: country.name.common,
    //   }));
    //   this.countries = newCountries;
    //   return newCountries
    // }).catch(err => {
    //   console.error(err);
    //   return [];
    // }).finally(() => {
    //   this.isLoading = false;
    // })
  }

  getCities = () => {
    this.isLoading = true;
    this.ApiStore.CommonApiClient.getCities(this.education.country).then(response => {
      const newCities = response.data?.data.map((city: any) => ({
        name: city,
      })).sort((a: any, b: any) => a.name.localeCompare(b.name));
      this.cities = newCities;
      return newCities
    }).catch(err => {
      console.error(err);
      return [];
    }).finally(() => {
      this.isLoading = false;
    })
  }

  getUniversities = () => {
    this.isLoading = true;
    return this.ApiStore.CommonApiClient.getEducation(
      this.education.country,
      this.education.city,
      i18n.language
    ).then(response => {
      const newUniversities = response.data.results.map((entity: any) => ({
        name: entity.name,
      }))
      this.universities = newUniversities;
      return newUniversities
    }).catch(err => {
      console.error(err);
      return [];
    }).finally(() => {
      this.isLoading = false;
    })
  }

  addEducation = async (userId: string) => {
    this.isLoading = true;
    return this.ApiStore.CommonApiClient.addEducation(
      userId,
      this.education.country,
      this.education.city,
      this.education.university,
      this.education.profession,
      JSON.stringify(this.education.degree),
      this.education.startDate.toString(),
      this.education.endDate.toString(),
    ).finally(() => {
      this.isLoading = false;
    });
  };




}
export default EducationStore;
