import { makeAutoObservable } from "mobx";
import { ConfigApi } from "../api/base/ConfigApi";
import CommonApiClient from "../api/common/CommonApiClient";
import EmployeesApiClient from "../api/EmployeesApiClient";
import CompanyApiClient from "../api/CompanyApiClient";
import ProjectsApiClient from "../api/ProjectsApiClient";
import ProjectTaskApiClient from "../api/ProjectTaskApiClient";
import ProjectDocumentsApiClient from "../api/ProjectDocumentsApiClient";
import TimeReportsApiClient from "../api/TimeReportsApiClient";
import EventsApiClient from "../api/EventsApiClient";
import NotificationsApiClient from "../api/NotificationsApiClient";
import TasksApiClient from "../api/TasksApiClient";
import SalaryApiClient from "../api/SalaryApiClient";
import TariffPlansApiClient from "../api/TariffPlansApiClient";
import PaymentApiClient from "../api/PaymentApiClient";
import TestsApiClient from "../api/TestsApiClient";

class ApiStore {
  CommonApiClient!: CommonApiClient;
  EmployeesApiClient!: EmployeesApiClient;
  CompanyApiClient!: CompanyApiClient;
  ProjectsApiClient!: ProjectsApiClient;
  ProjectTaskApiClient!: ProjectTaskApiClient;
  ProjectDocumentsApiClient!: ProjectDocumentsApiClient;
  TimeReportsApiClient!: TimeReportsApiClient;
  EventsApiClient!: EventsApiClient;
  NotificationsApiClient!: NotificationsApiClient;
  TasksApiClient!: TasksApiClient;
  SalaryApiClient!: SalaryApiClient;
  TariffPlansApiClient!: TariffPlansApiClient; 
  PaymentApiClient!: PaymentApiClient;
  TestsApiClient!: TestsApiClient;
  
  configApi: ConfigApi = {} as ConfigApi;

  constructor() {
    makeAutoObservable(this);
    this.CommonApiClient = new CommonApiClient({} as ConfigApi);
  }

  setConfigApi = (configApi: ConfigApi) => {
    this.configApi = configApi;
    this.CommonApiClient = new CommonApiClient(configApi);
    this.EmployeesApiClient = new EmployeesApiClient(configApi);
    this.CompanyApiClient = new CompanyApiClient(configApi);
    this.ProjectsApiClient = new ProjectsApiClient(configApi);
    this.ProjectTaskApiClient = new ProjectTaskApiClient(configApi);
    this.ProjectDocumentsApiClient = new ProjectDocumentsApiClient(configApi);
    this.TimeReportsApiClient = new TimeReportsApiClient(configApi);
    this.EventsApiClient = new EventsApiClient(configApi);
    this.SalaryApiClient = new SalaryApiClient(configApi);
    this.NotificationsApiClient = new NotificationsApiClient(configApi);
    this.TasksApiClient = new TasksApiClient(configApi);
    this.TariffPlansApiClient = new TariffPlansApiClient(configApi); 
    this.PaymentApiClient = new PaymentApiClient(configApi); 
    this.TestsApiClient = new TestsApiClient(configApi); 
  };
}
export default ApiStore;
