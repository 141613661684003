import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout";
import { Form, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../stores/root-store-context";
import { Divider, Flex, Input, Spin, message } from "antd";
import { useTranslation } from "react-i18next";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

const Login = observer(() => {


    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errorMessage, setError] = useState('');
    const [password, setPassword] = useState('string123*A');
    const [isLoading, setIsLoading] = useState(false);
    const { applicationStore, apiStore } = useStore();
    async function handleSubmit(event: any) {
        event.preventDefault();
        setIsLoading(true);
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');
        await applicationStore.login(email as string, password as string);
        setIsLoading(false);
        if (!applicationStore.isAuthenticated) {
            message.open({
                type: 'error',
                content: t('loginError'),
                key: 'checkingTenant'
            });
        } else {
            navigate(`/`);
        }
    }

    const handleSuccess = (response: any) => {
        console.log("Google login success:", response);
        console.log("response.access_token:", response.access_token);
        applicationStore.autorizeGoogle(response.access_token).then((res: any) => {
            console.log("Google login SkTech:");
            console.log(res);
            localStorage.setItem("token", res.jwt);
            if (res.jwt) {
                applicationStore.setConfigApi(res.jwt);
                applicationStore.autorize().then((res: any) => {
                    navigate(`/${applicationStore.company.Tenant}`);
                });
            }
        })
    };

    useEffect(() => {
        if(applicationStore.isAuthenticated){
            navigate(`/${applicationStore.company.Tenant}`);
        }
    }, []);

    const handleFailure = (error: Error) => {
        console.error("Google login failed:", error);
    };

    const CustomGoogleButton = () => {
        const login = useGoogleLogin({
            onSuccess: handleSuccess,
            onError: () => {
                console.error('Login Failed');
            },
        });

        return (
            <button
                onClick={() => login()}
                style={{
                    display: 'flex',
                    padding: '15px 20px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    border: '1px solid #dadce0',
                    gap: 10,
                    height: 65,
                    alignItems: 'center',
                }}
            >
                <div style={{ height: 40 }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ height: 40 }}><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                </div>
                <div>
                    {t("GoogleLogin")}
                </div>
            </button>
        );
    };

    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div>
                <section className="contact-section fix section-padding">
                    <div className="container">
                        <div className="contact-wrapper-2">
                            <div className="row g-4 align-items-center">
                                <div className="col-lg-12">
                                    <div className="col-lg-12">
                                        <Flex className="contact-content center" justify="center" vertical align="center" >
                                            <h2>{t('loginPrompt')}</h2>

                                            <Spin spinning={isLoading}>
                                                <Flex justify="center" align="center" vertical>
                                                    <CustomGoogleButton />
                                                </Flex>
                                                <Divider style={{ borderColor: '#cecece' }}>{t("or")}</Divider>
                                                <p>
                                                    <p>{t('fillRequiredInfo')}</p>
                                                </p>
                                                <form onSubmit={handleSubmit} id="contact-form" className="contact-form-items">
                                                    <div className="row ">
                                                        <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                            <div className="form-clt">
                                                                <span>{t('emailLabel')}*</span>
                                                                <input type="text" name="email" id="email" placeholder={t('emailPlaceholder')} />
                                                            </div>
                                                            <div className="form-clt" style={{ marginTop: 20 }}>
                                                                <span>{t('passwordLabel')}*</span>
                                                                <Input id="password" placeholder={t('passwordPlaceholder')} type="password" onChange={(e) => {
                                                                    setPassword(e.target.value);
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 wow fadeInUp" data-wow-delay=".3s">

                                                        </div>
                                                        <div className="danger">{errorMessage}</div>
                                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", gap: 20, marginTop: 20 }} data-wow-delay=".9s">
                                                            <button type="submit" className="theme-btn" disabled={isLoading}>
                                                                {t('signInButton')} <i className="fa-solid fa-arrow-right-long" />

                                                            </button>
                                                        </div>


                                                    </div>

                                                </form>
                                            </Spin>
                                        </Flex>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
})
export default Login;