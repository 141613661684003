import React, { useEffect, useState } from "react";
import { Button, Card, App, Typography, Popconfirm } from "antd";
import { Test, UserTestAssignment } from "../../stores/TestsStore";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/root-store-context";
import { Employee } from "../../api/EmployeesApiClient";
import { UserAddOutlined } from '@ant-design/icons';
import { toJS } from "mobx";

const { Text, Title } = Typography;

interface StepTwoProps {
    user: Employee,
    setUserAssignments: (userAssignments: UserTestAssignment[]) => void,
    userAssignments: UserTestAssignment[]
}

const TableSuggestTests: React.FC<StepTwoProps> = ({ user, setUserAssignments, userAssignments }) => {
    const { t } = useTranslation();
    const { testsStore, applicationStore } = useStore();
    const { message } = App.useApp();
    const [filteredTests, setFilteredTests] = useState<Test[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const publicTests: Test[] = await testsStore.getAllPublicTests();
            const companyTests: Test[] = await testsStore.getAllCompanyTests(applicationStore.company.Id ?? "");

            const allTests: Test[] = [...publicTests, ...companyTests];
            setFilteredTests(generationNeedTests(allTests));
        };

        fetchData();
    }, []);

    const generationNeedTests = (allTests: Test[]) => {
        const plainUserSkills = toJS(user.userSkills);

        const filteredTests = allTests.filter(t => t.name != null).filter(test =>
            plainUserSkills
                .some(skill => skill.skill.skillId == String(test.skillId))
        );

        return filteredTests;
    };

    const handleAssignmentToUser = async (userId: string, testId: number) => {
        const addAssignment: UserTestAssignment = {
            id: 0,
            test: null,
            testId: testId,
            assignedDate: new Date().toISOString(),
            assignmentStatusId: 1,
            assignedToUser: null,
            assignmentStatus: null,
            assignedToUserId: Number(userId),
            assignedByUserId: Number(applicationStore.user.id),
            testAttempts: []
        };

        try {
            const result = await testsStore.addAssignment(addAssignment);

            if (result == null) {
                message.error(t("Error"));
            } else {
                message.success(t("Success"));

                const newAssignments = [...userAssignments, result];
                const newFilterTests = filteredTests.filter(t => t.id !== testId);

                setFilteredTests(newFilterTests);
                setUserAssignments(newAssignments);
            }

        } catch (error) {
            message.error(t("Error"));
        }
    }

    return (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
                <tr>
                    <th>{t("add_test.Name")}</th>
                    <th>{t("Description")}</th>
                    <th>{t("add_test.Level")}</th>
                    <th>{t("add_test.NumberAttempts")}</th>
                    <th>{t("add_test.NumberQuestions")}</th>
                    <th>{`${t("add_test.MaxTime")} (${t("passage_test.minutes")})`}</th>
                    <th>{t("Actions")}</th>
                </tr>
            </thead>
            <tbody>
                {filteredTests.map((test: Test) => (
                    <tr key={test.id}>
                        <td>{test?.name}</td>
                        <td>{test?.description || t("(no description)")}</td>
                        <td>{test?.level}</td>
                        <td>{test?.numberOfAttempts}</td>
                        <td>{test?.numberOfQuestions}</td>
                        <td>{test?.maxTimeMinutes}</td>
                        <td>
                            <Popconfirm
                                title={t("tests.Delete the assignment?")}
                                okText={t("Yes")}
                                cancelText={t("No")}
                                onConfirm={async () => await handleAssignmentToUser(user.id, test.id)}
                            >
                                <Button
                                    icon={<UserAddOutlined />}
                                    type="text"
                                    shape="circle"
                                />
                            </Popconfirm>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableSuggestTests;