import { Flex, Image } from "antd"
import { Employee } from "../api/EmployeesApiClient"
import { Link } from "react-router-dom"

export const getUserComponent = (user: Employee | undefined, tenant: string | undefined, size: number = 80) => {
    if (user) {
        return <Flex align="center">
            {size >= 80 &&
                <Image
                    width={size}
                    height={size}
                    style={{ borderRadius: "50%", objectFit: "cover", minWidth: size }}
                    src={user.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${user.photoId}` :
                        `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                    }
                />}
            <Flex vertical justify="start" gap={5} style={{ marginLeft: 20 }}>
                <Link to={`/${tenant}/employees/${user.id}`}>{user.isApproveInvite === false ? "" : user.firstName + " " + user.lastName}</Link>
                {size >= 80 &&
                    <>
                        <div>{user.email}</div>
                        <div>{user.isApproveInvite === false ? "" : user.phoneNumber}</div>
                    </>
                }
            </Flex>
        </Flex>
    } else {
        return <></>
    }

}