import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Spin, Table, TableProps, Typography, App, Card, TabsProps, Tabs, Upload, UploadFile, UploadProps } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, SettingOutlined, EditOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import { NewProject, Project } from "../../stores/ProjectsStore";
import { Roles } from "../../stores/EmployeesStore";
import ReactQuill from "react-quill";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { ProjectDocument } from "../../stores/ProjectDocumentationStore";
import { useTranslation } from "react-i18next";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { handleDownload } from "../../helpers/upload";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const { Text } = Typography;

// Создаем отдельный компонент для редактора
const DocumentEditor = ({ 
    initialContent, 
    onChange 
}: { 
    initialContent?: string, 
    onChange: (content: string) => void 
}) => {
    const [editorState, setEditorState] = useState<EditorState | null>(null);

    useEffect(() => {
        // Инициализируем редактор с небольшой задержкой
        setTimeout(() => {
            let newEditorState;
            if (initialContent) {
                const contentBlock = htmlToDraft(initialContent);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    newEditorState = EditorState.createWithContent(contentState);
                } else {
                    newEditorState = EditorState.createEmpty();
                }
            } else {
                newEditorState = EditorState.createEmpty();
            }
            setEditorState(newEditorState);
        }, 100);
    }, [initialContent]);

    const handleEditorStateChange = (newState: EditorState) => {
        setEditorState(newState);
        const htmlContent = draftToHtml(convertToRaw(newState.getCurrentContent()));
        onChange(htmlContent);
    };

    if (!editorState) {
        return <div>Loading editor...</div>;
    }

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3'], //, 'Blockquote', 'Code'
                },
                fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                },
            }}
            editorStyle={{
                height: '400px',
                maxHeight: '400px',
                overflowY: 'auto',
                border: '1px solid #F1F1F1',
                padding: '5px'
            }}
        />
    );
};

const ProjectPage = observer(({ isEditMode }: { isEditMode: boolean }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { id, documentId } = useParams();

    const { applicationStore, projectsStore, projectDocumentationStore } = useStore();

    const [project, setProject] = useState<Project>();
    const [documents, setDocuments] = useState<TabsProps['items']>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const [currentTab, setCurrentTab] = useState("");
    const [currentFileList, setCurrentFileList] = useState<any[]>([]);

    const [editorContent, setEditorContent] = useState('');

    const [fileConflictData, setFileConflictData] = useState<{ file: UploadFile, id: string } | null>(null);

    const [isEditorReady, setIsEditorReady] = useState(false);

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        let fileList = [...info.fileList];
        const formData = new FormData();
        console.log(fileList);
    };

    const beforeUpload = (file: RcFile, allFiles: UploadFile[]) => {
        const isLt2M = file.size / 1024 / 1024 < 2000;
        if (!isLt2M) {
            message.error('Image must smaller than 2000MB!');
        }
        console.log(file);

        return isLt2M;
    };

    const handleUpload = async ({ file, onSuccess, onError }: any) => {
        const formData = new FormData();
        formData.append('file', file);

        if (projectDocumentationStore.document?.id) {
            try {
                const result = await projectDocumentationStore.uploadFile(file, projectDocumentationStore.document.id);
                updateCurrentFileList();
                onSuccess(result);
            } catch (error: any) {
                if (error?.response?.status === 409) {
                    setFileConflictData({ file, id: projectDocumentationStore.document.id });
                } else {
                    console.error(error);
                    onError(error);
                }
            }
        }
    }
    const handleAttachmentDeleting = ({ publicId }: any) => {
        projectDocumentationStore.deleteAttachmentByPublicId(publicId);
        updateCurrentFileList();
    }

    const showModal = (isNewSection: boolean = false) => {
        setIsModalOpen(true);
        if (isNewSection) {
            form.resetFields();
            projectDocumentationStore.setDocument(null);
            navigate(`/${applicationStore.company?.Tenant}/projects/${id}/new_section`);
        }
    };

    const hideModal = () => {
        setIsModalOpen(false);
        projectDocumentationStore.setDocument(null);
        localStorage.removeItem("activeTabId");
        if (documentId === 'new_section') {
            navigate(`/${applicationStore.company?.Tenant}/projects/${id}`, { replace: true });
        } else {
            navigate(`/${applicationStore.company?.Tenant}/projects/${id}/${documentId || ""}`, { replace: true });
        }
    };

    const deleteSection = () => {
        message.open({
            type: 'loading',
            content: 'Deleting...',
            key: 'Deleting'
        });
        if (projectDocumentationStore.document?.id) {
            projectDocumentationStore.deleteById(projectDocumentationStore.document?.id).then(() => {
                hideModal();
            }).finally(() => {
                updatePage();
                message.destroy("Deleting");
                message.success("The section was deleted");
            })
        }
    };

    const getFiles = () => {
        if (currentTab && currentTab !== 'new_section') {
            projectDocumentationStore.getFileList(currentTab).then((res) => {
                const fileList = res.map(file => {
                    return {
                        uid: file,
                        name: file.split('/').pop(),
                        status: 'done',
                        url: `http://res.cloudinary.com/dnl3x07wo/raw/upload/v1722014093/${file}`
                    }
                })
                setCurrentFileList(fileList);
            })
        }
    }

    const downloadDocumentationExport = (typeDocument: string) => {
        if (project?.id) {
            setIsExporting(true);
            projectDocumentationStore.exportDocumentation(project?.id, typeDocument).then((res) => {
                console.log("downloadDocumentationExport");
                console.log(res);
            }).finally(() => {
                setIsExporting(false);
            })
        }

    }

    const updateCurrentFileList = () => {
        if (currentTab && currentTab !== 'new_section') {
            projectDocumentationStore.downloadAttachmentsByDocumentId(currentTab).then((res) => {
                const fileList = res.map(file => {
                    return {
                        uid: file,
                        name: file.split('/').pop(),
                        status: 'done',
                        url: `http://res.cloudinary.com/dnl3x07wo/raw/upload/v1722014093/${file}`
                    }
                })
                setCurrentFileList(fileList);
            })
        }
    }
    const onChangeTabs = (key: string) => {
        if (key === 'new_section') {
            return;
        }
        setCurrentTab(key);
        navigate(`/${applicationStore.company?.Tenant}/projects/${id}/${key}`);
        const currentDocument = documents?.find(doc => doc.key === key);
        if (currentDocument) {
            const contentElement = currentDocument.children as React.ReactElement;
            if (contentElement && contentElement.props && contentElement.props.dangerouslySetInnerHTML) {
                setEditorContent(contentElement.props.dangerouslySetInnerHTML.__html);
            }
        }
    };

    const updatePage = () => {
        if (id) {
            projectsStore.getById(id).then(res => {
                setProject(res);
            }).catch(error => {
                message.error(error.message);
            });

            projectDocumentationStore.getByProjectId(id).then(res => {
                if (res.length > 0) {
                    const activeTabId = documentId || localStorage.getItem("activeTabId");
                    if (activeTabId && activeTabId !== 'new_section') {
                        setCurrentTab(activeTabId);
                        projectDocumentationStore.downloadAttachmentsByDocumentId(activeTabId);
                        onChangeTabs(activeTabId);
                        const currentDocument = res.find(doc => doc.id === activeTabId);
                        if (currentDocument) {
                            setEditorContent(currentDocument.content || "");
                        }
                    }
                } else {
                    setDocuments([]);
                    console.log("There are no sections available");
                }

                setDocuments(res?.map((document, index) => {
                    return {
                        key: document.id?.toString() || Math.random() + index.toString(),
                        label: document.title,
                        children: <div>
                            {(applicationStore.user.roleId !== Roles.Viewer) &&
                                <Button
                                    type="primary"
                                    shape="circle"
                                    style={{ position: "absolute", right: 0 }}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        navigate(`/${applicationStore.company?.Tenant}/projects/${id}/${document.id}/edit`);
                                        loadDocumentAndOpenModal(document.id as string);
                                    }}
                                />
                            }
                            <div className="document-content">
                                <div className="html-content" style={{ minHeight: 500, paddingTop: 30 }} 
                                    dangerouslySetInnerHTML={{ __html: document.content }} />
                            </div>
                        </div>
                    };
                }));
            }).catch(error => {
                message.error(error.message);
            });
        }
    };


    const onFinish: FormProps<ProjectDocument>['onFinish'] = async (section: ProjectDocument) => {
        message.open({
            type: 'loading',
            content: 'Saving section...',
            key: 'task'
        });

        if (section.id) {
            await projectDocumentationStore.edit(section).then(async (response) => {
                message.destroy("task");
                message.success("The section was saved");
                await projectDocumentationStore.getByProjectId(id as string).catch(error => {
                    message.error(error.message);
                });
                form.resetFields();
                hideModal();
            }).catch(error => {
                message.destroy("task");
                message.error(error.message);
            });
        } else {
            await projectDocumentationStore.createNew(section)
                .then(async (response) => {
                    message.destroy("task");
                    message.success("The section was saved");

                    const newDocumentId = response.id;
                    localStorage.setItem("activeTabId", newDocumentId);
                    setCurrentTab(newDocumentId);
                    updateCurrentFileList();

                    await projectDocumentationStore.getByProjectId(id as string).catch(error => {
                        message.error(error.message);
                    });
                    form.resetFields();
                    hideModal();
                }).catch(error => {
                    message.destroy("task");
                    message.error(error.message);
                });
        }
        updatePage();
    };

    useEffect(() => {
        updatePage();
    }, [id]);

    useEffect(() => {
        if (documentId) {
            if (documentId === 'new_section') {
                setCurrentTab('');
                setCurrentFileList([]);
            } else {
                setCurrentTab(documentId);
            }
        } else {
            setCurrentTab('');
        }
    }, [documentId]);

    useEffect(() => {
        getFiles();
    }, [currentTab]);

    const loadDocumentAndOpenModal = async (documentId: string) => {
        if (documentId === 'new_section') {
            return;
        }

        try {
            const res = await projectDocumentationStore.getByProjectId(id as string);

            if (!res || res.length === 0) {
                message.error("No documents found for the project.");
                return;
            }
            const numericDocumentId = parseInt(documentId, 10);
            const currentDocument = res.find(doc => Number(doc.id) === numericDocumentId);

            if (currentDocument) {
                // Сначала открываем модальное окно
                showModal();
                
                // Затем устанавливаем значения с небольшой задержкой
                setTimeout(() => {
                    projectDocumentationStore.setDocument(currentDocument);
                    form.setFieldsValue(currentDocument);
                    setEditorContent(currentDocument.content || "");
                }, 100);

                navigate(`/${applicationStore.company?.Tenant}/projects/${id}/${documentId}/edit`);
            } else {
                console.warn("Document not found in the response:", { documentId, res });
                message.error("Document not found");
            }
        } catch (error: any) {
            message.error(`Error loading document: ${error.message}`);
        }
    };

    useEffect(() => {
        if (isEditMode && documentId) {
            if (documentId === 'new_section') {
                showModal(true);
            } else {
                setTimeout(() => {
                    loadDocumentAndOpenModal(documentId);
                }, 0);
            }
        }
    }, [isEditMode, documentId, id]);

    useEffect(() => {
        if (!isModalOpen) {
            setIsEditorReady(false);
        }
    }, [isModalOpen]);

    const FileConflictModal = () => {
        if (!fileConflictData) return null;

        const handleCancel = () => {
            setFileConflictData(null);
            message.info(t('UploadCancelled'));
        };

        return (
            <Modal
                title={t('FileAlreadyExists')}
                open={!!fileConflictData}
                footer={null}
                onCancel={handleCancel}
                maskClosable={false}
            >
                <div>
                    <p>{t('FileExist', { existFileName: fileConflictData.file.name })}</p>

                    <Flex justify="space-between" style={{ marginTop: 24 }}>
                        <Button
                            onClick={handleCancel}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={async () => {
                                try {
                                    if (fileConflictData) {
                                        await projectDocumentationStore.ApiStore.ProjectDocumentsApiClient
                                            .uploadDocumentationAttachment(
                                                fileConflictData.file,
                                                fileConflictData.id,
                                                true
                                            );
                                        updateCurrentFileList();
                                        message.success(t('UploadSuccess'));
                                    }
                                } catch (error) {
                                    console.error(error);
                                    message.error(t('UploadFailed'));
                                } finally {
                                    setFileConflictData(null);
                                }
                            }}
                        >
                            {t('Replace')}
                        </Button>
                    </Flex>
                </div>
            </Modal>
        );
    };

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`Project`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <style>
                    {`
                .document-content ol, 
                .document-content ul {
                    margin: 0;
                    padding-left: 20px;
                }
                .document-content ul li {
                    list-style-type: disc;
                }
                .document-content ol li {
                    list-style-type: decimal;
                }
                .document-content ol {
                    list-style-type: decimal;
                }
                
                .document-content ol ol {
                    list-style-type: lower-alpha;
                    margin-left: 20px;
                }
                .document-content ul .ql-indent-1, 
                .document-content ol .ql-indent-1 {
                    list-style-type: lower-alpha;
                    margin-left: 20px;
                }
                .document-content ul .ql-indent-2, 
                .document-content ol .ql-indent-2 {
                    list-style-type: lower-roman;
                    margin-left: 50px;
                }
                .document-content ul .ql-indent-3, 
                .document-content ol .ql-indent-3 {
                    list-style-type: decimal;
                    margin-left: 80px;
                }
                .document-content ol .ql-indent-1, 
                .document-content ol .ql-indent-2, 
                .document-content ol .ql-indent-3 {
                    counter-reset: list-counter;
                }
                .document-content ol .ql-indent-1 > li::before {
                    content: counter(list-counter, lower-alpha) ". ";
                    counter-increment: list-counter;
                }
                `}
                </style>
                <Spin spinning={projectsStore.isLoading || projectDocumentationStore.isLoading}>
                    {project ? <>
                        <Card style={{ margin: "20 0", textAlign: "center" }}>
                            <Flex justify="space-between">
                                <Button type="primary"
                                    style={{ float: "right", marginBottom: 20, borderRadius: 0 }}
                                    onClick={() => {
                                        navigate(`/${applicationStore.company?.Tenant}/projects`);
                                    }}>
                                    {t("BackToProjects")}
                                </Button>
                                <Flex vertical gap={10}>
                                    <h4 title="Project name" >{project?.title}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: project.description }} />
                                    <Text type="success">{t("ProjectStarted")}: {project?.startDate.format('YYYY-MM-DD')}</Text>
                                </Flex>
                                {((applicationStore.user.roleId !== Roles.Viewer)) &&
                                    <Button type="primary" shape="circle" icon={<SettingOutlined />}
                                        disabled={
                                            !(
                                                [Roles.Owner, Roles.Manager].includes(applicationStore.user.roleId) ||
                                                project.members?.some(
                                                    member => member.id === applicationStore.user.id && [Roles.Owner, Roles.Manager].includes((member as any).role.id)
                                                )
                                            )
                                        }
                                        onClick={() => {
                                            navigate(`/${applicationStore.company?.Tenant}/projects/${id}/settings`);
                                        }} />
                                }

                            </Flex>
                            <Spin spinning={isExporting}>
                                <Flex gap={10} align="end" justify="end">
                                    <Button type="dashed" onClick={() => {
                                        downloadDocumentationExport("pdf")
                                    }}>
                                        {t("DownloadIn", { format: "PDF" })}
                                    </Button>
                                    <Button type="dashed" onClick={() => {
                                        downloadDocumentationExport("docx")
                                    }}>
                                        {t("DownloadIn", { format: "DOCX" })}

                                    </Button>
                                </Flex>
                            </Spin>
                        </Card>
                    </> : <></>}
                    <>
                        <Tabs
                            activeKey={currentTab}
                            items={documents}
                            type="card"
                            onChange={onChangeTabs}
                            tabPosition={"left"}
                            style={{ minHeight: 500, margin: "20px 0" }}
                            tabBarStyle={{ width: 200, maxHeight: 500 }}
                            tabBarExtraContent={
                                <>
                                    {(applicationStore.user.roleId !== Roles.Viewer) &&
                                        <Button type="primary" style={{ marginBottom: 20, borderRadius: 0, width: 200 }} onClick={() => showModal(true)}>
                                            {t("Add_new_section")}
                                        </Button>
                                    }
                                </>
                            } />
                        <Flex >
                            <div style={{ minWidth: 200 }}></div>
                            <Spin spinning={projectDocumentationStore.isLoadingAttachments}>
                                <Upload
                                    listType='picture-card'
                                    fileList={currentFileList}
                                    className='upload-list-inline'
                                    onDownload={handleDownload}
                                    showUploadList={{
                                        showRemoveIcon: false,
                                        showDownloadIcon: true,
                                        downloadIcon: <DownloadOutlined />
                                    }}
                                // itemRender={customItemRender}
                                >
                                </Upload>
                            </Spin>
                        </Flex>
                    </>
                </Spin>
            </div>
            <Modal title={projectDocumentationStore.document === null ? t("CreateSection") : t("EditSection")} open={isModalOpen} footer={null} onCancel={hideModal} width={900}
                style={{ padding: 10 }}>
                <Form form={form} onFinish={onFinish} initialValues={{ projectId: id, creatorId: applicationStore.user.id }} layout="vertical">
                    <Form.Item<ProjectDocument> name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> name="projectId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> name="creatorId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item<ProjectDocument> label="Title" name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item<ProjectDocument> label="Content" name="content" style={{ width: "100%", marginBottom: 30 }} rules={[{ required: true }]} >
                        <DocumentEditor
                            initialContent={projectDocumentationStore.document?.content}
                            onChange={(content) => {
                                form.setFieldValue('content', content);
                                setEditorContent(content);
                            }}
                        />
                    </Form.Item>
                    <Flex style={{ marginTop: 60 }} >
                        <Spin spinning={projectDocumentationStore.isLoadingAttachments}>
                            <Upload
                                fileList={currentFileList}
                                customRequest={handleUpload}
                                onChange={handleChange}
                                beforeUpload={beforeUpload}
                                listType="picture"
                                onRemove={(e) => {
                                    console.log("onRemove");
                                    console.log(e);
                                    handleAttachmentDeleting({ publicId: e.uid });
                                }}
                                onDrop={(e) => {
                                    console.log("onDrop");
                                    console.log(e);
                                }}
                                onDownload={handleDownload}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Spin>
                    </Flex>
                    <Form.Item style={{ margin: 0 }}>
                        <Flex justify="space-between">
                            <Popconfirm
                                title="Delete the section"
                                description="Are you sure to delete this section?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={deleteSection}
                            >
                                {projectDocumentationStore.document !== null &&
                                    <Button type="default" danger style={{ height: 65, marginTop: 30 }}>
                                        {t('DeleteSection')} <DeleteOutlined style={{ fontSize: 20, marginLeft: 5 }} /></Button>
                                }
                            </Popconfirm>
                            <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, marginTop: 30, display: "flex" }}>
                                {t('SaveSection')} <SaveOutlined style={{ fontSize: 20, marginLeft: 5 }} />
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>
            <FileConflictModal />
        </Layout >
    )
})
export default ProjectPage;