import { observer } from "mobx-react-lite";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { LevelSettings, QuestionForm } from "../../pages/Tests/AddTest";
import { useEffect, useState } from "react";

const defaultValueQuestion: QuestionForm = {
    question: '',
    options: [],
    prompt: '',
    numberPoints: 0,
    code: '',
    correctAnswer: '',
    type: 'singleChoice',
}

export const AddTestFormQustions = observer(({ questions, setQuestions, handleFinish }:
    {
        questions: QuestionForm[],
        setQuestions: (questions: QuestionForm[]) => void;
        handleFinish: () => void;
    }) => {
    const [formQuestions] = Form.useForm();
    const { t } = useTranslation();

    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newQuestionType, setNewQuestionType] = useState<'singleChoice' | 'multipleChoice' | 'writeCode' | 'fixCode' | 'openAnswer'>('singleChoice');
    const [newQuestion, setNewQuestion] = useState<QuestionForm>(defaultValueQuestion);

    const handleAddQuestion = () => {
        setIsModalVisible(true);
    };

    const handleEditQuestion = (index: number) => {
        setEditingIndex(index);
        setNewQuestion(questions[index]);
        setNewQuestionType(questions[index].type);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (editingIndex != null) {
            const updatedQuestions = questions.map((question, index) =>
                index == editingIndex ? newQuestion : question
            );
            setQuestions(updatedQuestions);
        } else {
            const updatedQuestions = [...questions, newQuestion];
            setQuestions(updatedQuestions);
        }

        setIsModalVisible(false);
        setNewQuestion(defaultValueQuestion);
        setEditingIndex(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setNewQuestion(defaultValueQuestion);
        setEditingIndex(null);
    };

    const handleQuestionChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: string
    ) => {
        setNewQuestion(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };

    const handleRemoveOption = (index: number) => {
        setNewQuestion(prevState => ({
            ...prevState,
            options: prevState.options!.filter((_, i) => i !== index),
        }));
    };

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedOptions = [...newQuestion.options!];
        updatedOptions[index] = e.target.value;
        setNewQuestion(prevState => ({
            ...prevState,
            options: updatedOptions
        }));
    };

    const handleTypeChange = (value: 'singleChoice' | 'multipleChoice' | 'writeCode' | 'fixCode' | 'openAnswer') => {
        setNewQuestionType(value);
        setNewQuestion(prevState => ({
            ...prevState,
            type: value,
            options: value === "singleChoice" || value === "multipleChoice" ? prevState.options || [] : undefined,
            code: value === "fixCode" ? prevState.code : '',
        }));
    };


    const handleDelete = (index: number) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };


    useEffect(() => {
        formQuestions.setFieldsValue({
            questions: questions.map((q) => ({
                question: q.question,
                options: q.options,
                prompt: q.prompt,
                code: q.code,
                correctAnswer: q.correctAnswer,
            })),
        });
    }, [questions, formQuestions]);

    return (
        <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px" }}>
            <div style={{ marginBottom: '20px', textAlign: "left" }}>
                <Button
                    type="primary"
                    onClick={handleAddQuestion}
                >
                    {t("add_test.createQuestion")}
                </Button>
            </div>
            <Form layout="vertical" form={formQuestions}>
                {questions.map((question, index) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: "30px",
                            padding: "20px",
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                            backgroundColor: "#fafafa",
                            position: "relative",
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            top: '6px',
                            right: '55px',
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <InputNumber
                                min={1}
                                max={5}
                                value={question.numberPoints ?? 1}
                                onChange={(value) => {
                                    const updatedQuestions = [...questions];
                                    updatedQuestions[index].numberPoints = value || 1;
                                    setQuestions(updatedQuestions);
                                }}
                                style={{ width: '50px' }}
                            />
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <EditOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditQuestion(index)}
                            />
                            <DeleteOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDelete(index)}
                            />
                        </div>

                        <Form.Item
                            label={`${t("add_test.Question")} ${index + 1}`}
                            name={["questions", index, "question"]}
                            rules={[
                                {
                                    required: true,
                                    message: t("add_test.Please input the question!"),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t("add_test.EnterYourQuestion")}
                                onChange={(e) => {
                                    const updatedQuestions = [...questions];
                                    updatedQuestions[index].question = e.target.value;
                                    setQuestions(updatedQuestions);
                                }}
                            />
                        </Form.Item>

                        {question.type === "singleChoice" || question.type === "multipleChoice" ? (
                            <>
                                <div style={{ marginTop: "15px" }}>
                                    {question.options?.map((option, optionIndex) => (
                                        <Form.Item
                                            key={optionIndex}
                                            label={`${t("add_test.Option")} ${optionIndex + 1}`}
                                            name={["questions", index, "options", optionIndex]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("add_test.Please input the option!"),
                                                },
                                            ]}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Input
                                                placeholder={t("add_test.EnterOption", { index: optionIndex + 1 })}
                                                onChange={(e) => {
                                                    const updatedQuestions = [...questions];
                                                    updatedQuestions[index].options![optionIndex] = e.target.value;
                                                    setQuestions(updatedQuestions);
                                                }}
                                            />
                                        </Form.Item>
                                    ))}
                                </div>

                                <Form.Item
                                    label={t("add_test.CorrectAnswer")}
                                    name={["questions", index, "correctAnswer"]}
                                    initialValue={question.correctAnswer}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("add_test.Please select the correct answer(s)!"),
                                        },
                                    ]}
                                >
                                    <Select
                                        mode={question.type === "multipleChoice" ? "multiple" : undefined}
                                        placeholder={t("add_test.SelectCorrectAnswer")}
                                        onChange={(value) => {
                                            const updatedQuestions = [...questions];
                                            updatedQuestions[index].correctAnswer = value;
                                            setQuestions(updatedQuestions);
                                        }}
                                    >
                                        {question.options?.map((option, optionIndex) => (
                                            <Select.Option key={optionIndex} value={option}>
                                                {option}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>
                        ) : null}

                        {question.type === "writeCode" && (
                            <Form.Item
                                label={t("add_test.WriteCodePrompt")}
                                name={["questions", index, "prompt"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("add_test.Please input the code prompt!"),
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    placeholder={t("add_test.EnterCodePrompt")}
                                    rows={5}
                                    onChange={(e) => {
                                        const updatedQuestions = [...questions];
                                        updatedQuestions[index].prompt = e.target.value;
                                        setQuestions(updatedQuestions);
                                    }}
                                />
                            </Form.Item>
                        )}

                        {question.type === "openAnswer" && (
                            <Form.Item
                                label={t("add_test.WriteOpenAnswer")}
                                name={["questions", index, "prompt"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("add_test.Please input the code prompt!"),
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    placeholder={t("add_test.EnterCodePrompt")}
                                    rows={5}
                                    onChange={(e) => {
                                        const updatedQuestions = [...questions];
                                        updatedQuestions[index].correctAnswer = e.target.value;
                                        setQuestions(updatedQuestions);
                                    }}
                                />
                            </Form.Item>
                        )}

                        {question.type === "fixCode" && (
                            <>
                                <Form.Item
                                    label={t("add_test.Code")}
                                    name={["questions", index, "code"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("add_test.Please input the code with placeholders!"),
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        placeholder={t("add_test.EnterCodeWithPlaceholders")}
                                        rows={5}
                                        onChange={(e) => {
                                            const updatedQuestions = [...questions];
                                            updatedQuestions[index].code = e.target.value;
                                            setQuestions(updatedQuestions);
                                        }}
                                    />
                                </Form.Item>
                            </>
                        )}
                    </div>
                ))}
                <div style={{ display: "flex", justifyContent: "right" }}>
                    <Button type="primary" onClick={handleFinish}>
                        {t("add_test.Submit")}
                    </Button>
                </div>
            </Form>

            <Modal
                title={t("add_test.CreateNewQuestion")}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form>
                    <Form.Item label={t("add_test.SelectQuestionType")}>
                        <Select
                            value={newQuestionType}
                            onChange={handleTypeChange}
                        >
                            <Select.Option value="singleChoice">{t("add_test.SingleChoice")}</Select.Option>
                            <Select.Option value="multipleChoice">{t("add_test.MultipleChoice")}</Select.Option>
                            <Select.Option value="writeCode">{t("add_test.WriteCode")}</Select.Option>
                            <Select.Option value="fixCode">{t("add_test.fixCode")}</Select.Option>
                            <Select.Option value="openAnswer">{t("add_test.openAnswer")}</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label={t("add_test.Question")}>
                        <Input
                            value={newQuestion.question}
                            onChange={(e) => handleQuestionChange(e, 'question')}
                        />
                    </Form.Item>

                    {newQuestionType == "singleChoice" || newQuestionType == "multipleChoice" ? (
                        <Form.Item label={t("add_test.Options")}>
                            {Array.isArray(newQuestion.options) && newQuestion.options.map((option, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <Input
                                        value={option}
                                        onChange={(e) => handleOptionChange(e, index)}
                                        placeholder={t("add_test.Option")}
                                        style={{ flex: 1 }}
                                    />
                                    <DeleteOutlined
                                        onClick={() => handleRemoveOption(index)}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                onClick={() =>
                                    setNewQuestion(prevState => ({
                                        ...prevState,
                                        options: [...(prevState.options || []), ''],
                                    }))
                                }
                            >
                                {t("add_test.AddOption")}
                            </Button>
                        </Form.Item>
                    ) : null}

                    {newQuestionType === "fixCode" ? (
                        <>
                            <Form.Item label={t("add_test.FillCodePrompt")}>
                                <Input.TextArea
                                    rows={5}
                                    value={newQuestion.code}
                                    onChange={(e) => handleQuestionChange(e, 'code')}
                                />
                            </Form.Item>
                        </>
                    ) : null}
                </Form>
            </Modal>
        </div>
    )
});