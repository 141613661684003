import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Typography, Space, Modal, message, Tabs, Input, Form, Table, Pagination } from "antd";
import { useStore } from "../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import { CheckOutlined } from "@ant-design/icons";
import Layout from "../../components/layout/Layout";
import dayjs from 'dayjs';
import GooglePayButton from '@google-pay/button-react';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;
const currencySymbol = "₴";

const PaymentAndTariffs = observer(() => {
  const { tariffPlansStore, paymentStore, applicationStore } = useStore();
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [confirmedPlan, setConfirmedPlan] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [topUpAmount, setTopUpAmount] = useState<number>(0);
  const pageSize = 4;
  const { t } = useTranslation();
  const currentTariffPlan = tariffPlansStore.tariffPlans.find(
    (plan) => plan.id === applicationStore.company?.TariffPlanId
  );
  const trialEndDate = paymentStore.trialDate ? paymentStore.trialDate.format('YYYY-MM-DD') : null;

  useEffect(() => {
    tariffPlansStore.getTariffPlans();

    if (applicationStore.company?.Id) {
      paymentStore.getBalance(applicationStore.company.Id);
      paymentStore.getPaymentDate(applicationStore.company.Id);
      paymentStore.getTransactionHistory(applicationStore.company.Id);
    }
    const companyTariffPlanId = applicationStore.company?.TariffPlanId;
    if (companyTariffPlanId) {
      setConfirmedPlan(companyTariffPlanId);
    }
  }, [tariffPlansStore, applicationStore, paymentStore]);

  const selectPlan = (planId: string) => {
    setSelectedPlan(planId);
    setIsModalVisible(true);
  };

  const handleSelectPlan = async (planId: number) => {
    console.log(planId);
    const currentTariffPlanId = applicationStore.company?.TariffPlanId;

    if (currentTariffPlanId === planId) {
      message.info(t("topUpBalanceInfo"));
      await initiatePayment(planId);
    } else {
      const paymentDate = paymentStore.paymentDate;
      const currentDate = dayjs();

      if (paymentDate.isAfter(currentDate)) {
        message.warning(t("switchTariffWarning"));
      } else {
        try {
          const changeTariffResponse = await paymentStore.changeTariffPlan(applicationStore.company?.Id || "", planId);
          if (!changeTariffResponse.isSuccess) {
            message.error(t("changeTariffError"));
            return;
          }
        } catch (error) {
          console.error(t("changeTariffError"), error);
          message.error(t("changeTariffError"));
          return;
        }
        message.info(t("tariffChanged"));
        await initiatePayment(planId);
      }
    }
  };


  const initiatePayment = async (planId: number) => {
    try {
      console.log(planId);
      const formData = {
        amount: planId === 1 ? 415 : 4150,
        currency: "UAH",
        description: planId === 1 ? "Monthly Plan" : "Yearly Plan",
        order_id: `order_${Date.now()}`,
      };

      const res = await paymentStore.createLiqPayRequest(applicationStore.company?.Id || "", formData);

      if (res.formHtml) {
        const paymentContainer = document.createElement("div");
        paymentContainer.innerHTML = res.formHtml;
        document.body.appendChild(paymentContainer);
        const form = paymentContainer.querySelector("form");
        if (form) {
          form.submit();
        } else {
          message.error("Error generating payment form");
        }
      } else {
        message.error("Error initiating payment");
      }
    } catch (error) {
      console.error("Payment error:", error);
      message.error("Failed to initiate payment");
    }
  };
  // const handlePayment = async () => {
  //   if (topUpAmount <= 0) {
  //     message.error("Please enter a valid amount");
  //     return;
  //   }

  //   try {
  //     const formData = {
  //       amount: topUpAmount,
  //       currency: "USD",
  //       description: "Top-up Balance",
  //       order_id: `order_${Date.now()}`,
  //     };

  //     const res = await paymentStore.createLiqPayRequest(applicationStore.company.Id || "", formData);

  //     if (res.formHtml) {
  //       const paymentContainer = document.createElement("div");
  //       paymentContainer.innerHTML = res.formHtml;
  //       document.body.appendChild(paymentContainer);
  //       const form = paymentContainer.querySelector("form");
  //       if (form) {
  //         form.submit();
  //         //message.success(`Your balance has been topped up by $${topUpAmount}`);
  //         //await applicationStore.updateCompany();
  //       } else {
  //         message.error("Error generating payment form");
  //       }
  //     } else {
  //       message.error("Error initiating payment");
  //     }
  //   } catch (error) {
  //     console.error("Payment error:", error);
  //     message.error("Failed to initiate payment");
  //   }
  // };

  const transactionColumns = [
    {
      title: t('date'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      align: 'left' as 'left',
      render: (text: string) => {
        console.log('Original transaction date from API:', text);

        let formattedDate;
        try {
          const cleanedDate = text.split('.')[0] + 'Z';
          const localDate = new Date(cleanedDate);

          formattedDate = localDate.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }).replace(/\//g, '.').replace(',', '');

          console.log('Formatted transaction date:', formattedDate);
        } catch (error) {
          console.error('Error formatting transaction date:', error);
          formattedDate = 'Invalid Date';
        }

        return formattedDate;
      },
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'left' as 'left',
      render: (text: number) => (
        <span style={{ color: text < 0 ? 'red' : 'green' }}>
          {currencySymbol}{text}
        </span>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'transactionStatus',
      key: 'transactionStatus',
      align: 'left' as 'left',
    },
  ];

  const paginatedData = paymentStore.transactionHistory
    .slice()
    .sort((a, b) => dayjs(b.transactionDate).diff(dayjs(a.transactionDate)))
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <Layout headerStyle={1} footerStyle={2}>
      <div style={{ margin: '50px auto', maxWidth: '1200px' }}>

        <Card
          bordered={false}
          style={{ marginBottom: '30px', padding: '20px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('balanceAndPayment')} key="1">
              <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                <Title level={4}>
                  {t('currentBalance')}:{' '}
                  <span style={{ color: '#1890ff' }}>{currencySymbol}{(paymentStore.balance).toFixed(2)}</span>
                </Title>
                <Text>
                  {paymentStore.trialDate &&
                    paymentStore.trialDate.isSame(paymentStore.paymentDate, 'day') &&
                    dayjs().isBefore(paymentStore.trialDate) ? (
                    <>
                      {t('trialPeriodEnds')}: <span style={{ color: '#1890ff' }}>{paymentStore.trialDate.format('YYYY.MM.DD')}</span>
                    </>
                  ) : (
                    <>
                      {t('nextPaymentDate')}: <span style={{ color: '#1890ff' }}>{paymentStore.paymentDate.format('YYYY.MM.DD')}</span>
                    </>
                  )}
                </Text>


                <Text style={{ display: 'block', marginTop: '10px' }}>
                  {t('TariffPlan')}:
                  <span style={{ color: '#1890ff' }}> {currentTariffPlan ? t(currentTariffPlan.name) : ' '}</span>
                </Text>
                {/*<Form layout="inline" style={{ marginTop: '20px' }}>
                  <Form.Item
                    name="amount"
                    label={t('topUpAmount')}
                    rules={[{ required: true, message: t('pleaseEnterAmount') }]}
                  >
                    <Input
                      placeholder={t('enterAmount')}
                      type="number"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        setTopUpAmount(value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClick={handlePayment}>{t('topUp')}</Button>
                  </Form.Item>
                </Form> */}
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab={t('transactionHistory')} key="2">
              <Table
                columns={transactionColumns}
                dataSource={paginatedData}
                rowKey="id"
                pagination={false}
              />
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={paymentStore.transactionHistory.length}
                onChange={(page) => setCurrentPage(page)}
                style={{ marginTop: '20px', textAlign: 'center' }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>

        <Row justify="center" gutter={[16, 16]} wrap={true}>
          {!tariffPlansStore.isLoading && tariffPlansStore.tariffPlans.length > 0 ? (
            tariffPlansStore.tariffPlans.filter(t => t.isPublic).map((plan) => (
              <Col key={plan.id} xs={24} sm={12} md={8}>
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  {plan.description === 'Yearly' && (
                    <div style={{
                      position: 'absolute',
                      top: '-14px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#ffc107',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '15px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      zIndex: 1,
                    }}>
                      {t("Favorable")}
                    </div>
                  )}
                  {plan.description === 'Monthly' && (
                    <div style={{
                      position: 'absolute',
                      top: '-14px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: '#17a2b8',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '15px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      zIndex: 1,
                    }}>
                      {t("Popular")}
                    </div>
                  )}
                  <Card
                    title={t(plan.name)}
                    bordered={false}
                    style={{
                      textAlign: "center",
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      padding: '20px',
                      margin: "20px 0",
                      borderRadius: "10px"
                    }}
                  >
                    <Space direction="vertical" size="middle">
                      <Title level={3} style={{ marginBottom: 0 }}>
                        {plan.description === "Yearly"
                          ? `${currencySymbol}${(plan.pricePerYear).toFixed(2)}/${t('Year')}`
                          : `${currencySymbol}${(plan.pricePerMonth).toFixed(2)}/${t('Month')}`}
                      </Title>

                      {plan.description === "Yearly" && (
                        <Text>
                          <span style={{ textDecoration: 'line-through', color: 'grey' }}>{currencySymbol}{(4150).toFixed(2)}</span>
                          <span style={{ color: '#1890ff' }}> {currencySymbol}({(345.69).toFixed(2)})</span>/{t('month')}
                          <span> </span>
                          <span style={{ color: '#ffc107', backgroundColor: '#fff9e8', borderRadius: "5px" }}> -17% </span>
                        </Text>
                      )}

                      {plan.description === "Monthly" && (
                        <Text>
                          <span style={{ color: 'grey' }}>{t("NoDiscount")}</span>
                        </Text>
                      )}

                      <Button
                        type="primary"
                        onClick={() => handleSelectPlan(plan.id)}
                        style={{
                          color: "#1890ff",
                          borderColor: "#1890ff",
                          backgroundColor: "#ffffff",
                          borderRadius: "25px",
                          fontWeight: "bold",
                          height: "50px",
                          width: "150px",
                          transition: "background-color 0.1s ease, color 0.01s ease",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#1890ff";
                          e.currentTarget.style.color = "#ffffff";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "#ffffff";
                          e.currentTarget.style.color = "#1890ff";
                        }}
                      >
                        {confirmedPlan === plan.id ? t("Choose") : t("Choose")}
                      </Button>
                    </Space>
                  </Card>
                </div>
              </Col>
            ))
          ) : (
            <Text>{t("Loading...")}</Text>
          )}
        </Row>

      </div>
    </Layout>
  );
});

export default PaymentAndTariffs;
