import { UploadFile } from "antd";
import { ProjectDocument } from "../stores/ProjectDocumentationStore";
import SkTechApiClient from "./base/SkTechApiClient";
import { RcFile } from "antd/es/upload";

export default class ProjectDocumentsApiClient extends SkTechApiClient {
  public add = async (document: ProjectDocument): Promise<any> => {
    return await this.Post(`/api/Documentation`, {
      title: document.title,
      content: document.content,
      projectId: document.projectId,
      creatorId: document.creatorId,
    }).then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public getAll = async (): Promise<any> => {
    return await this.Get(`/api/Documentation`).then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public getById = async (id: string): Promise<any> => {
    return await this.Get(`/api/Documentation/${id}`).then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public getByProjectId = async (id: string): Promise<any> => {
    return await this.Get(`/api/Documentation/ByProject?projectId=${id}`).then(
      function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      }
    );
  };

  public setDeleteById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/Documentation/SetDeleted?documentationId=${id}`).then(function (
      response
    ) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public deleteById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/Documentation/${id}`).then(function (
      response
    ) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public edit = async (document: ProjectDocument): Promise<any> => {
    return await this.Patch(`/api/Documentation/${document.id}`, {
      title: document.title,
      content: document.content,
    }).then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public uploadDocumentationAttachment = async (
    file: UploadFile,
    id: string,
    confirmation: boolean = false
  ): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file as RcFile);
  
    const url = confirmation 
        ? `/api/File/UploadDocumentationAttachment?documentationId=${id}&confirmation=true`
        : `/api/File/UploadDocumentationAttachment?documentationId=${id}`;

    return await this.Post(url, formData);
  };

  public GetAttachmentsByDocumentation = async (id: string): Promise<any> => {
    return await this.Get(
      `/api/Documentation/GetAttachmentsByDocumentation?documentationId=${id}`
    ).then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    });
  };

  public ExportDocumentation = async (projectId: string, documentType: string): Promise<any> => {
    return await this.Get(
      `/api/Documentation/ByProject/Export?projectId=${projectId}&format=${documentType}`,
      'blob'
    )
  };

  public DeleteAttachmentByPublicId = async (
    publicId: string
  ): Promise<any> => {

    await this.Delete(`/api/File/${encodeURIComponent(publicId)}`)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
}
