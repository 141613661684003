import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";


export type ResourceItem = {
  id?: number,
  title: string,
  description: string,
  assignedUserId?: number,
  type?: ResourceType,
  companyId?: number,
  resourceGroupId: number,
  photoIds?: string[],
}

export type ResourceType = {
  id: string,
  title: string,
}

class ResourceStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  resources!: ResourceItem[];
  resourceGoups!: ResourceType[];

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.resources = [];
    this.resourceGoups = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  }

  createNew = async (item: ResourceItem) => {
    this.isLoading = true;
     const response = await this.ApiStore.UserResourceApi.add(item);
     const newResources = await this.ApiStore.UserResourceApi.getAll().catch(() => {
      this.isLoading = false;
    });
    if (newResources) {
      this.resources = [];
    }
    this.isLoading = false;
    return response;
  }

  createNewGroup = async (title: string) => {
    this.isLoading = true;
     const response = await this.ApiStore.UserResourceApi.addGroups(title);
     const groups = await this.ApiStore.UserResourceApi.getAllGroups().catch(() => {
      this.isLoading = false;
    });
    if (groups) {
      this.resourceGoups = [];
    }
    this.isLoading = false;
    return response;
  }

  update = async (item: ResourceItem) => {
    this.isLoading = true;
     const response = await this.ApiStore.UserResourceApi.update(item);
     this.getAllResource();
    this.isLoading = false;
    return response;
  }

  getAllResource = async () => {
    this.isLoading = true;
    const resources = await this.ApiStore.UserResourceApi.getAll().catch(() => {
      this.isLoading = false;
    });
    this.isLoading = false;
    if (resources) {
      this.resources = resources;
    }
    return resources;
  }

  getAllGroups = async () => {
    this.isLoading = true;
    const groups = await this.ApiStore.UserResourceApi.getAllGroups().catch(() => {
      this.isLoading = false;
    });
    if (groups) {
      this.resourceGoups = groups;
    }
    this.isLoading = false;
    return groups;
  }


  deleteById = async (id: number) => {
    this.isLoading = true;
     const response = await this.ApiStore.UserResourceApi.delete(id);
    this.isLoading = false;
    return response;
  }

}
export default ResourceStore;
