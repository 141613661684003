import { Badge, Button, Calendar, DatePicker, Dropdown, Flex, Form, Input, Modal, Popconfirm, Select, Spin, Table, Tooltip, message } from "antd";
import { ColumnType } from 'antd/es/table';
import Layout from "../../components/layout/Layout";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import utc from 'dayjs/plugin/utc';
import { Option } from "antd/es/mentions";
import { DeleteOutlined } from '@ant-design/icons';
import AddEvent from "./AddEvent";
import { getUserComponent } from "../../helpers/user";
import dayjs, { Dayjs } from 'dayjs';
import { CalendarEvent } from "../../api/EventsApiClient";

dayjs.extend(utc);

const EventPage = observer(({ events }: { events: any[] }) => {
  const { employeesStore, applicationStore, eventStore, taskStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (employeesStore.users.length > 0) {
      eventStore.getAll();
      eventStore.getBirthdays(employeesStore.users);
    } else {
      employeesStore.getUsers().then(users => {
        eventStore.getBirthdays(users);
        eventStore.getAll();
      })
    }
  }, []);

  const deleteEvent = async (id: any) => {
    message.open({
      type: 'loading',
      content: 'Deleting...',
      key: 'deleting'
    });
    await eventStore.deleteById(id).then(() => {
      message.destroy('deleting');
      message.success("Success");
      taskStore.fetchTasksByUserId(applicationStore.user.id);
      applicationStore.initUserTasks();
    }).catch(() => {
      message.error("Error");

    }).finally(() => {
      eventStore.getAll().catch(console.log);
    });
  }

  const columns = [
    {
      title: t("user.User"),
      dataIndex: 'userId',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder= {t('EnterName')}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              {t('Clean')}
            </Button>
            <Button
              type="link"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              {t('Apply')}
            </Button>
          </div>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        const firstName = employeesStore.users.find(user => user.id === record.userId)?.firstName || '';
        const lastName = employeesStore.users.find(user => user.id === record.userId)?.lastName || '';
        const fullName = `${firstName} ${lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      },
      sorter: (a: any, b: any) => {
        const firstNameA = employeesStore.users.find(user => user.id === a.userId)?.firstName || '';
        const lastNameA = employeesStore.users.find(user => user.id === a.userId)?.lastName || '';
        const firstNameB = employeesStore.users.find(user => user.id === b.userId)?.firstName || '';
        const lastNameB = employeesStore.users.find(user => user.id === b.userId)?.lastName || '';
        const fullNameA = `${firstNameA} ${lastNameA}`;
        const fullNameB = `${firstNameB} ${lastNameB}`;
        return TextSort(fullNameA, fullNameB);
      },
      render: (userId: string) => {
        const user = employeesStore.users.find(user => user.id === userId);
        return <div>{getUserComponent(user, applicationStore.company?.Tenant)}</div>;
      },
      key: 'userFullName',
    },
    {
      title: t("EventType"),
      dataIndex: 'type',
      filters: [
        { text: t('Leave'), value: 'Leave' },
        { text: t('SickLeave'), value: 'SickLeave' },
        { text: t('Birthday'), value: 'Birthday' },
        { text: t('TimeOff'), value: 'TimeOff' },
      ],
      onFilter: (value: any, record: any) => record.type === value,
      render: (type: any) => t(`${type}`),
      key: 'type',
    },    
    {
      title: t("StartDate"),
      dataIndex: 'startDate',
      render: (date: any) => <div>{date?.format('YYYY-MM-DD')}</div>,
      sorter: (a: any, b: any) => DateSort(a.startDate?.format('YYYY-MM-DD'), b.startDate?.format('YYYY-MM-DD')),
      key: 'startDate',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            value={selectedKeys[0] || []}
            onChange={dates => setSelectedKeys(dates ? [dates] : [])}
            style={{ width: 220 }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              {t('Clean')}
            </Button>
            <Button
              type="link"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              {t('Apply')}
            </Button>
          </div>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        const [start, end] = value || [];
        if (!start || !end) return true;
        const startDate = record.startDate?.format('YYYY-MM-DD');
        return startDate >= start.format('YYYY-MM-DD') && startDate <= end.format('YYYY-MM-DD');
      },
    },
    
    {
      title: t("DueDate"),
      dataIndex: 'endDate',
      render: (date: any) => <div>{date?.format('YYYY-MM-DD')}</div>,
      sorter: (a: any, b: any) => DateSort(a.startDate?.format('YYYY-MM-DD'), b.startDate?.format('YYYY-MM-DD')),
      key: 'endDate',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            value={selectedKeys[0] || []}
            onChange={dates => setSelectedKeys(dates ? [dates] : [])}
            style={{ width: 220 }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => clearFilters && clearFilters()}
              size="small"
            >
              {t('Clean')}
            </Button>
            <Button
              type="link"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              {t('Apply')}
            </Button>
          </div>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        const [start, end] = value || [];
        if (!start || !end) return true;
        const endDate = record.endDate?.format('YYYY-MM-DD');
        return endDate >= start.format('YYYY-MM-DD') && endDate <= end.format('YYYY-MM-DD');
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        const eventUser = employeesStore.users.find(user => user.id === record.userId);
        const hasChief = eventUser?.chiefId !== undefined && eventUser?.chiefId !== null && eventUser?.chiefId !== '-1';
        const isUsersManager = eventUser?.chiefId === applicationStore.user.id;
        const isSickLeave = record.type === 'SickLeave';
        const isTimeOf = record.type === 'TimeOff';
        const isOwnEvent = record.userId === applicationStore.user.id;
        
        return (
          <>
            <Flex justify="space-between">
              <Popconfirm
                title="Delete the event"
                description="Are you sure to delete this event?"
                okText="Yes"
                cancelText="No"
                onConfirm={(e) => {
                  deleteEvent(record.id)
                }}
              >
                <Button
                  disabled={String(record.id).includes("brt") || (hasChief && !isUsersManager && !isOwnEvent && !(isSickLeave || isTimeOf))}
                  type="primary" 
                  danger 
                  shape="circle" 
                  icon={<DeleteOutlined />} 
                />
              </Popconfirm>
            </Flex>
          </>
        );
      },
    }
  ];
  return (
    <Spin spinning={eventStore.isLoading} style={{ minHeight: 600 }}>
      <div className="container" style={{ margin: "20px auto" }}>
        <Flex justify="end">
          <Button onClick={showModal} type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }}>
            {`${t("Add")}`}
          </Button>
        </Flex>
      </div>
      <div className="container">
        <Table
          rowHoverable
          dataSource={events}
          columns={columns}
          pagination={false}
        />
      </div>
      <AddEvent isModalOpen={isModalOpen} hideModal={hideModal} />
    </Spin>
  )
})
export default EventPage;