import { makeAutoObservable } from "mobx";
import { Salary } from "../api/SalaryApiClient";
import ApiStore from "./ApiStore";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

class SalaryStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  salaries: Salary[];
  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.salaries = [];
  }

  add = async (salary: {
    id: string,
    salaryPerHour: string,
    startDate: string,
    endDate: string,
    userId: string
  }) => {
    console.log("salary");
    console.log(salary);

    this.isLoading = true;
    const response = await this.ApiStore.SalaryApiClient.addSalary({
      id: salary.id,
      salaryPerHour: parseFloat(salary.salaryPerHour),
      startDate: dayjs(salary.startDate).startOf("day").format("YYYY-MM-DD"),
      endDate: dayjs(salary.endDate).startOf("day").format("YYYY-MM-DD"),
      userId: salary.userId
    });
    this.isLoading = false;
    return response;
  };

  update = async (salary: any) => {
    this.isLoading = true;
    const response = await this.ApiStore.SalaryApiClient.updateSalary(salary.id, {
      id: salary.id,
      salaryPerHour: parseFloat(salary.salaryPerHour),
      startDate: dayjs(salary.startDate).startOf("day").format("YYYY-MM-DD"),
      endDate: dayjs(salary.endDate).startOf("day").format("YYYY-MM-DD"),
      userId: salary.userId
    });
    this.isLoading = false;
    return response;
  };

  getAll = async () => {
    this.isLoading = true;
    const result = await this.ApiStore.SalaryApiClient.getAll()
      .then((res) => {
        this.salaries = res;
        return res;
      })
      .finally(() => {
        this.isLoading = false;
      });
    return result;
  };

  getByPeriod = async (startDate: any, endDate: any) => {
    this.isLoading = true;
    const result = await this.ApiStore.SalaryApiClient.salariesByPeriod(
      startDate,
      endDate
    )
      .then((res) => {
        if (res) {
          const newSalaries = res.map((el: {
            startDate: string; endDate: string;
          }) => {
            return {
              ...el,
              startDate: dayjs.utc(el.startDate),
              endDate: dayjs.utc(el.endDate)
            }
          });
          this.salaries = newSalaries;
          return newSalaries;
        }
        return [];
      })
      .finally(() => {
        this.isLoading = false;
      });
    return result;
  };

  getSalaryForUserAtDate = (userId: string, date: Dayjs) => {
    return this.salaries.find(
      (salary) =>
        salary.userId === userId &&
        dayjs(salary.startDate).isSameOrBefore(date) &&
        dayjs(salary.endDate).isSameOrAfter(date)
    );
  };

  exportToExcel = async (startDate: string, endDate: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.SalaryApiClient.exportSalaryToExcel(
      startDate,
      endDate
    )
      .then((success) => {
        if (success) {
          console.log("Download successfull");
        } else {
          console.error("export to excel error");
        }
        return success;
      })
      .finally(() => {
        this.isLoading = false;
      });
    return result;
  };
}
export default SalaryStore;
