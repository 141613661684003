import { Flex, Table, Image, TableProps, Button, Popconfirm } from "antd"
import { observer } from "mobx-react-lite"
import { TextSort } from "../../helpers/SorterHelper";
import { DownOutlined, UpOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStore } from "../../stores/root-store-context";
import { Link } from "react-router-dom";
import { TimeReport } from "../../api/TimeReportsApiClient";
import { useState } from "react";
import { getUserComponent } from "../../helpers/user";
import { sumAllTime } from "../../helpers/timeReports";
import { Roles } from "../../stores/EmployeesStore";
import { useTranslation } from "react-i18next";

export const UsersTime = observer(({ setLogUserId, setLogProjectId, showModalAdd, showModalEdit, deleteTime, setIsChangeUser, setEditTimeReport, timeReports }: {
    setLogUserId: (id: string) => void,
    setLogProjectId: (id: string) => void,
    showModalAdd: () => void,
    showModalEdit: () => void,
    deleteTime: (id: string) => Promise<void>,
    setIsChangeUser: (status: boolean) => void,
    setEditTimeReport: (report: TimeReport) => void,
    timeReports: any[],
}) => {
    const { applicationStore, timeReportStore, employeesStore, projectsStore } = useStore();
    const { t } = useTranslation();

    const [expandedRowKey, setExpandedRowKey] = useState<string[]>([]);

    console.log('timeReports:', timeReports);
    console.log('employeesStore.users:', employeesStore.users);
    console.log('applicationStore.user.roleId:', applicationStore.user.roleId);

    const onExpandHandle = (expand: boolean, record: any) => {
        if (expand) {
            setExpandedRowKey(
                [
                    ...expandedRowKey,
                    record.key
                ]
            );
        } else {
            setExpandedRowKey(
                expandedRowKey.filter(a => a !== record.key)
            );
        }
    };
    const columnsUsers: TableProps<any>['columns'] = [
        {
            title: t('User'),
            dataIndex: 'title',
            key: 'title',
            render: (data, record) => <div>{getUserComponent(record.user, applicationStore.company?.Tenant)}</div>,
            sorter: (a: any, b: any) => TextSort(a.user.firstName, b.user.firstName)

        },
        Table.EXPAND_COLUMN,
        {
            title: t('Hours'),
            dataIndex: 'time',
            key: 'time',
            render: (date, record) => <div>{sumAllTime(record.reports)}</div>,
            sorter: (a: any, b: any) => TextSort(a.time, b.time)

        },
        {
            title: (applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) ? t('Amount') : null,
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (text: any, record: any) => (
                <div>{record.totalAmount ? record.totalAmount.toFixed(2) : "0.00"}</div>
            ),
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: any) => {
                return (
                    <>
                        <Flex justify="space-between">
                            <Button type="primary" shape="circle" onClick={() => {
                                setIsChangeUser(applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager || applicationStore.user.id === record.user.id)
                                setLogUserId(record.reports[0].userId);
                                setLogProjectId("");
                                showModalAdd();
                            }} icon={<PlusOutlined />} />
                        </Flex>
                    </>
                );
            },
        }
    ];

    return (
        <Table columns={columnsUsers} dataSource={timeReports} rowHoverable
            pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'] }}
            expandable={{
                expandedRowKeys: expandedRowKey,
                onExpand: onExpandHandle,
                expandIcon: ({ expanded, onExpand, record }) => {
                    if (expanded) {
                        return <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                            <UpOutlined />
                        </Button>

                    } else {
                        return <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                            <DownOutlined />
                        </Button>
                    }
                },
                expandRowByClick: true,
                expandedRowRender: (record, index) => {
                    return <ul>
                        {
                            record.reports.map((el: TimeReport, index: number) => {
                                return <li>
                                    {el.totalTime &&
                                        <Flex justify="space-between">
                                            <div style={{ flex: "1 1 200px" }}>{projectsStore.projects.find(pr => pr.id == el.projectId)?.title}</div>
                                            <div style={{ flex: "1 1 200px" }}>{el.description}</div>
                                            <div style={{ flex: "1 1" }}>{el.totalTime.slice(0, 5)}
                                                <Button type="text" shape="circle" onClick={() => {
                                                    setIsChangeUser(false);
                                                    setEditTimeReport(el);
                                                    showModalEdit();
                                                }}><EditOutlined /></Button></div>
                                            <div style={{ flex: "1 1 50px" }}>{el.endTime.format("DD MMMM")}</div>
                                            <Popconfirm
                                                title="Delete the time-report"
                                                description="Are you sure to delete this time-report?"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={() => {
                                                    deleteTime(el.id).then(() => {
                                                        timeReportStore.getAll();
                                                    })
                                                }}
                                            >
                                                <Button danger type="text" shape="circle" onClick={() => {
                                                }}><DeleteOutlined /></Button>
                                            </Popconfirm>
                                        </Flex>
                                    }
                                </li>
                            })
                        }
                    </ul>
                }
            }} />
    )
})