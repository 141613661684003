import { Button, Flex, Form, message, Modal, Select, Input, DatePicker, Spin, AutoComplete } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";



export const AddExperience = observer((props: { isModalOpen: boolean, hideModal: () => void, fetchExperience: () => void }) => {
  const { isModalOpen, hideModal, fetchExperience } = props;
  const { employeesStore, projectsStore, applicationStore } = useStore();
  const [messageApi] = message.useMessage();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // const isValidProject = (projectId: string) => {
  //   return projectsStore.projects.some(
  //     (project) =>
  //       (project.isActive !== false && (project.isPublish || project.developers.some((el) => el.id == applicationStore.user.id))) &&
  //       project.id === projectId
  //   );
  // };

  const onFinish = async () => {
    const company = form.getFieldValue("company");
    const project = form.getFieldValue("project");
    const projectId = form.getFieldValue("projectId");
    const position = form.getFieldValue("position");
    const description = form.getFieldValue("description");
    const startDate = form.getFieldValue("startDate").format('YYYY-MM-DD');
    const endDate = form.getFieldValue("endDate")
      ? form.getFieldValue("endDate").format('YYYY-MM-DD')
      : null;
  
    await projectsStore.getAll();
  
    const success = await employeesStore.addExperience(company, project, projectId, position, description, startDate, endDate);
  
    if (success) {
      message.success("Success");
      await employeesStore.editUser({})
        .then(() => {
          form.resetFields();
          hideModal();
          employeesStore.getUsers().catch(console.log);
        });
      fetchExperience();
    } else {
      message.error(t("ExistingExperience"));
    }
  };

  return (
    <Spin spinning={employeesStore.isLoading}>
      <Modal
        title={t("AddExperience")}
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          hideModal();
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item<any>
            label={`${t("Company")}`}
            name="company"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("Company")}`}
            />
          </Form.Item>
          <Form.Item<any>
            label={`${t("Position")}`}
            name="position"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("Position")}`}
            />
          </Form.Item>
          <Form.Item<any>
            label={t("Project")}
            name="project"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            {/* only public and assigned project */}
            <AutoComplete
              style={{ height: 40 }}
              placeholder="Project"
              options={projectsStore.projects
                .filter(
                  (project) =>
                    project.isActive !== false && (project.isPublish ||
                      project.developers.some(
                        (el) => el.id == applicationStore.user.id
                      ))
                )
                ?.map((el, index) => ({
                  value: el.title,
                  label: (
                    <Flex gap={5}>
                      <span>{`${el.title}`}</span>
                    </Flex>
                  ),
                  data: el,
                }))}
              onChange={(value, option: any) => {
                form.setFieldValue("projectId", option.data?.id);

                const description = form.getFieldValue("description");

                if (option?.data?.title && !description || description === "") {
                  form.setFieldValue(
                    "description",
                    t("WorkOnProject", { projectTitle: option.data.title || "" })
                  );
                }
              }}
              filterOption={(inputValue, option) => {

                return (
                  option?.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                );
              }}

            />
          </Form.Item>
          <Form.Item<any>
            label={`${t("Description")}`}
            name="description"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <TextArea
              rows={5}
              placeholder={`${t("Description")}`}
            />
          </Form.Item>
          <Flex vertical={false} justify="space-between" >
            <Form.Item<any>
              label={`${t("Start Date")}`}
              name="startDate"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item<any>
              label={`${t("End Date")}`}
              name="endDate"
              style={{ width: "100%" }}
              rules={[{ required: false }]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </Flex>
          <Form.Item>
            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              style={{ height: 65, float: "right" }}
            >
              {t("Save")} <i className="fa-solid fa-arrow-right-long" />
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  )
})
