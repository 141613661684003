import {
  Flex,
  Table,
  Image,
  TableProps,
  Button,
  Popconfirm,
  AutoComplete,
  Select,
  Popover,
  Input,
  message,
  Statistic
} from "antd";
import { observer } from "mobx-react-lite";
import { TextSort } from "../../helpers/SorterHelper";
import {
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useStore } from "../../stores/root-store-context";
import { Link } from "react-router-dom";
import { TimeReport } from "../../api/TimeReportsApiClient";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Option } from "antd/es/mentions";
import dayjs, { Dayjs } from 'dayjs'
import TimerBlock from "../../components/blocks/TimerBlock";
import { Roles } from "../../stores/EmployeesStore";
import { sumAllTime } from "../../helpers/timeReports";

var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

export const MyTime = observer(
  ({
    setLogUserId,
    setLogProjectId,
    showModalAdd,
    showModalEdit,
    deleteTime,
    setIsChangeUser,
    setEditTimeReport,
    timeReports,
  }: {
    setLogUserId: (id: string) => void;
    setLogProjectId: (id: string) => void;
    showModalAdd: () => void;
    showModalEdit: () => void;
    deleteTime: (id: string) => Promise<void>;
    setIsChangeUser: (status: boolean) => void;
    setEditTimeReport: (report: TimeReport) => void;
    timeReports: TimeReport[] | { log: TimeReport; payment: number }[];
  }) => {
    const { applicationStore, timeReportStore, employeesStore, projectsStore } =
      useStore();
    const { t } = useTranslation();
    const [currentTimer, setCurrentTimer] = useState<any>();
    const [timeLog, setTimeLog] = useState<any>({ description: "" });
    const [userProjects, setUserProjects] = useState<any>([]);
    const [myPayment, setMyPayment] = useState<string>("0.00");
    const [myHours, setMyHours] = useState<string>("00:00");

    const getTimer = async () => {
      timeReportStore.setLoading(true);
      const timer = await timeReportStore.getTimer(applicationStore.user.id);
      setCurrentTimer(timer);
      timeReportStore.setLoading(false);
    }
    const fetchProjects = async () => {
      const projects: any = await projectsStore.getAll();
      const userProjects = projects?.filter((project: any) =>
        project.developers && (
          project.developers.some((user: any) => user.id === applicationStore.user.id) ||
          project.projectResponsibleId === applicationStore.user.id
        )
      );
      setUserProjects(userProjects);
    }
    const startTimer = async () => {
      if (timeLog.projectId === undefined) message.error("Choose the project");
      else {
        await timeReportStore.startTimer(timeLog.projectId);
        getTimer();
        document.dispatchEvent(new CustomEvent("timerStarted"));
      }
      fetchProjects();
    };

    useEffect(() => {
      document.addEventListener("timerStopped", getTimer);
      getTimer();
      fetchProjects();
    }, [timeLog]);

    useEffect(() => {
      if ([Roles.Financier, Roles.Owner].includes(applicationStore.user.roleId)) {
        const reports = (timeReports as { log: TimeReport; payment: number }[]).map((el) => {return el.log});
        const summTime = sumAllTime((reports as TimeReport[]));
        setMyHours(summTime);

        const amount = (timeReports as { log: TimeReport; payment: number }[]).reduce((acc, el) => { acc += el.payment; return acc }, 0)
        setMyPayment(amount.toFixed(2));
      } else {
        const summTime = sumAllTime((timeReports as TimeReport[]));
        setMyHours(summTime);
      }
    }, [timeReports]);

    useEffect(() => {
      const userProjects = projectsStore.projects?.filter(
        (project) =>
          project.isActive !== false && (project.isPublish ||
            project.developers.some(
              (el) => el.id == applicationStore.user.id
            ))
      );

      setUserProjects(userProjects);
      console.log("projectsStore.projects=====================");
      console.log(userProjects);
      console.log(JSON.parse(JSON.stringify(projectsStore.projects)));

    }, [projectsStore.projects]);




    const columns: TableProps<any>["columns"] = [
      {
        dataIndex: "title",
        key: "title",
        render: (text) => <div>{text}</div>,
      },
      Table.EXPAND_COLUMN,
      {
        title: t("Hours"),
        dataIndex: "time",
        key: "time",
        render: (date) => <div>{date}</div>,
      },
      {
        title: "",
        dataIndex: "project",
        render: (_: any, record: any) => {
          return (
            <>
              <Flex
                onClick={(e) => {
                  e.stopPropagation();
                }}
                gap={8}
                style={{ justifyContent: "end", alignItems: "center" }}
              >

                <Select
                  disabled={currentTimer}
                  onClick={() => {
                    setLogUserId(applicationStore.user.id);
                  }}
                  value={currentTimer ? (currentTimer.log ? currentTimer.log.projectId : currentTimer.projectId) : timeLog.projectId}
                  onChange={(value) => {
                    setTimeLog({ ...timeLog, projectId: value });
                  }}
                  style={{ width: "150px" }}
                >
                  {userProjects?.map((project: any, index: number) => (
                    <Option value={project.id} key={index.toString()}>
                      {project.title}
                    </Option>
                  ))}
                </Select>
                {!currentTimer ?
                  <Button
                    onClick={(e) => {
                      startTimer();
                    }}
                  >
                    <PlayCircleOutlined />
                  </Button>
                  :
                  <Flex style={{ display: "flex", alignItems: "center" }}>
                    <TimerBlock></TimerBlock>
                  </Flex>
                }

              </Flex>
            </>
          );
        },
      },
    ];

    if (applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) {
      columns.splice(2, 0, {
        title: t("Amount"),
        dataIndex: "Amount",
        key: "Amount",
        render: (date) => (
          <div>
            {applicationStore.company.Currency} {parseFloat(date).toFixed(2)}
          </div>
        ),
      });
    }

    return (
      <Table
        columns={columns}
        // showHeader={false}
        dataSource={[
          {
            title: (
              <>
                <Flex align="center">
                  <Image
                    width={80}
                    height={80}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    src={
                      applicationStore.user.photoId
                        ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${applicationStore.user.photoId}`
                        : `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                    }
                  />
                  <Link
                    style={{ marginLeft: 20 }}
                    to={`/${applicationStore.company.Tenant}/employees/${applicationStore.user.id}`}
                  >
                    {applicationStore.user.firstName +
                      " " +
                      applicationStore.user.lastName}
                  </Link>
                </Flex>
              </>
            ),
            time: myHours,
            Amount: myPayment
          },
        ]}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            if (expanded) {
              return (
                <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                  <UpOutlined />
                </Button>
              );
            } else {
              return (
                <Button shape="circle" onClick={(e) => onExpand(record, e)}>
                  <DownOutlined />
                </Button>
              );
            }
          },
          expandRowByClick: true,
          expandedRowRender: (record, index) => {
            return (
              <ul>
                {timeReports.map((el: TimeReport | { log: TimeReport; payment: number }, index) => {
                  const log = (applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) ? (el as { log: TimeReport }).log : (el as TimeReport);
                  const project = projectsStore.projects.find(pr => pr.id == (el as TimeReport).projectId || pr.id == (el as any).log?.projectId);

                  return (
                    <li key={index}>
                      {log.totalTime && (
                        <Flex justify="space-between" align="center">
                          <div style={{ flex: "0 1 200px" }}>
                            <Link to={`/${applicationStore.company?.Tenant}/projects/${project?.id}`}>{project?.title}</Link>
                          </div>
                          <div style={{
                            flex: "1 1 200px", border: "1px solid lightgray", backgroundColor: "#FFFFFF", margin: "0 5px 5px 0", padding: 5,
                            borderRadius: 5, boxShadow: "0 0 5px rgba(0,0,0,0.1)"
                          }}>
                            {log.description || "(no description)"}
                          </div>
                          <div style={{ flex: "1 1" }}>
                            {log.totalTime.slice(0, 5)}
                            <Button
                              type="text"
                              shape="circle"
                              onClick={() => {
                                setIsChangeUser(false);
                                setEditTimeReport(log);
                                showModalEdit();
                              }}
                            >
                              <EditOutlined />
                            </Button>
                          </div>
                          <div style={{ flex: "1 1 50px" }}>{log.endTime.format("DD MMMM")}</div>
                          <Popconfirm
                            title="Delete the time-report"
                            description="Are you sure to delete this time-report?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                              deleteTime(log.id).then(() => {
                                timeReportStore.getMyTime(applicationStore.user.id);
                              });
                            }}
                          >
                            <Button danger type="text" shape="circle">
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </Flex>
                      )}
                    </li>
                  );
                })}
              </ul>
            );
          },
        }}
        rowHoverable
        footer={() => (
          <Flex justify="end">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setLogUserId(applicationStore.user.id);
                setLogProjectId("");
                showModalAdd();
              }}
              icon={<PlusOutlined />}
            />
          </Flex>
        )}
        pagination={false}
      />
    );
  }
);
