import {
  AutoComplete,
  Button,
  Flex,
  Form,
  FormProps,
  message,
  Modal,
  Select,
  TimePicker,
  Image,
  Input,
  DatePicker,
} from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import dayjs, { Dayjs } from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { TimeReport } from "../../api/TimeReportsApiClient";
import { Roles } from "../../stores/EmployeesStore";
import moment from "moment";
import MessageNotHaveProjects from "../../components/blocks/MessageNotHaveProjects";
import { Project } from "../../stores/ProjectsStore";

export const AddTimeReport = observer(
  (props: {
    isModalOpen: boolean;
    hideModal: () => void;
    projectId?: string;
    userId?: string;
    isChangeUser: boolean;
    refresh: boolean;
    setRefresh: (refresh: boolean) => void;
  }) => {
    const {
      isModalOpen,
      hideModal,
      projectId,
      userId,
      isChangeUser,
      refresh,
      setRefresh,
    } = props;
    const { applicationStore, timeReportStore, employeesStore, projectsStore } =
      useStore();
    const [userProjects, setUserProjects] = useState<any[]>([]);

    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
      if (projectsStore.projects.length > 0) {
        const userProjects = projectsStore.projects?.filter(
          (project: Project) => {
            return (
              project.isPublish ||
              (project.developers &&
                (project.developers.some(
                  (user: any) => user.id === applicationStore.user.id
                ) ||
                  project.projectResponsibleId === applicationStore.user.id))
            );
          }
        );
        setUserProjects(userProjects);
      }
    }, [projectsStore.projects]);

    const onFinish: FormProps<any>["onFinish"] = async (timeReport: any) => {
      const projectId = form.getFieldValue("projectId");
      const description = form.getFieldValue("description");
      const userId = form.getFieldValue("userId");
      const time = dayjs(form.getFieldValue("time")).format("HH:mm");
      const endTime = dayjs(form.getFieldValue("endTime")).format("YYYY-MM-DD");
      timeReportStore
        .add(projectId, time, endTime, userId, description)
        .then(() => {
          timeReportStore.getMyTime(applicationStore.user.id);
        })
        .finally(() => {
          if (
            applicationStore.user.roleId === Roles.Financier ||
            applicationStore.user.roleId === Roles.Owner
          ) {
            timeReportStore.getForFinancier().then(() => setRefresh(!refresh));
          } else {
            timeReportStore.getAll();
          }
          form.resetFields();
          hideModal();
        });
    };

    useEffect(() => {
      if (isModalOpen) {
        form.setFieldValue(
          "user",
          `${employeesStore.users.find((el) => el.id === userId)?.firstName} ${employeesStore.users.find((el) => el.id === userId)?.lastName
          }`
        );
        form.setFieldValue("userId", userId);
        const projectTitle = projectsStore.projects.find((el) => el.id === projectId)?.title

        form.setFieldValue(
          "project",
          projectTitle
        );

        form.setFieldValue("projectId", projectId);
        form.setFieldsValue({ endTime: dayjs() });
      }
    }, [userId, projectId, isModalOpen]);

    return (
      <Modal
        title={t("LogTime")}
        open={isModalOpen}
        footer={null}
        onCancel={hideModal}
      >
        {userProjects.length > 0 ? (
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ time: dayjs(`${applicationStore.company.HoursVacation}:00`, "HH:mm"), endTime: dayjs() }}
            labelCol={{ span: 6 }}
            labelAlign="left"
          >
            <Form.Item<any> name="projectId" hidden>
              <Input />
            </Form.Item>
            <Form.Item<any> name="userId" hidden>
              <Input />
            </Form.Item>
            <Form.Item<any>
              label={t("Project")}
              name="project"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <AutoComplete
                style={{ height: 40 }}
                placeholder="Project"
                options={projectsStore.projects
                  .filter(
                    (project) =>
                      project.isActive !== false && (project.isPublish ||
                        project.developers.some(
                          (el) => el.id == applicationStore.user.id
                        ))
                  )
                  ?.map((el, index) => ({
                    value: el.title,
                    label: (
                      <Flex gap={5}>
                        <span>{`${el.title}`}</span>
                      </Flex>
                    ),
                    data: el,
                  }))}
                onChange={(value, option: any) => {
                  form.setFieldValue("projectId", option.data?.id);
                  
                  const description = form.getFieldValue("description");

                  if (option?.data?.title && !description || description === "") {
                    form.setFieldValue(
                      "description",
                      t("WorkOnProject", { projectTitle: option.data.title || "" })
                    );
                  }
                }}
                filterOption={(inputValue, option) => {

                  return (
                    option?.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }}

              />
            </Form.Item>
            <Form.Item<any>
              label={t("User")}
              name="user"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <AutoComplete
                disabled={!isChangeUser}
                style={{ height: 40 }}
                placeholder={t("User")}
                options={employeesStore.users?.map((el, index) => ({
                  key: index,
                  value: `${el.firstName} ${el.lastName}`,
                  data: el,
                  label: (
                    <Flex gap={5}>
                      <Image
                        width={40}
                        height={40}
                        preview={false}
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                        src={
                          el.photoId
                            ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${el.photoId}`
                            : `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                        }
                      />
                      <div>
                        <div>
                          {el.firstName}
                          {el.lastName}
                        </div>
                        <div>{el.email}</div>
                      </div>
                    </Flex>
                  ),
                }))}
                onChange={(value, option: any) => {
                  form.setFieldValue("userId", option.data?.id);
                }}
              />
            </Form.Item>
            <Form.Item<any>
              label={t("Time")}
              name="time"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <TimePicker
                defaultValue={dayjs("00:00", "HH:mm")}
                format={"HH:mm"}
                onCalendarChange={(date: any) => {
                  form.setFieldValue("time", date);
                }}
              />
            </Form.Item>
            <Form.Item<any>
              label={t("Date")}
              name="endTime"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <DatePicker
                defaultValue={form.getFieldValue("endTime")}
                onCalendarChange={(date: any) => {
                  form.setFieldValue("date", date);
                }}
              />
            </Form.Item>
            <Form.Item<any>
              label={t("Description")}
              name="description"
              style={{ width: "100%" }}
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="theme-btn"
                htmlType="submit"
                style={{ height: 65, float: "right" }}
              >
                {t("Add")}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <MessageNotHaveProjects />
        )}
      </Modal>
    );
  }
);
