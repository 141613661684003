import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import {
    Button,
    message,
    Card,
    Space,
    Typography,
    Steps,
    Spin,
    App,
    Modal
} from "antd";
import { set, toJS } from "mobx";
import { ClockCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Test, TestAnswer, TestAttempt, TestQuestion, UserTestAssignment } from "../../stores/TestsStore";
import TestStepTwo from "../../components/test/TestStepTwo";
import { Roles } from "../../stores/EmployeesStore";
import { AssignmentStatus, TypeTestQuestion } from "../../stores/ApplicationStore";
import { GoogleGenerativeAI } from "@google/generative-ai";

const { Title, Text } = Typography;
const { Step } = Steps;

const PassageTest = observer(() => {
    const API_AI_KEY = 'AIzaSyAsWBPVCBCJOFKF9flVgLa0a4v9RggzKTo';
    const [testAttempt, setTestAttempt] = useState<TestAttempt | null>();
    const [existAssignment, setExistAssignment] = useState<UserTestAssignment | null>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [test, setTest] = useState<Test>();
    const [testQuestions, setTestQuestions] = useState<TestQuestion[]>();
    const { applicationStore, apiStore } = useStore();
    const { testsStore } = useStore();
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    const { id } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [answers, setAnswers] = useState<
        Array<{
            questionId: number;
            answer: string | number | number[];
        }>
    >([]);
    const [correctAnswers, setCorrectAnswers] = useState<number>(0);
    const [percentageResult, setPercentageResult] = useState<number | null>(null);
    const [existMaxPercentage, setExistMaxPercentage] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [maxTotalPoints, setMaxTotalPoints] = useState<number>(0);
    const [userScore, setUserScore] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const companyTest = toJS(testsStore.testsCompany.find(t => t.id == Number(id)));

            if (applicationStore.user.roleId == Roles.User) {
                const userAssignments = await testsStore.getAllAssignmentByUserId(applicationStore.user.id ?? "");
                const existAssignment = userAssignments.filter(ua => ua.testId == Number(id)).filter(ua => ua.assignedToUserId == Number(applicationStore.user.id)).find(ua => ua.assignmentStatusId != AssignmentStatus.Completed);

                setExistAssignment(existAssignment ?? null);

                if (existAssignment && existAssignment.testAttempts.length > 0) {
                    const maxPercentag = Math.max(...existAssignment.testAttempts.map(t => t.percentage));
                    setExistMaxPercentage(maxPercentag);
                }
            }

            if (companyTest) {
                setTestQuestions(companyTest.testQuestions);
                setTest(companyTest);
            } else {
                const publicTest = toJS(testsStore.testsPublic.find(t => t.id == Number(id)));
                if (publicTest) {
                    if(publicTest.testQuestions.length == publicTest.numberOfQuestions) {
                        setTestQuestions(publicTest.testQuestions);
                    }
                    else{
                        console.log("All tests: ", publicTest.testQuestions);
                        var generationQuestions = generateQuestions(publicTest.testQuestions, publicTest.numberOfQuestions);
                        setTestQuestions(generationQuestions);
                        console.log("Generation tests ", generationQuestions);
                    }
                    setTest(publicTest);
                } else {
                    message.error(t('test_not_found'));
                    navigate(`/${applicationStore.company.Tenant}/tests`);
                }
            }

            setLoading(false);
        };

        fetchData();
    }, [testsStore]);

    const generateQuestions = (testQuestions: TestQuestion[], numberOfQuestions: number) => {
        const typeMap: Record<TypeTestQuestion, 'singleChoice' | 'multipleChoice' | 'writeCode' | 'fixCode' | 'openAnswer'> = {
            [TypeTestQuestion.SingleChoice]: 'singleChoice',
            [TypeTestQuestion.MultipleChoice]: 'multipleChoice',
            [TypeTestQuestion.WriteCode]: 'writeCode',
            [TypeTestQuestion.FixCode]: 'fixCode',
            [TypeTestQuestion.OpenAnswer]: 'openAnswer',
        };

        const questionTypeCounts = testQuestions.reduce((acc, question) => {
            const typeKey = typeMap[question.typeTestQuestionId];
            acc[typeKey] = (acc[typeKey] || 0) + 1;
            return acc;
        }, {} as Record<'singleChoice' | 'multipleChoice' | 'writeCode' | 'fixCode' | 'openAnswer', number>);

        const totalQuestions = testQuestions.length;

        const questionTypePercentages = Object.fromEntries(
            Object.entries(questionTypeCounts).map(([type, count]) => [type, (count / totalQuestions) * 100])
        );

        const questionsToSelect = Object.fromEntries(
            Object.entries(questionTypePercentages).map(([type, percentage]) => [
                type,
                Math.round((percentage / 100) * numberOfQuestions),
            ])
        );

        const getRandomQuestions = (questions: TestQuestion[], count: number) => {
            const shuffled = [...questions].sort(() => Math.random() - 0.5);
            return shuffled.slice(0, count);
        };

        let selectedQuestions: TestQuestion[] = [];
        Object.entries(questionsToSelect).forEach(([type, count]) => {
            const questionsOfType = testQuestions.filter(
                (q) => typeMap[q.typeTestQuestionId] === type
            );
            selectedQuestions = [...selectedQuestions, ...getRandomQuestions(questionsOfType, count)];
        });

        if (selectedQuestions.length < numberOfQuestions) {
            const remainingQuestions = testQuestions.filter((q) => !selectedQuestions.includes(q));
            selectedQuestions = [
                ...selectedQuestions,
                ...getRandomQuestions(remainingQuestions, numberOfQuestions - selectedQuestions.length),
            ];
        }

        return selectedQuestions;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        if(testQuestions){
            setMaxTotalPoints(testQuestions.reduce((total, question) => total + (question.numberPoints ?? 0), 0) ?? 0);
        }
        handleStartTest();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleStartTest = () => {
        if (applicationStore.user.roleId === Roles.User) {
            const numberAttempt = (existAssignment?.testAttempts?.at(-1)?.numberAttempt ?? 0) + 1;

            setTestAttempt({
                id: 0,
                startDate: new Date().toISOString(),
                finishDate: '',
                percentage: 0,
                testAnswers: [],
                numberAttempt: numberAttempt,
                timeSpentMinutes: 0,
                userTestAssignmentId: existAssignment?.id ?? 0,
            });
        };

        enableRestrictions();

        setCurrentStep(1);
    };

    const enterFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error(`Ошибка активации полноэкранного режима: ${err.message}`);
            });
        }
    };

    const exitFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error(`Ошибка выхода из полноэкранного режима: ${err.message}`);
            });
        }
    };

    const enableRestrictions = () => {
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        if (header && footer) {
            header.style.display = 'none';
            footer.style.display = 'none';
        }

        document.addEventListener('copy', preventAction);
        document.addEventListener('cut', preventAction);
        document.addEventListener('contextmenu', preventAction);
        document.addEventListener('keydown', preventKeyActions);
        enterFullScreen();
    };

    const disableRestrictions = () => {
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        if (header && footer) {
            header.style.display = 'block';
            footer.style.display = 'block';
        }

        document.removeEventListener('copy', preventAction);
        document.removeEventListener('cut', preventAction);
        document.removeEventListener('contextmenu', preventAction);
        document.removeEventListener('keydown', preventKeyActions);
        exitFullScreen();
    };

    const preventAction = (event: Event) => {
        event.preventDefault();
    };

    const preventKeyActions = (event: KeyboardEvent) => {
        if (event.ctrlKey && ['c', 'x', 'v', 'a'].includes(event.key.toLowerCase())) {
            event.preventDefault();
        }

        if (event.key === 'Escape') {
            event.preventDefault();
        }
    };

    const transformedAnswersToSave = (
        answers: Array<{ questionId: number; answer: string | number | number[] }>
    ): TestAnswer[] => {
        if (!test || !testQuestions) {
            return [];
        }

        return testQuestions.map((question) => {
            const answer = answers.find((a) => a.questionId == question.id);

            let answerValue: string;
            if (!answer) {
                answerValue = '';
            } else if (typeof answer.answer === 'number') {
                answerValue = answer.answer.toString();
            } else if (Array.isArray(answer.answer)) {
                answerValue = answer.answer.join(',');
            } else {
                answerValue = answer.answer;
            }

            return {
                answer: answerValue,
                testQuestionId: question.id,
                testAttemptId: 0,
                id: 0,
            };
        });
    };

    const transformedAnswersToJSONString = (answers: Array<{ questionId: number, answer: string | number | number[] }>) => {
        const transformedAnswers = testQuestions?.map((question) => {
            const answer = answers.find((a) => a.questionId === question.id);

            let transformedAnswer;
            if (!answer) {
                transformedAnswer = "";
            } else {
                switch (question.typeTestQuestionId) {
                    case TypeTestQuestion.SingleChoice:
                        transformedAnswer = question.testOptions.find((o) => o.id === answer.answer)?.name || "";
                        break;
                    case TypeTestQuestion.MultipleChoice:
                        if (Array.isArray(answer.answer)) {
                            transformedAnswer = answer.answer
                                .map((id) => question.testOptions.find((o) => o.id === id)?.name)
                                .filter(Boolean)
                                .join(", ");
                        }
                        break;
                    case TypeTestQuestion.WriteCode:
                    case TypeTestQuestion.FixCode:
                    case TypeTestQuestion.OpenAnswer:
                        transformedAnswer = answer.answer;
                        break;
                    default:
                        transformedAnswer = "Неизвестный тип ответа";
                }
            }

            const maxPoints = question.numberPoints ?? 5;

            return {
                questionName: question.name,
                answer: transformedAnswer,
                maxPoints
            };
        }).filter(Boolean);

        const jsonString = JSON.stringify(transformedAnswers, null, 2);

        return jsonString;
    }

    const fetchCheckTestsAnswers = async (
        answers: Array<{ questionId: number, answer: string | number | number[] }>,
        minutesSpent: number
    ) => {
        const jsonString = transformedAnswersToJSONString(answers);

        const requestAI = `
            Please check the following test answers and calculate the score based on the provided maximum points for each question.
            Each question has a maximum number of points, and the user's score for each question should be calculated accordingly.
            The scoring system is as follows:
            - If the percentage is greater than or equal to 90%, the grade is 5.
            - If the percentage is between 75% and 89%, the grade is 4.
            - If the percentage is between 60% and 74%, the grade is 3.
            - If the percentage is below 60%, the grade is 2.
    
            Here are the user's answers and the maximum points for each question in JSON format:
            ${jsonString}
    
            Calculate the percentage using the formula:
            (userScore / ${maxTotalPoints}) * 100
    
            Format the output as a JSON object with the following fields:
            - "grade": The calculated grade.
            - "correctCount": The total number of correctly answered questions.
            - "totalQuestions": The total number of questions in the test.
            - "maxScore": The maximum possible score (${maxTotalPoints} points).
            - "userScore": The total score achieved by the user based on their answers.
            - "percentage": The percentage of the maximum score achieved.
    
            Return the JSON object with these fields as output. Do not include any additional text, comments, or explanations.
        `;

        try {
            const genAI = new GoogleGenerativeAI(API_AI_KEY);
            const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

            const resultResponse = await model.generateContent(requestAI);

            const rawResponse = resultResponse.response.text().trim();
            const jsonStart = rawResponse.indexOf("{");
            const jsonEnd = rawResponse.lastIndexOf("}") + 1;

            if (jsonStart === -1 || jsonEnd === 0) {
                throw new Error("Response does not contain a valid JSON object.");
            }

            const cleanedResponse = rawResponse.substring(jsonStart, jsonEnd);

            const result = JSON.parse(cleanedResponse);

            const { grade, correctCount, totalQuestions, percentage, maxScore, userScore } = result;

            setPercentageResult(Math.round(Number(percentage)));
            setCorrectAnswers(correctCount);
            setUserScore(userScore);

            if (applicationStore.user.roleId === Roles.User && testAttempt != null) {
                await fetchGradeToServer(percentage, minutesSpent, answers);
            }

            console.log({
                grade,
                correctCount,
                totalQuestions,
                percentage,
                maxScore,
                userScore
            });
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    };

    const fetchGradeToServer = async (percentage: number, minutesSpent: number, answers: Array<{ questionId: number, answer: string | number | number[] }>) => {
        let testAttemptData: TestAttempt | null = testAttempt ?? null;

        if (testAttemptData != null) {
            testAttemptData.percentage = Math.round(Number(percentage));
            testAttemptData.timeSpentMinutes = minutesSpent;
            testAttemptData.finishDate = new Date().toISOString();
            testAttemptData.testAnswers = transformedAnswersToSave(answers);


            try {
                await testsStore.addTestAttempt(testAttemptData);

            } catch (error) {
                message.error(t("Failed"));
            }
        }
    }

    const onFinishTest = async (answers: Array<{ questionId: number, answer: string | number | number[] }>, minutesSpent: number) => {
        setAnswers(answers);
        disableRestrictions();

        fetchCheckTestsAnswers(answers, minutesSpent);
        setCurrentStep(2);
    }

    const getAttemptUsers = (): string | undefined => {
        if (applicationStore.user.roleId === Roles.User) {
            const numberOfAttempts = test?.numberOfAttempts ?? 0;
            const attemptsLength = existAssignment?.testAttempts.length ?? 0;
            return `${t("passage_test.attemptsRemaining")}: ${numberOfAttempts - attemptsLength}`;
        }

        return `${t("passage_test.number_of_attempts")}: ${test?.numberOfAttempts ?? 0}`;
    };

    return (
        <Layout headerStyle={1} footerStyle={2}>
            <Spin spinning={loading} style={{ minHeight: 600 }}>
                <div style={{ padding: "20px" }}>
                    <Steps current={currentStep} style={{ marginBottom: "20px" }}>
                        <Step title={t(`passage_test.test_details`)} />
                        <Step title={t("passage_test.test_in_progress")} />
                        <Step title={t("passage_test.test_complete")} />
                    </Steps>

                    {currentStep === 0 && (
                        <Card style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                                <Title level={3}>{t("passage_test.test_details")}</Title>
                                {test?.name != null && (
                                    <Text>{t("add_test.Name")}: {test?.name}</Text>
                                )}
                                <Text>{t("passage_test.topic")}: {applicationStore.skills.find(s => s.skillId == test?.skillId?.toString())?.title || t("unknown_skill")}</Text>
                                <Text>{getAttemptUsers()}</Text>
                                {existMaxPercentage != null && (
                                    <Text>{t(`passage_test.max_grade`)}: {existMaxPercentage}%</Text>
                                )}
                                {test?.language != null && (
                                    <Text>{t("tests.Language")}: {test.language == "en" ? t("add_test.English") : test.language == "ua" ? t("add_test.Ukrainian") : t("add_test.Russian")}</Text>
                                )}
                                <Text>
                                    <QuestionCircleOutlined /> {t("passage_test.number_of_questions")}: {test?.numberOfQuestions}
                                </Text>
                                <Text>
                                    <ClockCircleOutlined /> {t("passage_test.time_limit")}: {test?.maxTimeMinutes} {t("passage_test.minutes")}
                                </Text>
                                {(test?.description != null && test.description.length > 0) && (
                                    <Text>{t("add_test.Description")}: {test?.description}</Text>
                                )}
                                <Button
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={showModal}
                                >
                                    {t("passage_test.start_test")}
                                </Button>
                            </Space>
                        </Card>
                    )}

                    {currentStep === 1 && (
                        <TestStepTwo maxTimeMinutes={test?.maxTimeMinutes ?? 15} testQuestions={testQuestions ?? []} onFinish={onFinishTest} />
                    )}

                    {currentStep === 2 && (
                        <Card style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                            <Title level={3}>{t("passage_test.test_complete")}</Title>
                            <div style={{ marginTop: "30px" }}>
                                <Title level={4}>{t("passage_test.your_score")}:</Title>
                                <Text>{t("passage_test.correct_answers")}: {correctAnswers} / {test?.numberOfQuestions}</Text>
                                <Text style={{ display: "block", marginTop: "10px" }}>
                                    {t("passage_test.score")}: {percentageResult}%
                                </Text>
                                <Text style={{ display: "block", marginTop: "10px" }}>
                                    {t("passage_test.score_balls")}: {userScore} / {maxTotalPoints}
                                </Text>
                                <Text style={{ display: "block", marginTop: "10px", fontSize: "17px", fontWeight: "bold" }}>
                                    {(percentageResult ?? 0) > 80
                                        ? t("passage_test.test_passed")
                                        : t("passage_test.test_failed")}
                                </Text>
                            </div>
                            <Button style={{ marginTop: 20 }} type="primary" onClick={() => navigate(`/${applicationStore.company.Tenant}/tests`)}>
                                {t("passage_test.back_to_tests")}
                            </Button>
                        </Card>
                    )}
                </div>

                <Modal
                    title={<span style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>{t("passage_test.modal_title")}</span>}
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={t("passage_test.modal_confirm")}
                    cancelText={t("passage_test.modal_cancel")}
                >
                    <p style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>
                        {t("passage_test.modal_content")}
                    </p>
                </Modal>
            </Spin>
        </Layout>
    );
});

export default PassageTest;
