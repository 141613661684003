import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import {
    Button,
    message,
    Card,
    Space,
    Typography,
    Steps,
    Spin,
    App,
    Modal
} from "antd";
import { toJS } from "mobx";
import { ClockCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Test, TestAttempt, UserTestAssignment } from "../../stores/TestsStore";
import TestStepTwo from "../../components/test/TestStepTwo";
import { Roles } from "../../stores/EmployeesStore";
import { AssignmentStatus, TypeTestQuestion } from "../../stores/ApplicationStore";

const { Title, Text } = Typography;
const { Step } = Steps;

const PassageTest = observer(() => {
    const API_AI_KEY = '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';
    const [testAttempt, setTestAttempt] = useState<TestAttempt | null>();
    const [existAssignment, setExistAssignment] = useState<UserTestAssignment | null>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [test, setTest] = useState<Test>();
    const { applicationStore, apiStore } = useStore();
    const { testsStore } = useStore();
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    const { id } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [answers, setAnswers] = useState<
        Array<{
            questionId: number;
            answer: string | number | number[];
        }>
    >([]);
    const [correctAnswers, setCorrectAnswers] = useState<number>(0);
    const [score, setScore] = useState<number | null>(null);
    const [existMaxGrade, setExistMaxGrade] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const companyTest = toJS(testsStore.testsCompany.find(t => t.id == Number(id)));

            if (applicationStore.user.roleId == Roles.User) {
                const userAssignments = await testsStore.getAllAssignmentByUserId(applicationStore.user.id ?? "");
                const existAssignment = userAssignments.filter(ua => ua.testId == Number(id)).filter(ua => ua.assignedToUserId == Number(applicationStore.user.id)).find(ua => ua.assignmentStatusId != AssignmentStatus.Completed);

                setExistAssignment(existAssignment ?? null);

                if (existAssignment && existAssignment.testAttempts.length > 0) {
                    const maxGrade = Math.max(...existAssignment.testAttempts.map(t => t.grade));
                    setExistMaxGrade(maxGrade);
                }
            }

            if (companyTest) {
                setTest(companyTest);
            } else {
                const publicTest = toJS(testsStore.testsPublic.find(t => t.id == Number(id)));
                if (publicTest) {
                    setTest(publicTest);
                } else {
                    message.error(t('test_not_found'));
                    navigate(`/${applicationStore.company.Tenaut}/tests`);
                }
            }

            setLoading(false);
        };

        fetchData();
    }, [testsStore]);

    const hadnleFullFinishTest = async (id: number) => {
        console.log(id);
        var result = await apiStore.TestsApiClient.markAssignmentAsCompleted(id);

        if (result) {
            message.success(t("Success"));
        }
        else {
            message.error(t("Failed"));
        }

        navigate(`/${applicationStore.company.Tenaut}/tests`);
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        handleStartTest();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleStartTest = () => {
        if (applicationStore.user.roleId === Roles.User) {
            const numberAttempt = (existAssignment?.testAttempts?.at(-1)?.numberAttempt ?? 0) + 1;

            setTestAttempt({
                id: 0,
                startDate: new Date().toISOString(),
                finishDate: '',
                grade: 0,
                numberAttempt: numberAttempt,
                timeSpentMinutes: 0,
                userTestAssignmentId: existAssignment?.id ?? 0,
            });
        };

        enableRestrictions();

        setCurrentStep(1);
    };

    const enterFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error(`Ошибка активации полноэкранного режима: ${err.message}`);
            });
        }
    };
    
    const exitFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error(`Ошибка выхода из полноэкранного режима: ${err.message}`);
            });
        }
    };

    const enableRestrictions = () => {
        document.addEventListener('copy', preventAction);
        document.addEventListener('cut', preventAction);
        document.addEventListener('contextmenu', preventAction);
        document.addEventListener('keydown', preventKeyActions);
        enterFullScreen();
    };

    const disableRestrictions = () => {
        document.removeEventListener('copy', preventAction);
        document.removeEventListener('cut', preventAction);
        document.removeEventListener('contextmenu', preventAction);
        document.removeEventListener('keydown', preventKeyActions);
        exitFullScreen();
    };

    const preventAction = (event: Event) => {
        event.preventDefault();
    };

    const preventKeyActions = (event: KeyboardEvent) => {
        if (event.ctrlKey && ['c', 'x', 'v', 'a'].includes(event.key.toLowerCase())) {
            event.preventDefault();
        }

        if (event.key === 'Escape') {
            event.preventDefault();
        }
    };

    const transformedAnswersToJSONString = (answers: Array<{ questionId: number, answer: string | number | number[] }>) => {
        const transformedAnswers = test?.testQuestions.map((question) => {
            const answer = answers.find((a) => a.questionId === question.id);

            let transformedAnswer;
            if (!answer) {
                transformedAnswer = "";
            } else {
                switch (question.typeTestQuestionId) {
                    case TypeTestQuestion.SingleChoice:
                        transformedAnswer = question.testOptions.find((o) => o.id === answer.answer)?.name || "";
                        break;
                    case TypeTestQuestion.MultipleChoice:
                        if (Array.isArray(answer.answer)) {
                            transformedAnswer = answer.answer
                                .map((id) => question.testOptions.find((o) => o.id === id)?.name)
                                .filter(Boolean)
                                .join(", ");
                        }
                        break;
                    case TypeTestQuestion.WriteCode:
                    case TypeTestQuestion.FixCode:
                    case TypeTestQuestion.OpenAnswer:
                        transformedAnswer = answer.answer;
                        break;
                    default:
                        transformedAnswer = "Неизвестный тип ответа";
                }
            }

            return {
                questionName: question.name,
                answer: transformedAnswer,
            };
        }).filter(Boolean);

        const jsonString = JSON.stringify(transformedAnswers, null, 2);

        return jsonString;
    }

    const fetchCheckTestsAnswers = async (answers: Array<{ questionId: number, answer: string | number | number[] }>, minutesSpent: number) => {

        const jsonString = transformedAnswersToJSONString(answers);

        const requestAI = `
            Please check the following test answers and provide a score based on the percentage of correct answers.
            The scoring system is as follows:
            - If the percentage is greater than or equal to 90%, the grade is 5.
            - If the percentage is between 75% and 89%, the grade is 4.
            - If the percentage is between 60% and 74%, the grade is 3.
            - If the percentage is below 60%, the grade is 2.

            Here are the user's answers in JSON format:
            ${jsonString}

            Format the output as a JSON object with fields:
            - "grade": The grade number.
            - "correctCount": The correctCount number.
            - "totalQuestions": The totalQuestions number.
            - "percentage": The percentage number.

            Validate all JSON fields to ensure proper escaping and compliance with JSON specifications. Avoid control characters like newlines or tabs inside string values.
        
            Return only the JSON array as output, without any additional text or explanations.
        `;

        try {
            const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${API_AI_KEY}`,
                },
                body: JSON.stringify({
                    messages: [
                        {
                            role: 'user',
                            content: requestAI,
                        }
                    ],
                    model: 'llama-3.3-70b-versatile',
                }),
            });

            const rawData = await response.json();

            try {
                const content = rawData.choices[0]?.message?.content || "";

                const jsonContent = content.replace(/json\n|\n/g, '').trim();

                try {
                    const result = JSON.parse(jsonContent);

                    const scoreData = result[0];

                    const { grade, correctCount, totalQuestions, percentage } = scoreData;

                    setScore(grade);
                    setCorrectAnswers(correctCount);

                    let testAttemptData: TestAttempt | null = testAttempt ?? null;
                    if (applicationStore.user.roleId === Roles.User && testAttemptData != null) {
                        await fetchGradeToServer(grade, minutesSpent);
                    }

                    console.log({
                        grade,
                        correctCount,
                        totalQuestions,
                        percentage
                    });

                } catch (error) {
                    console.error('Error parsing JSON content:', error);
                }

            } catch (error) {
                console.error('Error extracting content:', error);
            }

        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    }

    const fetchGradeToServer = async (grade: number, minutesSpent: number) => {
        let testAttemptData: TestAttempt | null = testAttempt ?? null;

        if (testAttemptData != null) {
            testAttemptData.grade = grade;
            testAttemptData.timeSpentMinutes = minutesSpent;
            testAttemptData.finishDate = new Date().toISOString();

            try {
                const result = await testsStore.addTestAttempt(testAttemptData);

                if (result != null) {
                    message.success(t("Success"));
                }

            } catch (error) {
                message.error(t("Failed"));
            }
        }
    }

    const onFinishTest = async (answers: Array<{ questionId: number, answer: string | number | number[] }>, minutesSpent: number) => {
        setAnswers(answers);
        disableRestrictions();

        fetchCheckTestsAnswers(answers, minutesSpent);
        setCurrentStep(2);
    }

    const getAttemptUsers = (): string | undefined => {
        if (applicationStore.user.roleId === Roles.User) {
            const numberOfAttempts = test?.numberOfAttempts ?? 0;
            const attemptsLength = existAssignment?.testAttempts.length ?? 0;
            return `${t("passage_test.attemptsRemaining")}: ${numberOfAttempts - attemptsLength}`;
        }

        return `${t("passage_test.number_of_attempts")}: ${test?.numberOfAttempts ?? 0}`;
    };

    return (
        <Layout headerStyle={1} footerStyle={2}>
            <Spin spinning={loading} style={{ minHeight: 600 }}>
                <div style={{ padding: "20px" }}>
                    <Steps current={currentStep} style={{ marginBottom: "20px" }}>
                        <Step title={t(`passage_test.test_details`)} />
                        <Step title={t("passage_test.test_in_progress")} />
                        <Step title={t("passage_test.test_complete")} />
                    </Steps>

                    {currentStep === 0 && (
                        <Card style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                                <Title level={3}>{t("passage_test.test_details")}</Title>
                                {test?.name != null && (
                                    <Text>{t("add_test.Name")}: {test?.name}</Text>
                                )}
                                <Text>{t("passage_test.topic")}: {applicationStore.skills.find(s => s.skillId == test?.skillId?.toString())?.title || t("unknown_skill")}</Text>
                                <Text>{getAttemptUsers()}</Text>
                                {existMaxGrade != null && (
                                    <Text>{t(`passage_test.max_grade`)}: {existMaxGrade}</Text>
                                )}
                                {test?.language != null && (
                                    <Text>{t("tests.Language")}: {test.language == "en" ? t("add_test.English") : test.language == "ua" ? t("add_test.Ukrainian") : t("add_test.Russian")}</Text>
                                )}
                                <Text>
                                    <QuestionCircleOutlined /> {t("passage_test.number_of_questions")}: {test?.numberOfQuestions}
                                </Text>
                                <Text>
                                    <ClockCircleOutlined /> {t("passage_test.time_limit")}: {test?.maxTimeMinutes} {t("passage_test.minutes")}
                                </Text>
                                {(test?.description != null && test.description.length > 0) && (
                                    <Text>{t("add_test.Description")}: {test?.description}</Text>
                                )}
                                <Button
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={showModal}
                                >
                                    {t("passage_test.start_test")}
                                </Button>

                                {existAssignment && existAssignment.testAttempts.length > 0 ? (
                                    <Button
                                        type="primary"
                                        size="large"
                                        block
                                        style={{ backgroundColor: 'GrayText' }}
                                        onClick={async () => await hadnleFullFinishTest(existAssignment?.id ?? 0)}
                                    >
                                        {t("passage_test.finish_tests")}
                                    </Button>
                                ) : null}
                            </Space>
                        </Card>
                    )}

                    {currentStep === 1 && (
                        <TestStepTwo test={test!} onFinish={onFinishTest} />
                    )}

                    {currentStep === 2 && (
                        <Card style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                            <Title level={3}>{t("passage_test.test_complete")}</Title>
                            <div style={{ marginTop: "30px" }}>
                                <Title level={4}>{t("passage_test.your_score")}</Title>
                                <Text>{t("passage_test.correct_answers")}: {correctAnswers} / {test?.testQuestions.length}</Text>
                                <Text style={{ display: "block", marginTop: "10px" }}>
                                    {t("passage_test.score")}: {score} / 5
                                </Text>
                            </div>
                            <Button style={{ marginTop: 20 }} type="primary" onClick={() => navigate(`/${applicationStore.company.Tenaut}/tests`)}>
                                {t("passage_test.back_to_tests")}
                            </Button>
                        </Card>
                    )}
                </div>

                <Modal
                    title={t("passage_test.modal_title")}
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={t("passage_test.modal_confirm")}
                    cancelText={t("passage_test.modal_cancel")}
                >
                    <p>{t("passage_test.modal_content")}</p>
                </Modal>
            </Spin>
        </Layout>
    );
});

export default PassageTest;
