import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { Button, Flex, message, Popconfirm, Spin, Table, TableProps, Tabs, DatePicker, Image, Switch, Space } from "antd";
import { Project } from "../../stores/ProjectsStore";
import { RightOutlined, DownOutlined, UpOutlined, PlusOutlined, EditOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { useStore } from "../../stores/root-store-context";
import { Roles } from "../../stores/EmployeesStore";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Breadcrumb from '../../components/layout/Breadcrumb';
import { TimeReport } from "../../api/TimeReportsApiClient";
import { Employee } from "../../api/EmployeesApiClient";
import { AddTimeReport } from "./AddTimeReport";
import { useTranslation } from "react-i18next";
import { EditTimeReport } from "./EditTimeReport";
import i18n from "../../i18n";
import { MyTime } from "./MyTime";
import { getUserComponent } from "../../helpers/user";
import { UsersTime } from "./UsersTime";
import { sumAllTime } from "../../helpers/timeReports";
import ProjectsTimeReports from "./ProjectsTimeReports";
import EventPage from "../Events/EventPage";
import CalendarEvents from "../Events/CalendarEvents";
import { CalendarEvent } from "../../api/EventsApiClient";
import UserTaskPage from "../UserTasks/UserTaskPage";
import { useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';
import TimeReportsByDay from "./TimeReportsByDay";
import LeaveCalendar from './LeaveCalendar';
import ByUser from "./ByUser";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(duration);

const { RangePicker } = DatePicker;

const TimeReports = observer(() => {

    const { applicationStore, timeReportStore, employeesStore, projectsStore, eventStore } = useStore();
    const navigate = useNavigate();
    const { tabKey } = useParams<{ tabKey: string }>();
    const { t } = useTranslation();
    const location = useLocation();
    const [refresh, setRefresh] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState(tabKey || 'my');// 
    const [activeProjectTabKey, setActiveProjectTabKey] = useState('byProjects');
    const [activeUserTabKey, setActiveUserTabKey] = useState('ByUser');

    useEffect(() => {
        if (!tabKey) {
            navigate(`/${applicationStore.company?.Tenaut}/time-reports/my`, { replace: true });
        }
    }, [tabKey, navigate]);

    useEffect(() => {
        const parsedQuery = queryString.parse(location.search);
        if (parsedQuery.tab) {
            setActiveTabKey(parsedQuery.tab as string);
        }
    }, [location.search]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);

    const showModalEdit = () => {
        setIsModalOpenEdit(true);
    };

    const hideModalEdit = () => {
        setIsChangeUser(true);
        setIsModalOpenEdit(false);
    };

    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf("month").local());
    const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf("month").local());
    const [isAllTimeChecked, setIsAllTimeChecked] = useState(false);


    const [expandedProject, setExpandedProject] = useState<string[]>([]);
    const onExpandProject = (expand: boolean, record: any) => {
        if (expand) {
            setExpandedProject(
                [
                    ...expandedProject,
                    record.key
                ]
            );
        } else {
            setExpandedProject(
                expandedProject.filter(a => a !== record.key)
            );
        }
    };
    const [editTimeReport, setEditTimeReport] = useState<TimeReport>();


    const [logUserId, setLogUserId] = useState<string>("");
    const [logProjectId, setLogProjectId] = useState<string>("");
    const [isChangeUser, setIsChangeUser] = useState<boolean>(true);

    const [timeByMe, setTimeByMe] = useState<any[]>([]);
    const [timeByUsers, setTimeByUsers] = useState<any[]>([]);
    const [timeByProjects, setTimeByProjects] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>([]);

    const resetToCurrentMonth = () => {
        setStartDate(dayjs().startOf("month").local());
        setEndDate(dayjs().endOf("month").local());
    };

    const setFullYearRange = () => {
        setStartDate(dayjs().startOf('year').local());
        setEndDate(dayjs().endOf('year').local());
    };
    const handleTabChange = (activeKey: string) => {
        setActiveTabKey(activeKey);
        navigate(`/${applicationStore.company?.Tenaut}/time-reports/${activeKey}`);
        console.log("keykeykeykey1", activeKey);

        if (activeKey === 'allUsers') {

            if (activeUserTabKey === "vacationSickLeave") {
                setFullYearRange();
            } else {
                resetToCurrentMonth();
            }
            //timeReportStore.setFullScreen(activeUserTabKey === "ByDay");
        } else if (activeKey === 'allProjects') {
            //timeReportStore.setFullScreen(activeProjectTabKey === "byDay");
            resetToCurrentMonth();
        } else {
            //timeReportStore.setFullScreen(false);
            resetToCurrentMonth();
        }

        switch (activeKey) {
            case "my":
                timeReportStore.getMyTime(applicationStore.user.id);
                setIsAllTimeChecked(false);
                break;
            case "allUsers":
            case "allProjects":
                timeReportStore.getAll();
                setIsAllTimeChecked(false);
                break;
            case "Events":
            case "Calendar":

                if (employeesStore.users.length > 0) {
                    eventStore.getAll();
                    eventStore.getBirthdays(employeesStore.users);
                } else {
                    employeesStore.getUsers().then(users => {
                        eventStore.getBirthdays(users);
                        eventStore.getAll();
                    })
                }
                setIsAllTimeChecked(false);
                break;
            case "vacationSickLeave":
                eventStore.getAll();
                setFullYearRange();
                break;
        }
    };


    const handleUserTabChange = (key: string) => {
        setActiveUserTabKey(key);
        //timeReportStore.setFullScreen(key === "ByDay");
    };

    const handleProjectTabChange = (key: string) => {
        setActiveProjectTabKey(key);

        if (key === "byDay") {
            //timeReportStore.setFullScreen(true);
        } else {
            //timeReportStore.setFullScreen(false);
        }
    };

    const getByUsers = () => {
        if (timeReportStore.reports || timeReportStore.reportsForFinancier) {
            let result: any[];

            if ((applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) && timeReportStore.reportsForFinancier) {
                result = timeReportStore.reportsForFinancier.filter((el: { log: TimeReport; payment: number }) =>
                    employeesStore.users.some(user => user.id === el.log.userId) && el.log.endTime?.format("YYYY-MM-DD") >= startDate.format("YYYY-MM-DD") &&
                    el.log.endTime?.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD")
                );
            } else {
                result = timeReportStore.reports.filter((el: TimeReport) =>
                    employeesStore.users.some(user => user.id === el.userId) && el.endTime?.format("YYYY-MM-DD") >= startDate.format("YYYY-MM-DD") &&
                    el.endTime?.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD")
                );
            }

            result = result.filter((el: any) => {
                const startTime = el.log ? el.log.startTime : el.startTime;
                const endTime = el.log ? el.log.endTime : el.endTime;

                return startTime?.isSameOrAfter(startDate, "day") && endTime?.isSameOrBefore(endDate, "day");
            });

            const groupByUserId = (array: any[]) => {
                const grouped = array.reduce((acc: any, obj: any) => {
                    const userId = obj.log ? obj.log.userId : obj.userId;
                    if (!acc[userId]) {
                        acc[userId] = {
                            key: userId,
                            user: employeesStore.users.find(user => user.id === userId),
                            reports: [],
                            totalHours: 0,
                            totalAmount: 0,
                        };
                    }

                    const report = obj.log ? obj.log : obj;
                    acc[userId].reports.push(report);

                    acc[userId].totalHours += parseFloat(report.totalTime);
                    if (obj.payment) {
                        acc[userId].totalAmount += obj.payment;
                    }

                    return acc;
                }, {});

                return Object.values(grouped);
            };

            const byUsers = groupByUserId(result).map((el: any) => {
                el.reports = el.reports.sort((a:any, b:any) => { return DateSort(a.endTime, b.endTime) });
                return el;
            });
            console.log("Grouped by users: ", JSON.parse(JSON.stringify(byUsers)));
            setTimeByUsers(byUsers);
        }
    };



    const getByMe = async () => {
        let result;

        if (applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) {
            const reportsForFinancier = await timeReportStore.getForFinancier();
            result = reportsForFinancier.filter((el: { log: TimeReport, payment: number }) =>
                el.log.userId === applicationStore.user.id &&
                el.log.endTime?.isSameOrAfter(startDate, "day") &&
                el.log.endTime?.isSameOrBefore(endDate, "day")
            );


            setTimeByMe(result);
            // setMyTime(sumAllTime(result.map((el: { log: TimeReport }) => el.log)));
            // setMySalaryStr(timeReportStore.mySalary);

        } else {
            result = timeReportStore.filterByUserId(applicationStore.user.id);

            result = result.filter((el: TimeReport) =>
                el.endTime.isSameOrAfter(startDate, "day") &&
                el.endTime.isSameOrBefore(endDate, "day")
            );

            setTimeByMe(result);
            // setMyTime(sumAllTime(result));
        }
    };


    const getByProjects = async () => {
        let result;

        if ((applicationStore.user.roleId === Roles.Financier || applicationStore.user.roleId === Roles.Owner) && timeReportStore.reportsForFinancier) {
            const reportsForFinancier = await timeReportStore.getForFinancier();
            result = reportsForFinancier.filter((el: { log: TimeReport, payment: number }) =>
                projectsStore.projects.some(proj => proj.id === el.log.projectId)
            );

            result = result.filter((el: { log: TimeReport, payment: number }) =>
                el.log.startTime?.isAfter(startDate, "day") && el.log.endTime?.isBefore(endDate, "day")
            );

            const groupByProjectId = (array: { log: TimeReport, payment: number }[]) => {
                const grouped = array.reduce((acc: any, obj) => {
                    const { log } = obj;
                    const { projectId } = log;
                    if (!acc[projectId]) {
                        acc[projectId] = {
                            key: projectId,
                            project: projectsStore.projects.find(item => item.id === projectId),
                            reports: []
                        };
                    }
                    acc[projectId].reports.push(log);
                    return acc;
                }, {});

                return Object.values(grouped);
            };

            const byProjects = groupByProjectId(result);
            console.log("Grouped by projects for financier: ", byProjects);
            setTimeByProjects(byProjects);

        } else {
            result = timeReportStore.reports.filter((el: any) =>
                projectsStore.projects.some(proj => proj.id === el.projectId)
            );

            result = result.filter((el: TimeReport) =>
                el.startTime.isSameOrAfter(startDate, "day") && el.endTime.isSameOrBefore(endDate, "day")
            );

            const groupByProjectId = (array: TimeReport[]) => {
                const grouped = array.reduce((acc: any, obj) => {
                    const { projectId } = obj;
                    if (!acc[projectId]) {
                        acc[projectId] = {
                            key: projectId,
                            project: projectsStore.projects.find(item => item.id === projectId),
                            reports: []
                        };
                    }
                    acc[projectId].reports.push(obj);
                    return acc;
                }, {});

                return Object.values(grouped);
            };

            const byProjects = groupByProjectId(result);
            console.log("Grouped by projects for regular user: ", byProjects);
            setTimeByProjects(byProjects);
        }
    };


    const getEvents = () => {
        if (eventStore.events) {
            const events = eventStore.events.filter((el: CalendarEvent) => el.startDate.isSameOrBefore(endDate, "day") && el.endDate.isSameOrAfter(startDate, "day"));
            const birthDays2 = eventStore.birthdays?.filter((el: CalendarEvent) => {
                const eventDay = el.startDate.date();
                const eventMonth = el.startDate.month();
                const currentDay = startDate.date();
                const currentMonth = startDate.month();
                return eventDay === currentDay && eventMonth === currentMonth;
            });

            setEvents([...events, ...birthDays2]);
        }
    }

    const deleteTime = async (id: any) => {
        await timeReportStore.deleteById(id).then(() => {
            message.destroy("Deleting");
            message.success("The time was deleted");
        }).catch((error) => {
            message.error(error);
        })
    }

    useEffect(() => {
        employeesStore.getUsers().then(res => {
            projectsStore.getAll();
            timeReportStore.getAll();
            //timeReportStore.getMyTime(applicationStore.user.id, startDate, endDate);
        });
    }, [])

    useEffect(() => {
        getByProjects();
        getByUsers();
        getByMe();
    }, [timeReportStore.reports, startDate, endDate, refresh])

    useEffect(() => {
        getByMe();
    }, [timeByUsers])


    useEffect(() => {
        getEvents();
    }, [eventStore.events, eventStore.birthdays, startDate, endDate])

    const isChief = employeesStore.users.some(user => user.chiefId === applicationStore.user.id);

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("Time Reports")}>
            <div className={timeReportStore.isFull ? "" : "container"} style={{ margin: "20px auto" }}>
                <Spin spinning={timeReportStore.isLoading}>
                    <Flex justify="end">
                        <Flex gap={5} align="baseline">
                            <Button shape="circle" onClick={(e) => {
                                setStartDate(startDate.subtract(1, "month").startOf("month").local())
                                setEndDate(startDate.subtract(1, "month").endOf("month").local())
                            }}>
                                <LeftOutlined />
                            </Button>
                            <RangePicker
                                value={[startDate, endDate]}
                                picker="date"
                                format="DD MMMM YYYY"
                                style={{ marginBottom: 16 }}
                                onChange={(dates: any) => {
                                    setStartDate(dates[0].local())
                                    setEndDate(dates[1].local())
                                }}
                            />
                            <Button shape="circle" onClick={(e) => {
                                setStartDate(startDate.add(1, "month").startOf("month").local())
                                setEndDate(startDate.add(1, "month").endOf("month").local())
                            }}>
                                <RightOutlined />
                            </Button>
                        </Flex>
                    </Flex>

                    <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                        <TabPane tab={t('TimeReports.MyTime')} key="my">
                            <MyTime
                                setLogUserId={setLogUserId}
                                setLogProjectId={setLogProjectId}
                                showModalAdd={showModal}
                                showModalEdit={showModalEdit}
                                deleteTime={deleteTime}
                                setIsChangeUser={setIsChangeUser}
                                setEditTimeReport={setEditTimeReport}
                                timeReports={timeByMe} />
                        </TabPane>
                        <TabPane tab={t('TimeReports.ByUsers')} key="allUsers">
                            <ByUser setLogUserId={setLogUserId}
                                setLogProjectId={setLogProjectId}
                                showModalAdd={showModal}
                                showModalEdit={showModalEdit}
                                deleteTime={deleteTime}
                                setIsChangeUser={setIsChangeUser}
                                setEditTimeReport={setEditTimeReport}
                                timeReports={timeByUsers}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                resetToCurrentMonth={resetToCurrentMonth}
                                setFullYearRange={setFullYearRange}
                                activeUserTabKey={activeUserTabKey}
                                onUserTabChange={handleUserTabChange}
                                isAllTimeChecked={isAllTimeChecked}
                                setIsAllTimeChecked={setIsAllTimeChecked} />
                        </TabPane>

                        {/*<TabPane tab="Vacation/Sick Leave" key="vacationSickLeave">
                            <LeaveCalendar 
                                startDate={startDate}
                                endDate={endDate}
                                isAllTimeChecked={isAllTimeChecked} 
                                onToggleAllTime={(newStartDate: dayjs.Dayjs, newEndDate: dayjs.Dayjs) => {
                                    setStartDate(newStartDate);
                                    setEndDate(newEndDate);
                                    setIsAllTimeChecked(newStartDate.isBefore(dayjs().startOf('year')));
                                }}
                            />
                        </TabPane> */}

                        <TabPane tab={t('TimeReports.ByProjects')} key="allProjects">
                            <ProjectsTimeReports
                                setLogUserId={setLogUserId}
                                setLogProjectId={setLogProjectId}
                                showModalAdd={showModal}
                                showModalEdit={showModalEdit}
                                deleteTime={deleteTime}
                                setIsChangeUser={setIsChangeUser}
                                setEditTimeReport={setEditTimeReport}
                                timeReports={timeByProjects}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                activeProjectTabKey={activeProjectTabKey}
                                onProjectTabChange={handleProjectTabChange}
                            />
                        </TabPane>

                        <TabPane tab={t('TimeReports.Events')} key="Events">
                            <EventPage events={events} />
                        </TabPane>
                        {/* <TabPane tab="ByDay" key="ByDay">
                            <TimeReportsByDay startDate={startDate} endDate={endDate} />
                        </TabPane> */}
                        {isChief && (
                            <TabPane tab={t('TimeReports.Tasks')} key="Tasks">
                                <UserTaskPage />
                            </TabPane>
                        )}
                        <TabPane tab={t('TimeReports.Calendar')} key="Calendar">
                            <CalendarEvents events={events} startDate={startDate} endDate={endDate} />
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>

            <AddTimeReport isModalOpen={isModalOpen} hideModal={hideModal} projectId={logProjectId} userId={logUserId} isChangeUser={isChangeUser} refresh={refresh} setRefresh={setRefresh} />
            {editTimeReport &&
                <EditTimeReport isModalOpen={isModalOpenEdit} hideModal={hideModalEdit} id={editTimeReport?.id} time={editTimeReport?.totalTime} endTime={editTimeReport.endTime} description={editTimeReport?.description} />
            }
        </Layout>
    )
})
export default TimeReports;