import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";

type TariffPlans={
  id: number;
  name: string;
  description: string;
  maxUsers: number;
  maxProjects: number;
  pricePerMonth: number;
  pricePerYear: number;
  isPublic: boolean;
}

class TariffPlansStore {
  tariffPlans: TariffPlans[] = [];
  isLoading = true;

  ApiStore!: ApiStore;

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
  }

  getTariffPlans = async () => {
    this.isLoading = true;
    const tariffPlans = await this.ApiStore.TariffPlansApiClient.getTariffPlans();
    this.tariffPlans = tariffPlans;
    this.isLoading = false;
  };
}

export default TariffPlansStore;
