import { UploadFile } from "antd";
import SkTechApiClient from "./base/SkTechApiClient";
import { ResourceItem, ResourceType } from "../stores/ResourcesStore";


export default class UserResourceApi extends SkTechApiClient {

  public getAll = async (): Promise<ResourceItem[]> => {
    return await this.Get(`/api/UserResource`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getAllGroups = async (): Promise<ResourceType[]> => {
    return await this.Get(`/api/UserResource/Groups`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public add = async (item: ResourceItem): Promise<any> => {
      return await this.Post(`/api/UserResource`, item)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          }
          return false;
        });
    }

    public addGroups = async (title: string): Promise<any> => {
      return await this.Post(`/api/UserResource/Groups`, {
        title: title
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          }
          return false;
        });
    }
    
    public update = async (item: ResourceItem): Promise<any> => {
      return await this.Post(`/api/ProjectTask/${item.id}`, item)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          }
          return false;
        });
    }

    public delete = async (id: number): Promise<any> => {
      return await this.Post(`/api/ProjectTask/${id}`)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          }
          return false;
        });
    }
  
}