import axios, { AxiosError, AxiosResponse } from "axios";
import SkTechApiClient from "../base/SkTechApiClient";
import { Employee } from "../EmployeesApiClient";
import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { StringLiteral } from "typescript";
import { ConfirmUserItem } from "../../pages/RegisterByInvitation";

export default class CommonApiClient extends SkTechApiClient {
  public getEducation = async (
    country: string,
    city: string,
    language: string
  ) => {
    /* return await axios({
      method: 'get',
      url: `https://localhost:7075/api/University/GetUniversities?country=${country}&city=${city}`
      //url: `https://maps.googleapis.com/maps/api/place/textsearch/json?query=Education+in+${country}+${city}&language=${language}&key=AIzaSyBIRJi8byb-jIg9Yij2rl948wB3Qd0Fh5I`,
    }); */
    return await this.Get(
      `api/ExternalServices/GetUniversities?country=${country}&city=${city}&language=${language}`
    );
  };
  public getCities = async (country: string) => {
    return await axios.post(
      `https://countriesnow.space/api/v0.1/countries/cities`,
      {
        country: `${country}`,
      }
    );
  };
  public getCountries = async () => {
    // return await axios.get("https://restcountries.com/v3.1/all");
  };
  public addEducation = async (
    userId: string,
    country: string,
    city: string,
    university: string,
    profession: string,
    degree: string,
    startDate: string,
    endDate: string
  ) => {
    return await this.Post(`/api/CompanyUsers/AddEducation?userId=${userId}`, {
      country: country,
      city: city,
      university: university,
      profession: profession,
      degree: degree,
      startDate: startDate,
      endDate: endDate,
    })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public removeEducation = async (
    country: string,
    city: string,
    university: string,
    profession: string,
    degree: string,
    startDate: string,
    endDate: string
  ) => {
    return await this.Post(`/api/CompanyUsers/RemoveEducation`, {
      country: country,
      city: city,
      university: university,
      profession: profession,
      degree: degree,
      startDate: startDate,
      endDate: endDate,
    })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public AssignSkillToUser = async (
    skillId: string,
    userId: number,
    level: number,
    experience: number
  ) => {
    return await this.Get(
      `/api/Skill/AssignSkillToUser?skillId=${skillId}&userId=${userId}&skillLevel=${level}&skillExperienceYears=${experience}`
    )
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public RemoveSkillFromUser = async (skillId: number, userId: number) => {
    return await this.Get(
      `/api/Skill/RemoveSkillFromUser?skillId=${skillId}&userId=${userId}`
    )
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public GetEvents = async () => {
    return await this.Get(`/api/EventCalendar`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };
  public AddEvent = async (value: any) => {
    return await this.Post(`/api/EventCalendar`, value)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public SupportRequest = async (value: any) => {
    return await this.Post(`/api/Report/SendReport`, value)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
  public DeleteEvent = async (id: any) => {
    return await this.Delete(`/api/EventCalendar?id=${id}`)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public loginGoogle = async (googleToken: string): Promise<any> => {
    return await fetch(process.env.REACT_APP_API_URL + "/api/Auth/google-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: googleToken }),
    })
      .then((response) => {
        return response.json();
      })
      .then(async function (data) {
        console.log("google-login");
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };


  public login = async (login: string, password: string): Promise<any> => {
    return await this.Post(process.env.REACT_APP_API_URL + "/api/Auth/login", {
      email: login,
      password: password,
    })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          return true;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public autorize = async (): Promise<any> => {
    return await this.Get("/api/Account/me")
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public getSkills = async (): Promise<any> => {
    return await this.Get("/api/Skill")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public addSkills = async (title: string): Promise<any> => {
    return await this.Post("/api/Skill", {
      title: title,
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public getJobTitles = async (): Promise<any> => {
    return await this.Get("/api/JobTitle")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public addJobTitles = async (
    title: string,
    description?: string
  ): Promise<any> => {
    return await this.Post("/api/JobTitle/AddJobTitle", {
      title: title,
      description: description,
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public companyCreate = async (
    title: string,
    description: string,
    tenant: string,
    adress: string
  ): Promise<any> => {
    return await this.Post("/api/CompanyCreate", {
      title: title,
      description: description,
      tenant: tenant,
      adress: adress,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public registerByInvitation = async (
    user: ConfirmUserItem
  ): Promise<any> => {
    return await this.Post("/api/Account/RegisterByInvitation", {
      email: user.email,
      token: user.token,
      firstName: user.firstName,
      lastName: user.lastName,
      city: user.city || "",
      phoneNumber: user.phoneNumber || "",
      isPhoneNumberPrivate: user.isPhoneNumberPrivate || false,
      skype: user.skype || "",
      password: user.password,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public isApproveInvite = async (
    email: string
  ): Promise<any> => {
    return await this.Get(`/api/Account/IsApproveInvite?email=${email}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        }
        return true;
      })
      .catch(function (error) {
        console.log(error);
        return true;
      });
  };

  public accountRegister = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyId: number,
    role: string = "user"
  ): Promise<any> => {
    return await this.Post("/api/Account/register", {
      email: email,
      password: password,
      FirstName: firstName,
      LastName: lastName,
      companyId: companyId,
      role: role,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public fileUpload = async (file: UploadFile): Promise<any> => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as RcFile);
    return await this.Post("/api/File/upload", formData)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  public CompanyCheck = async (name: string): Promise<boolean> => {
    return await this.Get(`/api/Company/Check?companyName=${name}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };
}
