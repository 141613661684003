import { Button, Flex, Form, message, Modal, Select, Input, DatePicker, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/root-store-context";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Option } from "antd/es/mentions";

export const AddResourceGroup = observer((props: { isModalOpen: boolean, hideModal: () => void, userId?: string }) => {
    const { isModalOpen, hideModal, userId } = props;
    const { resourceStore } = useStore();
    const [messageApi] = message.useMessage();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const onFinish = async () => {
        const title = form.getFieldValue("title");
        const description = form.getFieldValue("description");
        await resourceStore.createNewGroup(title).then(async (res: any) => {
            return res;
        }).catch(console.log)
    };


    return (
        <Spin spinning={resourceStore.isLoading}>
            <Modal
                title="New Resource Group"
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    hideModal();
                    form.resetFields();
                }}
            >
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item<any>
                        label={`${t("Title")}`}
                        name="title"
                        style={{ width: "100%" }}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder={`${t("Title")}`}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            className="theme-btn"
                            htmlType="submit"
                            style={{ height: 65, float: "right" }}
                        >
                            {t("Add")} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    )
})