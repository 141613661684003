import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../stores/root-store-context";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Option } from "antd/es/mentions";
import { CalendarEvent } from "../../api/EventsApiClient";
dayjs.extend(utc);

const AddEvent = observer((props: { isModalOpen: boolean, hideModal: () => void }) => {
    const { isModalOpen, hideModal } = props;
    const [form] = Form.useForm();

    const { employeesStore, applicationStore, eventStore } = useStore();
    const { t } = useTranslation();

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const showConfirmationModal = () => {
        setIsConfirmationModalOpen(true);
    };

    const hideConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
    };

    const isHasChief = applicationStore.user.chiefId && Number(applicationStore.user.chiefId) > 0 ? true : false;

    const addEvent = async () => {
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'saving'
        });

        const startDate = dayjs(form.getFieldValue("startDate")).format('YYYY-MM-DD');
        const endDate = dayjs(form.getFieldValue("endDate")).format('YYYY-MM-DD');
        const eventType = form.getFieldValue("type");
        const event: any = {
            startDate: startDate,
            endDate: endDate,
            type: form.getFieldValue("type"),
            userId: applicationStore.user.id
        }

        eventStore.add(event).then(() => {
            message.destroy('saving');
            message.success("Success");
            hideModal();
            eventStore.getAll();
            if (eventType === '0') {
                showConfirmationModal();
            }
        }).catch((err) => {
            message.error("Error: " + err);
        });
    }

    const disabledDate = (current: dayjs.Dayjs) => {
        return current && current.isBefore(dayjs().startOf('day'));
    };

    return (
        <>
        <Modal open={isModalOpen} footer={null} onCancel={hideModal} title={`${t("Add")}`}>
            <Form form={form} onFinish={addEvent} layout="vertical">
                <Form.Item
                    name="type"
                    label={t("EventType")}
                    rules={[{ required: true, message: t("Please select an event type!") }]}
                >
                    <Select
                        placeholder={t("EventType")}
                        options={[
                            { value: '0', label: t("Leave") },
                            { value: '1', label: t("SickLeave") },
                            // { value: '2', label: t("Birthday") },
                            { value: '3', label: t("TimeOff") },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    name="startDate"
                    label={t("StartDate")}
                    rules={[{ required: true, message: t("Please select a start date!") }]}
                >
                    <DatePicker
                        format='YYYY-MM-DD'
                        placeholder={t("StartDate")}
                        disabledDate={disabledDate}
                    />
                </Form.Item>

                <Form.Item
                    name="endDate"
                    label={t("DueDate")}
                    dependencies={['startDate']}
                    rules={[
                        { required: true, message: t("Please select an end date!") },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const startDate = getFieldValue('startDate');
                                if (!value || !startDate || value.isSameOrAfter(startDate)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t("End date cannot be earlier than start date!")));
                            },
                        }),
                    ]}
                >
                    <DatePicker
                        format='YYYY-MM-DD'
                        placeholder={t("DueDate")}
                        disabledDate={disabledDate}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, float: "right" }}>
                        {t("Save")} <i className="fa-solid fa-arrow-right-long" />
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            open={isConfirmationModalOpen}
            onCancel={hideConfirmationModal}
            footer={[
                <Button key="ok" type="primary" onClick={hideConfirmationModal} style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                    OK
                </Button>,
            ]}
            closable={true}
            bodyStyle={{ textAlign: 'center', fontSize: '18px'  }}
        >
            <p style={{ marginBottom: '32px', marginTop: '32px' }}>
            {isHasChief ? t("LeaveConfirmation") : t("LeaveAutoConfirmation")}
            </p>
        </Modal>
        </>
    )
})
export default AddEvent;