import axios from "axios";
import { ConfigApi } from "./ConfigApi";
import { message } from "antd";

class SkTechApiClient {
  protected _configApi: ConfigApi;
  private BASE_URL: string;

  constructor(configApi: ConfigApi) {
    this.BASE_URL = process.env.REACT_APP_API_URL as string;
    this._configApi = configApi;
  }

  private getSkTechHeader = async (): Promise<Record<string, string>> => {
    return {
      "Authorization": `Bearer ${this._configApi.token}`,
      "Accept": `text/plain`,
    };
  };

  protected Get = async (url: string, responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'): Promise<any> => {
    return await axios({
      method: 'get',
      baseURL: this.BASE_URL,
      url: url,
      headers: await this.getSkTechHeader(),
      ...(responseType && { responseType }),
    });
  }

  protected Post = async (url: string, data?: Record<any, any>): Promise<any> => {
    const config = {
      method: 'post',
      baseURL: this.BASE_URL,
      url: url,
      headers: await this.getSkTechHeader(),
      ...(data !== undefined && { data: data }),
    };
    return await axios(config);
  }

  protected Put = async (url: string, data?: Record<any, any>): Promise<any> => {
    const config = {
      method: 'put',
      baseURL: this.BASE_URL,
      url: url,
      headers: await this.getSkTechHeader(),
      ...(data !== undefined && { data: data }),
    };
    return await axios(config);
  }

  protected Patch = async (url: string, data?: Record<any, any>): Promise<any> => {
    const config = {
      method: 'patch',
      baseURL: this.BASE_URL,
      url: url,
      headers: await this.getSkTechHeader(),
      ...(data !== undefined && { data: data }),
    };
    return await axios(config);
  }

  protected Delete = async (url: string, data?: Record<any, any>): Promise<any> => {
    const config = {
      method: 'delete',
      baseURL: this.BASE_URL,
      url: url,
      headers: await this.getSkTechHeader(),
      ...(data !== undefined && { data: data }),
    };
    return await axios(config);
  }

  protected tryGet = async (url: string, noContentIsOk: boolean = false): Promise<any> => {
    try {
      return await axios({
        method: "get",
        baseURL: this.BASE_URL,
        url: url,
        headers: await this.getSkTechHeader(),
      }).catch((error) => {
        console.log(error);
        message.error(`Error: ${error.message}`);
        return error;
      });
    } catch (error:any) {
      console.log(error);
      message.error(`Error: ${error.message}`);
      return undefined;
    }
  };

  protected tryPost = async (
    url: string,
    data?: Record<any, any> | string,
    noContentIsOk: boolean = false
  ): Promise<any> => {
    const config = {
      method: "post",
      url: url,
      baseURL: this.BASE_URL,
      headers: await this.getSkTechHeader(),
      ...(data !== undefined && { data: data }),
    };
    try {
      const res = await axios(config);
      if (res.statusText === 'OK' || (noContentIsOk && res.statusText === 'No Content')) {
        return res;
      }
      console.log(`url:`, url);
      console.log(`request data:`, data);
      console.log(`response:`, res);
      return undefined;
    } catch (error) {
      message.error(`Error: ${error}`);
      console.log(`url:`, url);
      console.log(`request data:`, data);
      return undefined;
    }
  };
}

export default SkTechApiClient;
