import { observer } from "mobx-react-lite";
import { TextSort } from "../../helpers/SorterHelper";
import { DownOutlined, UpOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import { getUserComponent } from "../../helpers/user";
import { useTranslation } from "react-i18next";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Select, Image, Spin, Table, TableProps, Tag, Typography, App, Tabs } from "antd";
import { Employee } from "../../api/EmployeesApiClient";
import { Roles } from "../../stores/EmployeesStore";
import { TestAttempt, UserTestAssignment } from "../../stores/TestsStore";

const { TabPane } = Tabs;

export const UserTestInfo = observer(({ userAssignments, setUserAssignments }: { userAssignments: UserTestAssignment[], setUserAssignments: (userAssignments: UserTestAssignment[]) => void }) => {
    const { t } = useTranslation();
    const { testsStore } = useStore();
    const { applicationStore, employeesStore } = useStore();
    const [expandedSkills, setExpandedSkills] = useState<{ [key: string]: boolean }>({});
    const [filteredUser, setFilteredUser] = useState<Employee[]>([]);
    const [expandedRowKey, setExpandedRowKey] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAttempts, setSelectedAttempts] = useState<TestAttempt[]>([]);
    const { message } = App.useApp();

    const showAttempts = (attempts: TestAttempt[]) => {
        setSelectedAttempts(attempts);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedAttempts([]);
    };

    const onExpandHandle = (expanded: boolean, record: Employee) => {
        if (expanded) {
            setExpandedRowKey([record.id]);
        } else {
            setExpandedRowKey([]);
        }
    };

    useEffect(() => {
        employeesStore.getUsers().then(res => {
            setFilteredUser(employeesStore.users.filter(u => u.id == applicationStore.user.id));
        })
    }, []);

    const columns: TableProps<Employee>['columns'] = [
        {
            title: `${t("user.Name")}`,
            dataIndex: 'photoId',
            key: 'photo',
            render: (data, record) => <div>{getUserComponent(record, applicationStore.company?.Tenaut)}</div>,
        },
        Table.EXPAND_COLUMN,
        {
            title: `${t("user.JobTitle")}`,
            dataIndex: 'jobTitleId',
            key: 'jobTitle',
            render: (jobTitleId) => {
                const jobTitle = employeesStore.jobTitles.find(el => el.id === jobTitleId)?.title;
                return <div>{jobTitle || t(" ")}</div>;
            },
        },
        {
            title: `${t("Skills")}`,
            dataIndex: 'userSkills',
            key: 'userSkills',
            render: (skills, project) => <Flex wrap style={{ maxWidth: 200 }} gap={10}>
                {(expandedSkills[project.id] ? skills : skills.slice(0, 5)).map((el: any) => (
                    <Tag color="blue" key={el.skill.title}>{el.skill.title}</Tag>
                ))}
                {skills?.length > 5 && (
                    expandedSkills[project.id] ? (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: false
                        }))} style={{ cursor: 'pointer' }} color="blue">{t("ShowLess")}</Tag>
                    ) : (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: true
                        }))} style={{ cursor: 'pointer' }} color="blue">+{skills.length - 5} more</Tag>
                    )
                )}
            </Flex>,
        },
    ];

    return (
        <Spin spinning={employeesStore.isLoading}>
            {applicationStore.isFreezeModalVisible ? (
                <p style={{ padding: '20px', textAlign: 'center' }}>Access to users is limited until the subscription is renewed.</p>
            ) : (
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredUser.filter(user => user.isActive !== false)}
                    expandable={{
                        expandedRowKeys: expandedRowKey,
                        onExpand: onExpandHandle,
                        expandIcon: ({ expanded, onExpand, record }: any) => (
                            <Button
                                shape="circle"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onExpand(record, e);
                                }}
                            >
                                {expanded ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        ),
                        expandRowByClick: true,
                        expandedRowRender: (record) => {
                            const assignedTests = userAssignments.filter(
                                (ua) => ua.assignedToUserId === Number(record.id)
                            );

                            if (!assignedTests.length) {
                                return <p style={{ padding: "10px" }}>{t("tests.no_tests_assigment")}</p>;
                            }

                            return (
                                <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
                                    <TabPane tab={t(`tests.myAssignments`)} key={1}>
                                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                            <thead>
                                                <tr>
                                                    <th>{t("add_test.Name")}</th>
                                                    <th>{t("Description")}</th>
                                                    <th>{t("tests.grade")}</th>
                                                    <th>{t("add_test.Level")}</th>
                                                    <th>{t("add_test.NumberAttempts")}</th>
                                                    <th>{t("add_test.NumberQuestions")}</th>
                                                    <th>{`${t("add_test.MaxTime")} (${t("passage_test.minutes")})`}</th>
                                                    <th>{t("tests.AssignedDate")}</th>
                                                    <th>{t("Status")}</th>
                                                    <th>{t("Actions")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assignedTests.map((ua: UserTestAssignment) => (
                                                    <tr key={ua.id}>
                                                        <td>{ua.test?.name}</td>
                                                        <td>{ua.test?.description || t("(no description)")}</td>
                                                        <td>{ua.testAttempts.length > 0 ? `${Math.max(...ua.testAttempts.map((t) => t.grade))}/5` : "–"}</td>
                                                        <td>{ua.test?.level}</td>
                                                        <td>{ua.test?.numberOfAttempts}</td>
                                                        <td>{ua.test?.numberOfQuestions}</td>
                                                        <td>{ua.test?.maxTimeMinutes}</td>
                                                        <td>
                                                            {ua.assignedDate
                                                                ? new Date(ua.assignedDate).toLocaleDateString('uk-UA', {
                                                                    day: 'numeric',
                                                                    month: 'numeric',
                                                                    year: 'numeric'
                                                                }).replace(/\//g, '.')
                                                                : t("No date")}
                                                        </td>
                                                        <td>{t(ua.assignmentStatus?.name ?? "")}</td>
                                                        <td>
                                                            <Button
                                                                type="text"
                                                                shape="circle"
                                                                icon={<EyeOutlined />}
                                                                onClick={() => showAttempts(ua.testAttempts)}
                                                                disabled={ua.testAttempts.length === 0}
                                                                title={t("View Attempts")}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </TabPane>
                                </Tabs>
                            );
                        }
                    }}
                />
            )}

            <Modal
                open={isModalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="close" onClick={handleModalClose}>
                        {t("tests.Close")}
                    </Button>,
                ]}
                width={800}
                style={{ maxWidth: "90%", top: 20 }}
            >
                {selectedAttempts.length > 0 ? (
                    <Table
                        dataSource={selectedAttempts}
                        rowKey="id"
                        pagination={false}
                        columns={[
                            {
                                title: t("tests.attemptNumber"),
                                dataIndex: "numberAttempt",
                                key: "numberAttempt",
                            },
                            {
                                title: t("tests.grade"),
                                dataIndex: "grade",
                                key: "grade",
                                render: (value: number) => `${value}/5`
                            },
                            {
                                title: t("Start Date"),
                                dataIndex: "startDate",
                                key: "startDate",
                                render: (value: string) =>
                                    value
                                        ? new Date(value).toLocaleString('uk-UA', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        }).replace(/,/g, '')
                                        : t("No date"),
                            },
                            {
                                title: t("tests.finishDate"),
                                dataIndex: "finishDate",
                                key: "finishDate",
                                render: (value: string) =>
                                    value
                                        ? new Date(value).toLocaleString('uk-UA', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        }).replace(/,/g, '')
                                        : t("No date"),
                            },
                            {
                                title: t("tests.timeSpent"),
                                dataIndex: "timeSpentMinutes",
                                key: "timeSpentMinutes",
                            },
                        ]}
                    />
                ) : (
                    <p>{t("No attempts available.")}</p>
                )}
            </Modal>
        </Spin>
    )
});