import { Link, useNavigate } from "react-router-dom"
import { useStore } from "../../stores/root-store-context";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../elements/LanguageSwitcher";
import { Button, Popover } from "antd";
import { AddTimeReport } from "../../pages/TimeReports/AddTimeReport";
import { useState } from "react";
import { ClockCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Roles } from "../../stores/EmployeesStore";
import TimerBlock from "../blocks/TimerBlock";
import AddTimerBlock from "../blocks/AddTimerBlock";


const Menu = () => {
    const { applicationStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const [isModalOpenTimer, setIsModalOpenTimer] = useState(false);

    const showModalTimer = () => {
        setIsModalOpenTimer(true);
    };

    const hideModalTimer = () => {
        setIsModalOpenTimer(false);
    };

    return (
        <>
            <ul>
                {!applicationStore.isAuthenticated ?
                    <li>
                        <Link to={"/about"}>{t('Menu.AboutUs')}</Link>
                    </li> : <></>
                }
                {applicationStore.isAuthenticated ?
                    <>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenant}>{t('Menu.MyCompany')}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenant + "/employees"}>{t("Menu.Employees")}</Link>
                        </li>
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenant + "/projects"}>{t("Menu.Projects")}</Link>
                        </li>
                        {/* <li>
                            <Link to={"/" + applicationStore.company?.Tenant + "/tests"}>{t("Menu.Tests")}</Link>
                        </li> */}
                        {/* <li>
                            <Link to={"/" + applicationStore.company?.Tenant + "/events"}>{t("Menu.Events")}</Link>
                        </li> */}
                        <li>
                            <Link to={"/" + applicationStore.company?.Tenant + "/time-reports"}>{t("Menu.Events")}</Link>
                            <Button type="link" shape="circle" onClick={showModal} icon={<ClockCircleOutlined style={{ fontSize: '22px' }} />} style={{ marginLeft: 10 }} />
                            <TimerBlock></TimerBlock>
                        </li>
                        {[Roles.Financier, Roles.Owner].includes(applicationStore.user.roleId) && (
                            <li>
                                <Link to={"/" + applicationStore.company?.Tenant + "/employees/salaries"}>{t("Menu.Salaries")}</Link>
                            </li>
                        )}
                    </> : <></>
                }
                {applicationStore.isAuthenticated ?
                    <li></li> :
                    <>
                        <li>
                            <div >
                                <button className="theme-btn" onClick={() => {
                                    localStorage.removeItem('token');
                                    navigate('/register-company');

                                }}>
                                    <span>
                                        {t("RegisterCompany")}
                                        <i className="fa-solid fa-arrow-right-long" />
                                    </span>
                                </button>
                            </div></li><li>
                            <div >
                                <button className="theme-btn" onClick={() => {
                                    navigate("/login");
                                }}>
                                    <span>
                                        {t("SignIn")}                                      
                                        <i className="fa-solid fa-arrow-right-long" />
                                    </span>
                                </button>
                            </div>
                        </li>
                    </>}
            </ul>
            <AddTimeReport isModalOpen={isModalOpen} hideModal={hideModal} userId={applicationStore.user?.id}
                isChangeUser={applicationStore.user?.roleId === Roles.Owner || applicationStore.user?.roleId === Roles.Manager} refresh={refresh}
                setRefresh={setRefresh}  />
        </>
    )
}
export default Menu;
