import { observer } from "mobx-react-lite";
import { TextSort } from "../../helpers/SorterHelper";
import { DownOutlined, UpOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import { getUserComponent } from "../../helpers/user";
import { useTranslation } from "react-i18next";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Tooltip, Spin, Table, TableProps, Tag, Typography, App, Tabs, DatePicker, TimePicker, Select, Avatar, Row, Col } from "antd";
import { Employee, Skill } from "../../api/EmployeesApiClient";
import { Roles } from "../../stores/EmployeesStore";
import { Test, TestAttempt, UserTestAssignment } from "../../stores/TestsStore";
import { AssignmentStatus } from "../../stores/ApplicationStore";
import TableSuggestTests from "./TableSuggestTests";
import { toJS } from "mobx";
import 'dayjs/locale/uk';
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';

const { TabPane } = Tabs;

export const TestUsersInfo = observer(({ userAssignments, setUserAssignments }: { userAssignments: UserTestAssignment[], setUserAssignments: (userAssignments: UserTestAssignment[]) => void }) => {
    const { t } = useTranslation();
    const { testsStore } = useStore();
    const { applicationStore, employeesStore } = useStore();
    const [expandedSkills, setExpandedSkills] = useState<{ [key: string]: boolean }>({});
    const [filteredUser, setFilteredUser] = useState<Employee[]>([]);
    const [expandedRowKey, setExpandedRowKey] = useState<string[]>([]);
    const [isModalVisibleAttempt, setIsModalVisibleAttempt] = useState(false);
    const [isModalVisibleTests, setIsModalVisibleTests] = useState(false);
    const [allTests, setAllTests] = useState<Test[]>([]);
    const [generationSuggestTests, setGenerationSuggestTests] = useState<Test[]>([]);
    const [selectedTestId, setSelectedTestId] = useState<number | null>(null);
    const [selectedAttempts, setSelectedAttempts] = useState<TestAttempt[]>([]);
    const [selectedUser, setSelectedUser] = useState<Employee | null>(null);
    const { message } = App.useApp();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

    const showAttempts = (attempts: TestAttempt[]) => {
        setSelectedAttempts(attempts);
        setIsModalVisibleAttempt(true);
    };

    const handleModalCloseAttempt = () => {
        setIsModalVisibleAttempt(false);
        setSelectedAttempts([]);
    };

    const showModalTests = (user: Employee) => {
        setIsModalVisibleTests(true);
        setSelectedUser(user);

        const testsForUser = generationNeedTests(user);
        setGenerationSuggestTests(testsForUser);

        const filteredAllTests = allTests.filter(test =>
            !testsForUser.some(suggestedTest => suggestedTest.id == test.id)
        );
        setAllTests(filteredAllTests);
    };

    const handleOkTests = async () => {
        setIsModalVisibleTests(false);
        setSelectedUser(null);

        await handleCreateAssignment();
    };

    const handleCreateAssignment = async () => {
        let finishDateTime = new Date().toISOString();

        if (selectedDate != null && selectedTime != null) {
            const combinedDateTime = selectedDate
                .hour(selectedTime.hour())
                .minute(selectedTime.minute())
                .second(0)
                .millisecond(0);

            finishDateTime = combinedDateTime.toISOString();
        }
        else {
            let createdDateTime = new Date();
            createdDateTime.setDate(createdDateTime.getDate() + 2);
            finishDateTime = createdDateTime.toISOString()
        }

        const addAssignment: UserTestAssignment = {
            id: 0,
            test: null,
            testId: selectedTestId ?? 0,
            finishAssignedDate: finishDateTime,
            assignedDate: new Date().toISOString(),
            assignmentStatusId: 1,
            assignedToUser: null,
            assignmentStatus: null,
            assignedToUserId: Number(selectedUser?.id),
            assignedByUserId: Number(applicationStore.user.id),
            testAttempts: []
        };

        try {
            const result = await testsStore.addAssignment(addAssignment);

            if (result) {
                console.log("result", result);
                message.success(t("Success"));
                const newAssignments = [...userAssignments, result];
                setUserAssignments(newAssignments);
            }
            else {
                message.error(t("Error"));
            }

        } catch (error) {
            console.log(error);
            message.error(t("Error"));
        }
    }

    const handleCancelTests = () => {
        setIsModalVisibleTests(false);
        setSelectedUser(null);
        setSelectedTestId(null);

        const combinedTests = [...testsStore.testsCompany, ...testsStore.testsPublic];
        setAllTests(combinedTests);
    };

    const onExpandHandle = (expanded: boolean, record: Employee) => {
        if (expanded) {
            setExpandedRowKey([record.id]);
        } else {
            setExpandedRowKey([]);
        }
    };

    const generationNeedTests = (user: Employee) => {
        const allTests = [...testsStore.testsCompany, ...testsStore.testsPublic];

        if (user != null) {
            const plainUserSkills = toJS(user.userSkills);

            const filteredTests = allTests.filter(t => t.name != null).filter(test =>
                plainUserSkills.some(skill => skill.skill.skillId == String(test.skillId))
            );

            return filteredTests;
        }

        return [];
    };

    const getCardImage = (skillId: string | null) => {
        const existSkill = applicationStore.skills.find(s => s.skillId == skillId)?.title || t("unknown_skill");

        if (existSkill == "unknown_skill") {
            return "/assets/img/test/Test.jpg";
        }
        else if (existSkill == "C#") {
            return "/assets/img/test/CSharp.jpg";
        }
        else {
            return `/assets/img/test/${existSkill}.jpg`;
        }
    }

    useEffect(() => {
        employeesStore.getUsers().then(res => {
            setFilteredUser(employeesStore.users.filter(u => u.roleId == Roles.User && u.userSkills.length > 0));
        });

        const combinedTests = [...testsStore.testsCompany, ...testsStore.testsPublic];
        setAllTests(combinedTests);
    }, []);

    const handleDeleteAssignment = async (assignment: UserTestAssignment) => {
        if (assignment.assignmentStatusId == AssignmentStatus.Started) {
            message.error(t("tests.You can not delete assignment"));
            return;
        }

        const result = await testsStore.deleteAssignmentById(assignment.id.toString());

        if (result) {
            message.success(t("Success"));

            const newAssignments = userAssignments.filter(ua => ua.id !== assignment.id);

            setUserAssignments(newAssignments);
        } else {
            message.error(t("add_test.DeleteFailed"));
        }
    };

    const columns: TableProps<Employee>['columns'] = [
        {
            title: `${t("user.Name")}`,
            dataIndex: 'photoId',
            key: 'photo',
            render: (data, record) => <div>{getUserComponent(record, applicationStore.company?.Tenant)}</div>,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.firstName)

        },
        Table.EXPAND_COLUMN,
        {
            title: `${t("user.JobTitle")}`,
            dataIndex: 'jobTitleId',
            key: 'jobTitle',
            render: (jobTitleId) => {
                const jobTitle = employeesStore.jobTitles.find(el => el.id === jobTitleId)?.title;
                return <div>{jobTitle || t(" ")}</div>;
            },
            sorter: (a: Employee, b: Employee) => TextSort(a.jobTitleId || "", b.jobTitleId || "")
        },
        {
            title: `${t("Skills")}`,
            dataIndex: 'userSkills',
            key: 'userSkills',
            render: (skills, project) => <Flex wrap style={{ maxWidth: 200 }} gap={10}>
                {(expandedSkills[project.id] ? skills : skills.slice(0, 5)).map((el: any) => (
                    <Tag color={el.isConfirmed ? "green" : "blue"} key={el.skill.title}>{el.skill.title}</Tag>
                ))}
                {skills?.length > 5 && (
                    expandedSkills[project.id] ? (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: false
                        }))} style={{ cursor: 'pointer' }} color="blue">{t("ShowLess")}</Tag>
                    ) : (
                        <Tag onClick={() => setExpandedSkills(prevState => ({
                            ...prevState,
                            [project.id]: true
                        }))} style={{ cursor: 'pointer' }} color="blue">+{skills.length - 5} more</Tag>
                    )
                )}
            </Flex>,
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.firstName),
            filters: applicationStore.skills?.map(skill => { return { text: skill.title, value: skill.skillId } }),
            onFilter: (value, record) => record.userSkills.some(el => el.skill.skillId === value),
        },
        {
            title: `${t("tests.assignedTests")}`,
            render: (record, project) => {
                const assignedTests = userAssignments.filter(
                    (ua) => ua.assignedToUserId === Number(record.id)
                );
                const relevantSkills = assignedTests
                    .map((assignment) => {
                        const skillId = assignment.test?.skillId?.toString() ?? "";
                        let color = "gray";
                        let matchedSkill: Skill | null = null;
                        const existSkill = applicationStore.skills.find(s => s.skillId == skillId) ?? null;

                        if (existSkill != null) {
                            if (assignment.assignmentStatusId == AssignmentStatus.Started) {
                                color = "orange";
                            } else if (assignment.assignmentStatusId == AssignmentStatus.Completed) {
                                // const rating = matchedSkill.confirmedLevel || 0;
                                // color = rating > 4 ? "green" : "red";
                                color = "green";
                            }
                            else if (assignment.assignmentStatusId == AssignmentStatus.Expired) {
                                color = "red";
                            }
                        }

                        if (matchedSkill == null && skillId != null) {
                            const findOtherSkill = applicationStore.skills.find(s => s.skillId == skillId.toString()) ?? null;
                            matchedSkill = toJS(findOtherSkill);
                        }

                        if (!matchedSkill) {
                            return null;
                        }

                        return { ...matchedSkill, color, assignment };
                    })
                    .filter(Boolean);

                return (
                    <Flex wrap style={{ maxWidth: 200 }} gap={10}>
                        {(expandedSkills[project.id] ? relevantSkills : relevantSkills.slice(0, 5)).map((skill: any, index) => (
                            <Tooltip
                                key={`${skill.skillId}-${index}`}
                                title={
                                    <div>
                                        <div><strong>{skill.assignment.test?.name || skill.title}</strong></div>
                                        <div>{t("Level")}: {skill.assignment.test?.level || t("Unknown")}</div>
                                        <div>
                                            {t("tests.selectDeadlineDate")}: {dayjs(skill.assignment.finishAssignedDate)
                                                .locale('uk')
                                                .format('DD.MM.YYYY HH:mm') || t("N/A")}
                                        </div>
                                        <div>{t("Status")}: {t(skill.assignment.assignmentStatus?.name ?? "")}</div>
                                    </div>
                                }
                            >
                                <Tag color={skill.color}>{skill.title}</Tag>
                            </Tooltip>
                        ))}
                        {relevantSkills?.length > 5 && (
                            expandedSkills[project.id] ? (
                                <Tag
                                    onClick={() => setExpandedSkills(prevState => ({
                                        ...prevState,
                                        [project.id]: false
                                    }))}
                                    style={{ cursor: 'pointer' }}
                                    color="blue"
                                >
                                    {t("ShowLess")}
                                </Tag>
                            ) : (
                                <Tag
                                    onClick={() => setExpandedSkills(prevState => ({
                                        ...prevState,
                                        [project.id]: true
                                    }))}
                                    style={{ cursor: 'pointer' }}
                                    color="blue"
                                >
                                    +{relevantSkills.length - 5} {t("more")}
                                </Tag>
                            )
                        )}
                        {relevantSkills?.length === 0 && (
                            <div>{t("tests.no_tests_assigment")}</div>
                        )}
                    </Flex>
                );
            },
            sorter: (a: Employee, b: Employee) => TextSort(a.firstName, b.firstName),
            filters: applicationStore.skills?.map(skill => { return { text: skill.title, value: skill.skillId } }),
            onFilter: (value, record) => record.userSkills.some(el => el.skill.skillId === value),
        },
    ];

    return (
        <Spin spinning={employeesStore.isLoading}>
            {applicationStore.isFreezeModalVisible ? (
                <p style={{ padding: '20px', textAlign: 'center' }}>Access to users is limited until the subscription is renewed.</p>
            ) : (
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredUser.filter(user => user.isActive !== false)}
                    expandable={{
                        expandedRowKeys: expandedRowKey,
                        onExpand: onExpandHandle,
                        expandIcon: ({ expanded, onExpand, record }: any) => (
                            <Button
                                shape="circle"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onExpand(record, e);
                                }}
                            >
                                {expanded ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        ),
                        expandRowByClick: true,
                        expandedRowRender: (record) => {
                            return (
                                <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
                                    <TabPane tab={t(`tests.assignments`)} key={1}>
                                        <div>
                                            <Button
                                                type="primary"
                                                style={{ position: "absolute", top: -60, right: 0 }}
                                                onClick={() => showModalTests(record)}
                                            >
                                                {t('tests.assign')}
                                            </Button>

                                            <div>
                                                {userAssignments.filter((ua) => ua.assignedToUserId === Number(record.id)).length > 0 ? (
                                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>{t("add_test.Name")}</th>
                                                                <th>{t("Description")}</th>
                                                                <th>{t("tests.grade")}</th>
                                                                <th>{t("add_test.Level")}</th>
                                                                <th>{t("add_test.NumberAttempts")}</th>
                                                                <th>{t("add_test.NumberQuestions")}</th>
                                                                <th>{`${t("add_test.MaxTime")} (${t("passage_test.minutes")})`}</th>
                                                                <th>{t("tests.AssignedDate")}</th>
                                                                <th>{t("tests.selectDeadlineDate")}</th>
                                                                <th>{t("Status")}</th>
                                                                <th>{t("Actions")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {userAssignments.filter((ua) => ua.assignedToUserId === Number(record.id)).length && (
                                                                userAssignments.filter((ua) => ua.assignedToUserId === Number(record.id)).map((ua: UserTestAssignment) => (
                                                                    <tr key={ua.id}>
                                                                        <td>{ua.test?.name}</td>
                                                                        <td>{ua.test?.description || t("(no description)")}</td>
                                                                        <td>{ua.testAttempts.length > 0 ? `${Math.max(...ua.testAttempts.map((t) => t.percentage))}/100%` : "–"}</td>
                                                                        <td>{ua.test?.level}</td>
                                                                        <td>{ua.test?.numberOfAttempts}</td>
                                                                        <td>{ua.test?.numberOfQuestions}</td>
                                                                        <td>{ua.test?.maxTimeMinutes}</td>
                                                                        <td>
                                                                            {ua.assignedDate
                                                                                ? new Date(ua.assignedDate).toLocaleDateString('uk-UA', {
                                                                                    day: 'numeric',
                                                                                    month: 'numeric',
                                                                                    year: 'numeric'
                                                                                }).replace(/\//g, '.')
                                                                                : t("No date")}
                                                                        </td>
                                                                        <td>
                                                                            {ua.finishAssignedDate
                                                                                ? dayjs(ua.finishAssignedDate)
                                                                                    .locale('uk')
                                                                                    .format('DD.MM.YYYY HH:mm')
                                                                                : t("No date")}
                                                                        </td>
                                                                        <td>{t(ua.assignmentStatus?.name ?? "")}</td>
                                                                        <td>
                                                                            <Button
                                                                                type="text"
                                                                                shape="circle"
                                                                                icon={<EyeOutlined />}
                                                                                onClick={() => showAttempts(ua.testAttempts)}
                                                                                disabled={ua.testAttempts.length === 0}
                                                                                title={t("View Attempts")}
                                                                            />
                                                                            <Popconfirm
                                                                                title={t("tests.Delete the assignment?")}
                                                                                okText={t("Yes")}
                                                                                cancelText={t("No")}
                                                                                onConfirm={async () => await handleDeleteAssignment(ua)}
                                                                            >
                                                                                <Button
                                                                                    danger
                                                                                    type="text"
                                                                                    shape="circle"
                                                                                    icon={<DeleteOutlined />}
                                                                                />
                                                                            </Popconfirm>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>{t("tests.no_tests_assigment")}</p>
                                                )}
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            );
                        }
                    }}
                />
            )}

            <Modal
                open={isModalVisibleTests}
                onOk={handleOkTests}
                onCancel={handleCancelTests}
                width={600}
            >
                <div>
                    <h3 style={{ margin: 0 }}>
                        {selectedUser?.firstName} {selectedUser?.lastName}
                    </h3>
                    <p style={{ margin: "5px 0", color: "#555" }}>{selectedUser?.email}</p>
                </div>

                {employeesStore.jobTitles.find(el => el.id === selectedUser?.jobTitleId)?.title && (
                    <div style={{ marginBottom: "20px" }}>
                        <p style={{ margin: 0, color: "#777" }}>
                            {employeesStore.jobTitles.find(el => el.id === selectedUser?.jobTitleId)?.title || t("tests.NotSpecified")}
                        </p>
                    </div>
                )}

                {selectedUser?.userSkills && (
                    <div style={{ marginBottom: "20px" }}>
                        <Flex wrap style={{ gap: "10px", maxWidth: "100%" }}>
                            {(expandedSkills[selectedUser.id ?? '']
                                ? selectedUser.userSkills
                                : selectedUser.userSkills.slice(0, 5)
                            ).map(skill => (
                                <Tag color="blue" key={skill.skill.skillId}>
                                    {skill.skill.title}
                                </Tag>
                            ))}
                            {selectedUser.userSkills.length > 5 && (
                                expandedSkills[selectedUser.id ?? ''] ? (
                                    <Tag
                                        onClick={() =>
                                            setExpandedSkills(prevState => ({
                                                ...prevState,
                                                [selectedUser.id ?? '']: false,
                                            }))
                                        }
                                        style={{ cursor: 'pointer' }}
                                        color="blue"
                                    >
                                        Скрыть
                                    </Tag>
                                ) : (
                                    <Tag
                                        onClick={() =>
                                            setExpandedSkills(prevState => ({
                                                ...prevState,
                                                [selectedUser.id ?? '']: true,
                                            }))
                                        }
                                        style={{ cursor: 'pointer' }}
                                        color="blue"
                                    >
                                        +{selectedUser.userSkills.length - 5} ещё
                                    </Tag>
                                )
                            )}
                        </Flex>
                    </div>
                )}

                <div style={{ marginBottom: "20px" }}>
                    <p style={{ fontWeight: "bold" }}>{t("tests.selectDeadlineDate")}</p>
                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <DatePicker
                            style={{ flex: 1 }}
                            required
                            onChange={(value) => setSelectedDate(value)}
                            disabledDate={(current) => current && current.isBefore(dayjs(), 'day')}

                        />
                        <TimePicker
                            style={{ flex: 1 }}
                            format="HH:mm"
                            required
                            onChange={(value) => setSelectedTime(value)}
                        />
                    </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <p style={{ fontWeight: "bold" }}>{t(`tests.SelectTest`)}:</p>
                    <Select
                        placeholder={t(`tests.SelectTest`)}
                        style={{ width: "100%" }}
                        value={selectedTestId}
                        onChange={(value: number) => {
                            setSelectedTestId(value);
                        }}
                        optionLabelProp="label"
                    >
                        <Select.OptGroup label={t(`tests.SuggestedTests`)}>
                            {generationSuggestTests.map((test) => (
                                <Select.Option key={test.id} value={test.id} label={test.name}>
                                    <Row align="middle">
                                        <Col>
                                            <Avatar
                                                style={{ backgroundColor: '#87d068' }}
                                                src={getCardImage(test.skillId?.toString() ?? "")}
                                                alt="test-image"
                                                size="large"
                                            />
                                        </Col>
                                        <Col style={{ marginLeft: "10px" }}>
                                            <strong>{test.name}</strong>
                                            <div style={{ color: "#777" }}>{test.level}</div>
                                        </Col>
                                    </Row>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>

                        <Select.OptGroup label={t(`tests.AllTests`)}>
                            {allTests.map((test) => (
                                <Select.Option key={test.id} value={test.id} label={test.name}>
                                    <Row align="middle">
                                        <Col>
                                            <Avatar
                                                src={getCardImage(test.skillId?.toString() ?? "")}
                                                alt="test-image"
                                                size="large"
                                            />
                                        </Col>
                                        <Col style={{ marginLeft: "10px" }}>
                                            <strong>{test.name}</strong>
                                            <div style={{ color: "#777" }}>{test.level}</div>
                                        </Col>
                                    </Row>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>
                </div>
            </Modal>

            <Modal
                open={isModalVisibleAttempt}
                onCancel={handleModalCloseAttempt}
                footer={[
                    <Button key="close" onClick={handleModalCloseAttempt}>
                        {t("tests.Close")}
                    </Button>,
                ]}
                width={800}
                style={{ maxWidth: "90%", top: 20 }}
            >
                {selectedAttempts.length > 0 ? (
                    <Table
                        dataSource={selectedAttempts}
                        rowKey="id"
                        pagination={false}
                        columns={[
                            {
                                title: t("tests.attemptNumber"),
                                dataIndex: "numberAttempt",
                                key: "numberAttempt",
                            },
                            {
                                title: t("tests.grade"),
                                dataIndex: "percentage",
                                key: "percentage",
                                render: (value: number) => `${value}/100%`
                            },
                            {
                                title: t("Start Date"),
                                dataIndex: "startDate",
                                key: "startDate",
                                render: (value: string) =>
                                    value
                                        ? new Date(value).toLocaleString('uk-UA', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        }).replace(/,/g, '')
                                        : t("No date"),
                            },
                            {
                                title: t("tests.finishDate"),
                                dataIndex: "finishDate",
                                key: "finishDate",
                                render: (value: string) =>
                                    value
                                        ? new Date(value).toLocaleString('uk-UA', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        }).replace(/,/g, '')
                                        : t("No date"),
                            },
                            {
                                title: t("tests.timeSpent"),
                                dataIndex: "timeSpentMinutes",
                                key: "timeSpentMinutes",
                            },
                            {
                                title: t("Actions"),
                                key: "actions",
                                render: (_, record) => (
                                    <a
                                        onClick={() => navigate(`/${applicationStore.company.Tenant}/tests/result/${record.userTestAssignmentId}/${record.id}`)}
                                    >
                                        <EyeOutlined />
                                    </a>
                                ),
                            },
                        ]}
                    />
                ) : (
                    <p>{t("No attempts available.")}</p>
                )}
            </Modal>
        </Spin>
    )
});