import { observer } from "mobx-react-lite";
import {
  AutoComplete,
  Button,
  DatePicker,
  Flex,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Tag,
  Upload,
  UploadFile,
  message,
  Divider,
  Checkbox,
} from "antd";
import Layout from "../../components/layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { Employee, Skill } from "../../api/EmployeesApiClient";
import { useEffect, useRef, useState } from "react";
import { UploadChangeParam } from "antd/es/upload";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import { useStore } from "../../stores/root-store-context";
import TextArea from "antd/es/input/TextArea";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  BookOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";
import { Option } from "antd/es/mentions";
import { SkillLevelName, SkillLevelNameColor } from "../../helpers/skills";
import dayjs from "dayjs";
import InfoBlock from "../../components/blocks/InfoBlock";
import EducationBlock from "../../components/blocks/EducationBlock";
import { AddEducation } from "./AddEducation";
import i18n from "../../i18n";
import { AddJobTitle } from "./AddJobTitle";
import ExperienceBlock from "../../components/blocks/ExperienceBlock";
import { AddExperience } from "./AddExperience";
import InputMask from 'react-input-mask';

export interface Country {
  code: string;
  name: string;
}
export interface City {
  name: string;
}
export interface University {
  name: string;
}
// export interface Skill {
//   skillId?: number;
//   title?: string;
//   experience?: number;
//   level?: number;
// }
export interface Education {
  City: string;
  Country: string;
  Profession: string;
  Degree: string;
  StartDate: string;
  EndDate: string;
  University: string;
}

const EditEmployee = observer(() => {
  const { applicationStore, employeesStore, educationStore } = useStore();
  const [messageApi] = message.useMessage();
  const { t } = useTranslation();
  const uploadRef = useRef<HTMLInputElement>(null);
  const [autoCompleteSkillValue, setAutoCompleteSkillValue] = useState<string>('');
  const { id } = useParams();
  const [form] = Form.useForm();
  const [skillForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalJobTitle, setIsModalJobTitle] = useState(false);
  const [isModalExperience, setIsModalExperience] = useState(false);
  const [educationList, setEducationList] = useState<Education[]>([]);
  const [experienceList, setExperienceList] = useState<any>([]);
  const [selectedSkill, setSelectedSkill] = useState<Skill>();
  const [searchText, setSearchText] = useState<string>("");
  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const [filteredSkills, setFlteredSkills] = useState<any[]>([]);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const showModalJobTitle = () => {
    setIsModalJobTitle(true);
  };

  const hideModalJobTitle = () => {
    setIsModalJobTitle(false);
  };
  const showModalExperience = () => {
    setIsModalExperience(true);
  };

  const hideModalExperience = () => {
    setIsModalExperience(false);
  };
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  function updatePage(){
    if (id) {
      employeesStore.getUserById(id).then((result) => {
        form.setFieldsValue(result);
        fetchExperience();
        if (result?.photoId) {
          setFileList([
            {
              uid: "-1",
              name: `${result?.photoId}`,
              status: "done",
              url: `https://res.cloudinary.com/dnl3x07wo/image/upload/${result?.photoId}`,
            },
          ]);
        }
      });
    } else {
      navigate("/404");
    }
  }
  useEffect(() => {
    updatePage()
  }, [id]);

  function ParseToJson(str: string): any[] {
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  }
  useEffect(() => {
    if (employeesStore.user?.education) {
      const data = ParseToJson(employeesStore.user?.education);
      const educationArray = Array.isArray(data) ? data : [data];
      setEducationList(educationArray);
    }
  }, [employeesStore.user?.education])

  const fetchEducation = async () => {
    if (id) {
      await employeesStore.getUserById(id);
    }
    if (employeesStore.user?.education) { 
      const data = ParseToJson(employeesStore.user?.education);
      const educationArray = Array.isArray(data) ? data : [];
      setEducationList(educationArray);
    }
  }

  const fetchExperience = async () => {
    const data = ParseToJson(employeesStore.user?.experience);
    const experienceArray = Array.isArray(data) ? data : [];
    setExperienceList(experienceArray);
  }

  useEffect(() => {
    if (employeesStore.user) {
      fetchExperience();
      fetchEducation();
    }
  }, [])

  const handleSkillChange = async (skillId: string, title: string) => {
    setSelectedSkill({
      skillId: skillId,
      title: title,
      level: undefined,
      experience: undefined,
    });
  };


  const removeEducation = async (educationToRemove: Education) => {
    var result = await employeesStore.ApiStore.CommonApiClient.removeEducation(
      educationToRemove.Country,
      educationToRemove.City,
      educationToRemove.University,
      educationToRemove.Profession,
      educationToRemove.Degree,
      educationToRemove.StartDate,
      educationToRemove.EndDate
    );
    if (result) {
      messageApi.success(t("Success"));
      fetchEducation();
    } else {
      messageApi.error(t("SomethingWentWrong"));
    }
  };

  const removeExperience = async (experienceToRemove: any) => {
    var result = await employeesStore.removeExperience(
      experienceToRemove.Company,
      experienceToRemove.Position,
      experienceToRemove.Description,
      experienceToRemove.StartDate,
      experienceToRemove.EndDate
    );
    console.log(result);
    if (result) {
      message.success(t("Success"));
      fetchExperience();
    }
    else {
      message.error(t("SomethingWentWrong"));
    }
  };

  const addSkill = async () => {
    messageApi.open({
      type: "loading",
      content: t("Saving"),
      key: "saving",
    });
    if (
      selectedSkill?.skillId === undefined ||
      selectedSkill?.title === undefined ||
      selectedSkill?.experience === undefined ||
      selectedSkill?.level === undefined
    ) {
      messageApi.destroy("saving");
      messageApi.error("Saving failed! Fill all necessary fields");
      return;
    }
    const result =
      await employeesStore.ApiStore.CommonApiClient.AssignSkillToUser(
        selectedSkill?.skillId,
        parseInt(id as string) as number,
        selectedSkill?.level as number,
        selectedSkill?.experience as number
      );
    messageApi.destroy("saving");
    if (result) {
      message.success(t("SaveSuccess"));
      const userSkill = {
        skill: {
          skillId: String(selectedSkill?.skillId) as string,
          title: selectedSkill?.title as string,
        },
        skillLevel: String(selectedSkill?.level) as string,
        experienceYears: String(selectedSkill?.experience) as string,
        confirmationDate: "",
      };
      employeesStore.setUser({
        ...employeesStore.user,
        userSkills: [...employeesStore.user?.userSkills, userSkill],
      });
      setAutoCompleteSkillValue('');
      setSearchText('');
    } else message.error("Saving failed");
    skillForm.resetFields();
    setSelectedSkill(undefined);
  };

  useEffect(() => {
    console.log("useEffect");
    console.log(JSON.parse(JSON.stringify(employeesStore.user?.userSkills)));
    const newSkill = applicationStore.skills
      ?.filter(
        (el) =>
          el.title &&
          el.title.toLowerCase().includes(searchText.toLowerCase()) &&
          !employeesStore.user.userSkills?.some(
            (skill) => skill.skill.skillId == el.skillId
          )
      )
      .map((el) => ({
        value: el.title,
        label: el.title,
        skillId: el.skillId,
      }));
    console.log(newSkill);

    setFlteredSkills(newSkill)
  }, [employeesStore.user.userSkills.length, searchText, applicationStore.skills, employeesStore.user.userSkills]);

  async function updateSkills() {
    const updatedSkills = await employeesStore.ApiStore.CommonApiClient.getSkills();
    applicationStore.skills = updatedSkills;
  }

  const removeSkill = async (skillId: string) => {
    message.open({
      type: "loading",
      content: t("Removing"),
      key: "remove",
    });
    const result =
      await employeesStore.ApiStore.CommonApiClient.RemoveSkillFromUser(
        parseInt(skillId),
        parseInt(id as string) as number
      );
    message.destroy("remove");
    if (result) {
      message.success(t("RemoveSuccess"));
      employeesStore.setUser({
        ...employeesStore.user,
        userSkills: employeesStore.user?.userSkills?.filter(
          (skill) => skill.skill.skillId !== skillId
        ),
      });
    } else message.error("Removing failed");
  };

  const fileChange = (info: UploadChangeParam) => {
    let fileList = [...info.fileList];

    // Limit to one file
    fileList = fileList.slice(-1);

    // Only show the last file
    setFileList(fileList);

    if (info.file.status === "done") {
      message.success(`${info.file.name} ${t("UploadSuccess")}`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} ${t("UploadFailed")}`);
    }
  };

  const onFinish: FormProps<Employee>["onFinish"] = async (
    newUser: Employee
  ) => {
    newUser.id = employeesStore.user.id;
    message.open({
      type: "loading",
      content: t("Saving"),
      key: "saving",
    });
    if (fileList.length > 0 && fileList[0].uid !== "-1") {
      const photo = await employeesStore.uploadPhoto(fileList[0], employeesStore.user.id);
      newUser.photoId = photo.publicId;
    }
    console.log(employeesStore.user.id);

    employeesStore
      .editUser(newUser)
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        message.destroy("saving");
        message.success(t("SaveSuccess"));
      });
  };


  return (
    <Layout headerStyle={1} footerStyle={2}>
      <div>
        <section className="contact-section fix section-padding">
          <div className="container" style={{ maxWidth: 800 }}>
            <Button onClick={() => {
              navigate(-1)
            }}>{t("Back")}</Button>
            <Spin spinning={employeesStore.isLoading}>

              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item<Employee>
                  style={{ width: "100%" }}
                  rules={[{ required: true }]}
                >
                  <Flex justify="center" vertical align="center">
                    <ImgCrop rotationSlider>
                      <Upload
                        className="avatar-uploader"
                        ref={uploadRef}
                        style={{ alignSelf: "center" }}
                        accept="image/*"
                        maxCount={1}
                        listType="picture-circle"
                        fileList={fileList}
                        onChange={fileChange}
                        beforeUpload={() => false}
                      >
                        {fileList.length === 0 && (
                          <Button icon={<UploadOutlined />}></Button>
                        )}
                      </Upload>
                    </ImgCrop>
                    {fileList.length > 0 && (
                      <Button style={{ marginTop: 10 }} icon={<UploadOutlined />} onClick={() => {
                        if (uploadRef.current) {
                          const input = document.querySelector('.avatar-uploader input[type="file"]') as HTMLElement;
                          if (input) {
                            input.click();
                          }
                        }
                      }}>{t('Upload')}</Button>
                    )}
                  </Flex>
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.Name")}`}
                  name="firstName"
                  style={{ width: "100%" }}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={`${t("user.Name")}`} />
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.Surname")}`}
                  name="lastName"
                  style={{ width: "100%" }}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={`${t("user.Surname")}`} />
                </Form.Item>
                <Form.Item<Employee>
                  label={t("PhoneNumber")}
                  name="phoneNumber"
                  style={{ width: "100%" }}
                  rules={[
                    { required: true, message: t("PleaseEnterPhoneNumber") },
                    {
                      pattern: /^(?:\+380\d{9}|0\d{9})$/,
                      message: t("PhoneNumberFormat"),
                    },
                  ]}
                >
                  {/* <Input placeholder={t("PhoneNumber")} /> */}
                  <InputMask
                    mask="+380999999999"
                    placeholder={t("PhoneNumber")}
                    maskChar=""
                    alwaysShowMask={false}
                  >
                    {(inputProps: any) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
                <Form.Item<Employee>
                  label={t("HidePhoneNumber")}
                  name="isPhoneNumberPrivate"
                  valuePropName="checked"
                  style={{ width: "100%" }}
                >
                  <Checkbox>{t("HidePhoneNumberFromOthers")}</Checkbox>
                </Form.Item>

                <Form.Item<Employee>
                  label="Skype"
                  name="skype"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <Input placeholder="Skype" />
                </Form.Item>

                <Form.Item<Employee>
                  label={`${t("City")}`}
                  name="city"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <Input placeholder={`${t("City")}`} />
                </Form.Item>
                <Form.Item<Employee> name="jobTitleId" hidden>
                  <Input />
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.JobTitle")}`}
                  name="jobTitle"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <AutoComplete
                    placeholder={`${t("user.JobTitle")}`}
                    options={[
                      ...(employeesStore.jobTitles
                        ? employeesStore.jobTitles
                            .slice() 
                            .sort((a, b) => a.title.localeCompare(b.title, 'uk'))
                            .map((el, index) => ({
                              key: `${el.id}-${index}`,
                              value: el.title,
                              label: el.title,
                              data: el,
                            }))
                        : []),
                      {
                        key: "create-job-title",
                        value: "create-job-title",
                        label: <Button onClick={showModalJobTitle}>{t("CreateJobTitle")}</Button>,
                      },
                    ]}

                    onChange={(value, option: any) => {
                      if (option?.value && option?.value !== "create-job-title") {
                        console.log("option");
                        console.log(option);
                        console.log(value);
                        form.setFieldValue("jobTitleId", option.data.id)
                        form.setFieldValue("jobTitle", option.value)
                        console.log(option);
                      } else {
                        console.log(value);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.BirthDate")}`}
                  labelCol={{ span: 12 }}
                  name="birthDay"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                  getValueFromEvent={(date) => date ? dayjs(date).format('YYYY-MM-DD') : undefined}
                  getValueProps={(value) => ({ value: value ? dayjs(value, 'YYYY-MM-DD') : null })}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.AboutMe")}`}
                  name="aboutMe"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <TextArea placeholder={t("user.AboutMe")} rows={4} autoSize />
                </Form.Item>
                <Form.Item<Employee>
                  label={`${t("user.MyHobby")}`}
                  name="hobby"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <TextArea placeholder={t("user.MyHobby")} rows={4} autoSize />
                </Form.Item>
                <Form.Item<Employee>
                  name="education"
                  style={{ width: "100%" }}
                  rules={[{ required: false }]}
                >
                  <EducationBlock
                    educationList={educationList}
                    removeEducation={removeEducation}
                    canRemove={true}
                  ></EducationBlock>
                </Form.Item>
                <Button style={{ marginBottom: 20 }} onClick={showModal}>{`${t("AddEducation")}`}</Button>

                <Form.Item<Employee>
                  // label={`${t("user.MySkills")}`}
                  style={{
                    width: "100%", border: "1px solid #cecece", borderRadius: 5, padding: 5,
                    display: "flex", flexDirection: "column",
                    backgroundColor: "#fafafa"
                  }}
                  rules={[{ required: false }]}
                >
                  <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                    <div style={{ marginBottom: "15px", flex: "1 1 200px" }}>
                      <label style={{ marginBottom: 8 }}>{t("user.MySkills")}</label>
                      <AutoComplete
                        value={autoCompleteSkillValue}
                        showSearch={true}
                        placeholder={`${t("user.MySkills")}`}
                        options={[
                          ...filteredSkills.slice() 
                          .sort((a, b) => a.label.localeCompare(b.label, 'uk')),
                          ...(searchText && !filteredSkills?.find((el) => el.value === searchText)
                            ? [{ label: `${t("Add")} "${searchText}"`, value: searchText }]
                            : []),
                        ].filter((el) => employeesStore.user?.userSkills.some(userSkill => userSkill.skill.skillId === el.skillId) === false)}
                        onSearch={handleSearch}
                        onSelect={(value: string, option: any) => {
                          if (option?.label?.includes("Добавить")) {
                            if (value) {

                              const skillExists = applicationStore.skills.some(
                                (skill) => skill.title.toLowerCase() === value.toLowerCase()
                              );

                              if (skillExists) {
                                message.error("Навык с таким названием уже существует.");
                                return;
                              }

                              employeesStore.ApiStore.CommonApiClient.addSkills(value).then((newSkill) => {
                                updateSkills();
                                setSearchText("");
                                message.success(`Навык "${newSkill.data.title}" успешно добавлен.`);

                                applicationStore.skills.push({
                                  skillId: newSkill.data.skillId,
                                  title: newSkill.data.title,
                                });

                                setSelectedSkill({
                                  skillId: newSkill.data.skillId,
                                  title: newSkill.data.title,
                                  level: undefined,
                                  experience: undefined,
                                });
                              }).catch((error) => {
                                message.error(`Ошибка при добавлении навыка: ${error.message}`);
                              });
                            }
                          } else {
                            const selected = applicationStore.skills.find((el) => el.title === value);
                            if (selected?.skillId) {
                              handleSkillChange(selected.skillId, value);
                            }
                          }
                        }}
                        onChange={(value) => {
                          setSearchText(value || "")
                          setAutoCompleteSkillValue(value || "");
                        }}
                      />
                    </div>


                    {selectedSkill?.skillId !== undefined && (
                      <Form key="skillForm" style={{ flex: "4 1 200px", display: "flex", gap: 10 }} form={skillForm} onFinish={addSkill}>
                        <Form.Item<any>
                          label={t("Level")}
                          name={t("Level")}
                          style={{ width: "100%", marginBottom: "46px" }}
                          rules={[{ required: false }]}
                        >
                          <Select
                            value={selectedSkill?.level}
                            onChange={(e) =>
                              setSelectedSkill({ ...selectedSkill, level: e })
                            }
                            options={[
                              { value: '1', label: t("Elementary"), key: '1' },
                              { value: '2', label: t("Intermediate"), key: '2' },
                              { value: '3', label: t("Advanced"), key: '3' },
                              { value: '4', label: t("Professional"), key: '4' },
                              { value: '5', label: t("Expert"), key: '5' },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item<any>
                          label={t("SkillExperience")}
                          name="Skill experience"
                          style={{ width: "100%" }}
                          rules={[{ required: false }]}
                        >
                          <InputNumber
                            max={20}
                            min={0}
                            value={selectedSkill?.experience}
                            onChange={(e) =>
                              setSelectedSkill({
                                ...selectedSkill,
                                experience: e?.valueOf(),
                              })
                            }
                            placeholder={t("years")}
                          />
                        </Form.Item>
                        <div
                          key={"submitExpirence"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            disabled={selectedSkill?.level === undefined || selectedSkill?.experience === undefined}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={addSkill}
                            className="theme-btn"
                          >
                            {`${t("Add")}`}
                          </Button>
                        </div>
                      </Form>
                    )}

                    <Flex gap={10} wrap style={{ width: "100%" }}>
                      {employeesStore.user?.userSkills?.map((item, index) => {
                        return (
                          <Tag
                            key={index}
                            color="blue"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                SkillLevelNameColor[parseInt(item.skillLevel)],
                            }}
                            onClick={() => {
                              removeSkill(item.skill.skillId);
                            }}
                          >
                            <div>
                              {item.skill.title} <CloseOutlined />
                            </div>
                            <div>{SkillLevelName[parseInt(item.skillLevel)]}</div>
                            <div>{[parseInt(item.experienceYears) || 0]} {t('years')}</div>
                          </Tag>
                        );
                      })}
                    </Flex>
                  </div>
                </Form.Item>



                <Flex style={{ display: "block" }}>
                  <ExperienceBlock experienceList={experienceList} canRemove={true} removeExperience={removeExperience}></ExperienceBlock>
                  <Button onClick={showModalExperience}>{t('AddExperience')}</Button>
                </Flex>
                <Form.Item>
                  <Button
                    type="primary"
                    className="theme-btn"
                    htmlType="submit"
                    style={{ height: 60, display: "flex", float: "right" }}
                  >
                    {`${t("Save")}`}{" "}
                    <SaveOutlined style={{ fontSize: 20, margin: 5 }} />
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </section>
      </div>
      <AddEducation isModalOpen={isModalOpen} hideModal={hideModal} userId={id} fetchEducation={fetchEducation} />
      <AddJobTitle isModalOpen={isModalJobTitle} hideModal={hideModalJobTitle} userId={id} />
      <AddExperience isModalOpen={isModalExperience} hideModal={hideModalExperience} fetchExperience={()=>{
        updatePage();
      }} />

    </Layout>
  );
});
export default EditEmployee;
