import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  if (!i18n.isInitialized) {
    return null;
  }

  const changeLanguage = (e: { key: any; }) => {
    console.log('Selected language:', e.key);
    i18n.changeLanguage(e.key);
  };

  const items = [
    { key: 'en', label: 'English' },
    { key: 'uk', label: 'Українська' },
    { key: 'ru', label: 'Русский' },
  ];

  return (
    <Dropdown
      menu={{ items, onClick: changeLanguage }}
      trigger={['click']}
    >
      <Button>
        {i18n.language.toUpperCase()} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguageSwitcher;
