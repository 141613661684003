import { UploadFile } from "antd";
import { Roles } from "../stores/EmployeesStore";
import { AssignUserProject, NewProject, Project } from "../stores/ProjectsStore";
import SkTechApiClient from "./base/SkTechApiClient";
import dayjs, { Dayjs } from 'dayjs';
import { RcFile } from "antd/es/upload";
import { AxiosError, AxiosResponse } from "axios";
import { Test, TestAttempt, TestLevelSetting, UserTestAssignment } from "../stores/TestsStore";


export default class TestsApiClient extends SkTechApiClient {

  public addTestAttempt = async (addTestAttempt: TestAttempt): Promise<TestAttempt> => {
    return await this.Post(`/api/TestAttempt`, addTestAttempt)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error.response.data;
      });
  }

  public deleteAssignmentById = async (assignmentId: string): Promise<any> => {
    return await this.Delete(`/api/UserTestAssignment/${assignmentId}`)
      .then(function (response) {
        if (response.status === 200) {
          return true;
        }
        return false;
      });
  }

  public addUserTestAssignment = async (addTest: UserTestAssignment): Promise<UserTestAssignment> => {
    return await this.Post(`/api/UserTestAssignment`, addTest)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        return error.response.data;
      });
  }

  public getAllAssignmentByCompanyId = async (companyId: string): Promise<UserTestAssignment[]> => {
    return await this.Get(`/api/UserTestAssignment/company/${companyId}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      });
  }

  public getAssignmentById = async (id: string): Promise<UserTestAssignment> => {
    return await this.Get(`/api/UserTestAssignment/${id}`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
  }

  public getAllAssignmentByUserId = async (userId: string): Promise<UserTestAssignment[]> => {
    return await this.Get(`/api/UserTestAssignment/user/${userId}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      });
  }

  public addTest = async (addTest: Test): Promise<Test> => {
    return await this.Post(`/api/Test`, addTest)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        return error.response.data;
      });
  }

  public getAllPublic = async (): Promise<Test[]> => {
    return await this.Get(`/api/Test`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      });
  }

  public getAllByCompanyId = async (companyId: string): Promise<Test[]> => {
    return await this.Get(`/api/Test/company/${companyId}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      });
  }

  public getAllTestLevelSettings = async (): Promise<TestLevelSetting[]> => {
    return await this.Get(`/api/Test/setting/level`)
      .then(function (response) {
        if (response.status === 200) {
          return response.data;
        }
        return [];
      });
  }

  public deleteById = async (id: string): Promise<any> => {
    return await this.Delete(`/api/Test/${id}`)
      .then(function (response) {
        if (response.status === 200) {
          return true;
        }
        return false;
      });
  }

  public getProjects = async (): Promise<Project[]> => {

    return await this.Get(`/api/Tests`)
      .then(function (response) {
        if (response?.status === 200) {
          return response.data.map((el: {
            startDate: string;
          }) => {
            return {
              ...el,
              startDate: dayjs(el.startDate),
            }
          });
        }
        return false;
      });
  }

}