import { RouteObject, useNavigate, useRoutes } from "react-router-dom";
import { App } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/root-store-context";
import { useEffect, useState } from "react";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Account from "../pages/Users/UserPage";
import RequireAuth from "../providers/RequireAuth";
import CompanyHome from "../pages/CompanyHome";
import Users from "../pages/Users/UsersList";
import Error404 from "../pages/Error404";
import Projects from "../pages/Projects/ProjectsList";
import Tests from "../pages/Tests/Tests";
import Register from "../pages/Register";
import About1 from "../components/sections/About1";
import About from "../pages/About/About";
import Preloader from "../components/elements/Preloader";
import EditEmployee from "../pages/Users/EditEmployee";
import ProjectsList from "../pages/Projects/ProjectsList";
import ProjectPage from "../pages/Projects/ProjectPage";
import TaskPage from "../pages/ProjectTasks/ProjectTaskPage";
import ProjectSettings from "../pages/Projects/ProjectSettings";
import TimeReports from "../pages/TimeReports/TimeReports";
import EventPage from "../pages/Events/EventPage";
import PaymentAndTariffs from "../pages/Payment/PaymentAndTariffs"
import dayjs from "dayjs";
import "dayjs/locale/uk";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import i18n from "../i18n";
import SalariesPage from "../pages/Salaries/SalariesPage";
import OrganogramPage from "../pages/Users/OrganogramPage";
import { Roles } from "../stores/EmployeesStore";
import CompanySettings from "../pages/CompanySettings";
import CheckCompanyStatus from "./CheckCompanyStatus";
import PublicOffer from "../pages/PublicOffer";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RegisterByInvitation from "../pages/RegisterByInvitation";
import SupportPage from "../pages/Support/SupportPage";
import AddTest from "../pages/Tests/AddTest";
import PassageTest from "../pages/Tests/PassageTest";
import { UserAnswersTest } from "../pages/Tests/UserAnswersTest";

const Router: React.FC = observer(() => {
  const rootStore = useStore();
  const { apiStore, applicationStore, employeesStore, projectsStore } = rootStore;
  const [routings, setRoutings] = useState<RouteObject[]>([]);
  const navigate = useNavigate();

  dayjs.locale(i18n.language);

  const { notification } = App.useApp();

  useEffect(() => {
    let token = localStorage.getItem("token");
    apiStore.setConfigApi({
      token: localStorage.getItem("token") as string,
      companyId: "0",
      userId: 0,
    });
    if (token) {
      applicationStore.setLoading(true);
      applicationStore.autorize();
    } else {
      applicationStore.setIsAuthenticated(false);
      applicationStore.setLoading(false);
    }
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token && applicationStore.isAuthenticated) {
      applicationStore.setIsAuthenticated(false);
      console.log("navigate(/login);");
      navigate("/login");
    }
    else {
      apiStore.setConfigApi({
        token: localStorage.getItem("token") as string,
        companyId: "0",
        userId: 0
      });
      employeesStore.getUsers();
      projectsStore.getAll();
     
    }
    
  }, [applicationStore.isAuthenticated]);

  const routes = [
    {
      path: "/",
      element: (
        <CheckCompanyStatus>
          {applicationStore.isAuthenticated ? <CompanyHome /> : <Home />}
        </CheckCompanyStatus>
      ),
    },
    { path: "/:companyName/RegisterByInvitation", element: <RegisterByInvitation /> },
    { path: "/about", element: <About /> },
    { path: "/public-offer", element: <PublicOffer /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/login", element: <Login /> },
    { path: "/:companyName/login", element: <Login /> },
    { path: "/register-company", element: <Register /> },
    { path: "/support", element: <SupportPage /> },
    {
      path: `/${applicationStore.company?.Tenant}/`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <CompanyHome />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/settings`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated && applicationStore.user?.roleId === Roles.Owner}>
          <CompanySettings />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/employees`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <Users />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/employees/:id`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <Account />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/payment-and-tariffs`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated && applicationStore.user.roleId === Roles.Owner}>
          <PaymentAndTariffs />
        </RequireAuth>
      ),
    },    
    {
      path: `/${applicationStore.company?.Tenant}/employees/:id/edit`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <EditEmployee />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <ProjectsList />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects/:id`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <ProjectPage isEditMode={false} />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects/:id/:documentId/edit`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <ProjectPage isEditMode={true} />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects/:id/:documentId?`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <ProjectPage isEditMode={false} />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects/:id/settings`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <ProjectSettings />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/projects/:id/task/:taskId`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <TaskPage />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/tests`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <Tests />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/tests/passage/:id`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <PassageTest />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/tests/result/:assignmentId/:id`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <UserAnswersTest />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/tests/create`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <AddTest />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/time-reports/:tabKey?`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <TimeReports />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/tests/:id`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <Tests />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/employees/salaries`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated&&[Roles.Financier, Roles.Owner].includes(applicationStore.user.roleId)}>
          <SalariesPage />
        </RequireAuth>
      ),
    },
    {
      path: `/${applicationStore.company?.Tenant}/employees/organogram`,
      element: (
        <RequireAuth isAuthenticated={applicationStore.isAuthenticated}>
          <OrganogramPage />
        </RequireAuth>
      ),
    },
  ];
  if (!applicationStore.isLoading) {
    routes.push({
      path: "*",
      element: <Error404 />,
    });
  }
  if (applicationStore.isLoading) {
    return useRoutes([
      {
        path: `*`,
        element: <Preloader />,
      },
    ]);
  }
  return useRoutes(routes);
});

export default Router;
