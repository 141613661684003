import { makeAutoObservable } from "mobx";
import ApiStore from "./ApiStore";
import { Employee } from "../api/EmployeesApiClient";
import { TypeTestQuestion } from "./ApplicationStore";


export interface Test {
  id: number,
  name: string | null,
  level: string | null,
  description: string | null,
  numberOfQuestions: number,
  skillId?: number | null,
  companyId?: number | null,
  language?: string | null,
  isPublic: boolean,
  numberOfAttempts: number,
  maxTimeMinutes: number,
  testQuestions: TestQuestion[]
}

export interface TestLevelSetting {
  id: number,
  level: string,
  minNumberOfQuestons: number,
  minNumberAttempts: number,
  minTime: number,
  singleChoicePercentage: number,
  multipleChoicePercentage: number,
  writeCodePercentage: number,
  fixCodePercentage: number,
  openAnswerPercentage: number,
}

export interface TestQuestion {
  id: number,
  name: string,
  correctAnswer?: string | null,
  code?: string | null,
  prompt?: string | null,
  testId: number,
  numberPoints: number,
  typeTestQuestionId: TypeTestQuestion,
  testOptions: TestOption[]
}

export interface TestOption {
  id: number,
  name: string,
  isCorrect: boolean,
  testQuestionId: number
}

export interface AssignmentStatus {
  id: number,
  name: string,
}

export interface TestAnswer {
  id: number,
  testQuestionId: number,
  testAttemptId: number,
  answer: string
}

export interface UserTestAssignment {
  id: number,
  assignedDate: string,
  finishAssignedDate: string,
  testId: number,
  test: Test | null,
  assignmentStatusId: number,
  assignmentStatus: AssignmentStatus | null,
  assignedByUserId: number,
  assignedToUserId: number,
  assignedToUser: Employee | null,
  testAttempts: TestAttempt[]
}

export interface TestAttempt {
  id: number,
  numberAttempt: number,
  percentage: number,
  startDate: string,
  finishDate: string,
  timeSpentMinutes: number | null,
  userTestAssignmentId: number,
  testAnswers: TestAnswer[]
}

class TestsStore {
  isLoading: boolean = true;
  ApiStore!: ApiStore;
  testsPublic: Test[] = [];
  testsCompany: Test[] = [];
  testCompanyAssignments: UserTestAssignment[] = [];
  testUserAssignments: UserTestAssignment[] = [];
  levelSettings: TestLevelSetting[];

  constructor(apiStore: ApiStore) {
    makeAutoObservable(this);
    this.ApiStore = apiStore;
    this.testsPublic = [];
    this.testsCompany = [];
    this.levelSettings = [];
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  createNew = async (addTest: Test) => {
    this.isLoading = true;

    const response = await this.ApiStore.TestsApiClient.addTest(addTest);
    this.isLoading = false;

    return response;
  };

  addAssignment = async (addAssignment: UserTestAssignment) => {
    this.isLoading = true;

    const response = await this.ApiStore.TestsApiClient.addUserTestAssignment(addAssignment);
    this.isLoading = false;

    return response;
  };

  deleteAssignmentById = async (assignmentId: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.TestsApiClient.deleteAssignmentById(assignmentId).finally(() => {
      this.isLoading = false;
    });

    return result;
  };

  async getAllTestLevelSettings() {
    const allSettings = await this.ApiStore.TestsApiClient.getAllTestLevelSettings();

    if (allSettings != null && Array.isArray(allSettings)) {
      this.levelSettings = allSettings;
    }

    return allSettings;
  }

  getAssignmentById = async (id: string) => {
    this.isLoading = true;
    const assignmentExist = await this.ApiStore.TestsApiClient.getAssignmentById(id);
    this.isLoading = false;

    return assignmentExist;
  }

  addTestAttempt = async (addTestAttempt: TestAttempt) => {
    this.isLoading = true;

    const response = await this.ApiStore.TestsApiClient.addTestAttempt(addTestAttempt);
    this.isLoading = false;

    return response;
  };

  getAllAssignmentByUserId = async (userId: string) => {
    this.isLoading = true;

    const testAssignments = await this.ApiStore.TestsApiClient.getAllAssignmentByUserId(userId).catch(() => {
      this.isLoading = false;
      return [];
    });

    if (testAssignments) {
      this.testUserAssignments = testAssignments;
    }
    
    this.isLoading = false;

    return testAssignments;
  }

  getAllAssignmentByCompanyId = async (companyId: string) => {
    this.isLoading = true;
    const testAssignments = await this.ApiStore.TestsApiClient.getAllAssignmentByCompanyId(companyId).catch(() => {
      this.isLoading = false;
      return [];
    });
    this.isLoading = false;
    if (testAssignments) {
      this.testCompanyAssignments = testAssignments;
    }
    return testAssignments;
  }

  deleteById = async (id: string) => {
    this.isLoading = true;
    const result = await this.ApiStore.TestsApiClient.deleteById(id).finally(() => {
      this.isLoading = false;
    });

    return result;
  };

  getAllPublicTests = async () => {
    this.isLoading = true;
    const testsPublic = await this.ApiStore.TestsApiClient.getAllPublic().catch(() => {
      this.isLoading = false;
      return [];
    });
    this.isLoading = false;
    if (testsPublic) {
      this.testsPublic = testsPublic;
    }
    return testsPublic;
  };

  getAllCompanyTests = async (companyId: string) => {
    this.isLoading = true;
    const testsCompany = await this.ApiStore.TestsApiClient.getAllByCompanyId(companyId).catch(() => {
      this.isLoading = false;
      return [];
    });
    this.isLoading = false;
    if (testsCompany) {
      this.testsCompany = testsCompany;
    }
    return testsCompany;
  }
}
export default TestsStore;
