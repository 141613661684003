import { observer } from "mobx-react-lite"
import Layout from "../../components/layout/Layout"
import { useStore } from "../../stores/root-store-context";
import { Button, Flex, Form, FormProps, Input, Modal, Popconfirm, Select, Image, Spin, Table, TableProps, Tag, Typography, message, AutoComplete, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, MailTwoTone } from '@ant-design/icons';
import { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Employee, Skill } from "../../api/EmployeesApiClient";
import { Roles } from "../../stores/EmployeesStore";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { useTranslation } from "react-i18next";
import { getUserComponent } from "../../helpers/user";
import { Option } from "antd/es/mentions";
import { AddJobTitle } from "./AddJobTitle";
import { ResourceItem } from "../../stores/ResourcesStore";
import TextArea from "antd/es/input/TextArea";
import { AddResourceGroup } from "./AddResourceGroup";

const Search = Input.Search;


const Resource = observer(() => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { applicationStore, resourceStore, employeesStore } = useStore();
    const [searchText, setSearchText] = useState("");
    const [filteredUser, setFilteredUser] = useState<ResourceItem[]>([]);

    const [selectedUser, setSelectedUser] = useState("");
    const [expandedSkills, setExpandedSkills] = useState<{ [key: string]: boolean }>({});


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const [isModalAddGroup, setIsModalAddGroup] = useState(false);

    const showModalJobTitle = () => {
        setIsModalAddGroup(true);
    };

    const hideModalAddGroup = () => {
        setIsModalAddGroup(false);
    };

    const getUserName = (userId: string) => {
        const user = employeesStore.users?.find(el => el.id === userId);
        if (user) {
            return `${user.firstName} ${user.lastName}`
        } else {
            return ""
        }
    };
    
    const columns: TableProps<ResourceItem>['columns'] = [
        {
            title: `${t("Resource")}`,
            dataIndex: 'title',
            key: 'title',
            render: (data, record) => <div>{record.title}</div>,
            sorter: (a: ResourceItem, b: ResourceItem) => TextSort(a.title, b.title)

        },
        {
            title: `${t("Type")}`,
            dataIndex: 'resourceGroupId',
            key: 'resourceGroupId',
            render: (data, record) => <div>{resourceStore.resourceGoups.find(el => el.id == record.resourceGroupId.toString())?.title}</div>,
            sorter: (a: ResourceItem, b: ResourceItem) => TextSort(a.title, b.title)

        },
        {
            title: `${t("Photo")}`,
            dataIndex: 'photoIds',
            key: 'photoIds',
            render: (data, record) => <div>{}</div>,
            sorter: (a: ResourceItem, b: ResourceItem) => TextSort(a.title, b.title)
        },
        {
            title: `${t("User")}`,
            dataIndex: 'assignedUserId',
            key: 'assignedUserId',
            render: (data, record) => {
                if (applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager) {
                    return (
                        <Flex gap={5} align="center">
                            <AutoComplete
                                style={{ height: 40, width: 250 }}
                                placeholder={t("User")}
                                value={getUserName(data)}
                                onChange={(e, option: any) => {
                                    // form.setFieldValue("chiefId", option?.id)
                                    // record.chiefId = option?.id;
                                    // employeesStore.editUser({
                                    //     id: record.id,
                                    //     chiefId: option?.id
                                    // }).then(() => {
                                    //     employeesStore.getUsers().catch(console.log)
                                    // })
                                }}
                                options={employeesStore.users?.filter(user => user.id !== record.assignedUserId as any).map((el, index) => ({
                                    value: el.id,
                                    id: el.id, label: <Flex align="center">
                                        <Image
                                            preview={false}
                                            width={40}
                                            height={40}
                                            style={{ borderRadius: "50%", objectFit: "cover", minWidth: 40 }}
                                            src={el.photoId ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${el.photoId}` :
                                                `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`
                                            }
                                        />
                                        <Flex vertical justify="start" gap={5} style={{ marginLeft: 20 }}>
                                            <div>{el.firstName + " " + el.lastName}</div>
                                            <div>{el.email}</div>
                                        </Flex>
                                    </Flex>
                                }))}
                            />
                            <Button disabled={!(applicationStore.user.roleId === Roles.Owner || applicationStore.user.id === record.assignedUserId as any)}
                                onClick={() => {
                                    employeesStore.editUser({
                                        id: record.id,
                                        chiefId: -1
                                    }).then(() => {
                                        employeesStore.getUsers().catch(console.log)
                                    })
                                }}
                                type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                        </Flex>
                    )
                } else if (applicationStore.user.roleId === Roles.User) {
                    return (
                        <div>{getUserComponent(employeesStore.users.find(el => el.id === data), applicationStore.company?.Tenaut, 20)}</div>
                    )
                } else {
                    return (
                        <div>{getUserComponent(employeesStore.users.find(el => el.id === data), applicationStore.company?.Tenaut, 20)}</div>
                    )
                }
            },
            sorter: (a: ResourceItem, b: ResourceItem) => TextSort(a.assignedUserId as  any || "", b.assignedUserId as  any || "")
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: ResourceItem) => {
                console.log(record)

                return (
                    <>
                        <Flex justify="space-between" gap={10}>
                            <Button type="primary" shape="circle" onClick={() => {
                                //navigate(`./${record.id}`)
                            }} icon={<EditOutlined />} />
                            <Popconfirm

                                title="Delete the user"
                                description="Are you sure to delete this user?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={async () => {
                                    try {
                                        // await employeesStore.deleteUser(record.id);
                                        message.success("User deleted successfully");
                                    } catch (error) {
                                        message.error("Failed to delete user");
                                    }
                                }}
                            >
                                <Button disabled={
                                    !(applicationStore.user.roleId === Roles.Manager
                                        || applicationStore.user.roleId === Roles.Owner)
                                } type="primary" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Flex>

                    </>
                );


            },
        }
    ];


    const onFinish: FormProps<ResourceItem>['onFinish'] = async (user: ResourceItem) => {
        console.log('Success:', user);
        message.open({
            type: 'loading',
            content: t("SendingInvitation"),
            key: 'invitation'
        });
        resourceStore.createNew(user).then(() => {
            message.destroy("invitation");
            message.success(t("TheInvitationWasSentToMail"));
            form.resetFields();
            hideModal();
           
        }).catch(error => {
            message.destroy("invitation");
            message.error(error.message);
        })
    };

    useEffect(() => {
        if(employeesStore.users.length === 0){
            employeesStore.getUsers()
        }
        resourceStore.getAllGroups().then(()=>{
            resourceStore.getAllResource().catch(console.log);
        }).catch(console.log);

    }, [])

    useEffect(() => {
        let data = employeesStore.users;
        if (searchText.length > 0) {
            data = data.filter(el =>
                el.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                el.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                el.email?.toLowerCase().includes(searchText.toLowerCase())
            );
        }
        data = data.filter(el => el.isActive !== false);
        data = data.map(el => { return { ...el, key: el.id } });
    }, [employeesStore.users, searchText]);

    useEffect(() => {
        
    }, [resourceStore.resources]);

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={`${t("Resource")}`}>
            <div className="container" style={{ margin: "20px auto" }}>
                <Spin spinning={employeesStore.isLoading || resourceStore.isLoading}>
                    {applicationStore.isFreezeModalVisible ? (
                        <p style={{ padding: '20px', textAlign: 'center' }}>Access to users is limited until the subscription is renewed.</p>
                    ) : (
                        <>
                            <Flex justify="end" style={{ marginBottom: 10 }}>
                                <div>
                                    {applicationStore.user.roleId === Roles.Owner || applicationStore.user.roleId === Roles.Manager ?
                                        <Button type="primary" style={{ float: "right", marginBottom: 20, borderRadius: 0, }} onClick={showModal}>
                                            {`${t("AddResource")}`} <i className="fa-solid fa-plus" />
                                        </Button> : <></>
                                    }
                                </div>
                            </Flex>
                            <Table columns={columns} dataSource={resourceStore.resources} onRow={(record) => ({
                                // style: record.assignedUserId === 0 ? {
                                //     backgroundColor: '#c6c6c6',
                                //     cursor: "not-allowed",
                                //     pointerEvents: 'stroke'
                                // } : {},
                            })} />
                        </>
                    )}
                </Spin>
            </div>

            <Modal title={t("AddResource")} open={isModalOpen} footer={null} onCancel={hideModal}>
                <Form form={form} onFinish={onFinish} initialValues={{ role: "user", "chiefId": applicationStore.user.id, "chief": applicationStore.user.email }} labelCol={{ span: 24 }}>
                    <Form.Item<any> name="chiefId" hidden >
                        <Input />
                    </Form.Item>
                    <Form.Item<ResourceItem> label={t("Title")} name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item<ResourceItem> label={t("Description")} name="description" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <TextArea placeholder="Description" rows={5}/>
                    </Form.Item>
                    <Form.Item<ResourceItem> label={t("Group")} name="resourceGroupId" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Select options={[...resourceStore.resourceGoups.map(el=>{
                            return {label: el.title, value: el.id}
                        }),
                        {
                            key: "create-new",
                            value: "create-new",
                            label: <Button onClick={showModalJobTitle}>{t("Add")}</Button>,
                          },
                        ]}/>
                    </Form.Item>
                    <Form.Item<ResourceItem> label={t("Photos")} name="assignedUserId" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 65, float: "right" }}>
                            {`${t("Add")}`} <i className="fa-solid fa-arrow-right-long" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <AddResourceGroup isModalOpen={isModalAddGroup} hideModal={hideModalAddGroup} userId={selectedUser} />

        </Layout>
    )
})
export default Resource;