import React from 'react';
import { CloseOutlined, CalendarOutlined } from '@ant-design/icons';
import { Divider, message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

interface Experience {
  Company: string;
  Project: string;
  Position: string;
  Description: string;
  StartDate: string;
  EndDate: string;
}

interface ExperienceBlockProps {
  experienceList: Experience[];
  canRemove?: boolean;
  removeExperience: (experience: Experience) => Promise<void>;
}

const ExperienceBlock: React.FC<ExperienceBlockProps> = observer(({ experienceList, canRemove = false, removeExperience }) => {
  const { t } = useTranslation();

    if (experienceList.length === 0) {
        return null;
      }
  return (
    <div
      style={{
        padding: "20px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        width: "100%",
        marginBottom: "16px",
      }}
    >
      <h3 style={{ marginBottom: "20px", color: "#333" }}>
        {t("Experience")}
      </h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {experienceList.map((item, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "16px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
            }}
          >
            {canRemove && (
              <CloseOutlined
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  cursor: "pointer",
                  color: "#ff4d4f",
                  fontSize: "16px",
                }}
                onClick={async () => {
                  try {
                    await removeExperience(item);
                  } catch (error) {
                    message.error("Failed to remove experience");
                  }
                }}
              />
            )}
            <h3
              style={{
                marginBottom: "8px",
                fontWeight: "bold",
                color: "#2a2a2a",
                fontSize: "16px",
              }}
            >
              {t("Company")} {item.Company}
            </h3>
            <div
              style={{
                marginBottom: "8px",
                color: "#4a4a4a",
                fontSize: "14px",
              }}
            >
              {t("Project")}: {item.Project}
            </div>
            <div
              style={{
                marginBottom: "8px",
                color: "#4a4a4a",
                fontSize: "14px",
              }}
            >
              {t("Position")}: {item.Position}
            </div>
            <div
              style={{
                color: "#8c8c8c",
                fontSize: "14px",
              }}
            >
              <CalendarOutlined
                style={{ marginRight: "8px", color: "#faad14" }}
              />
              {item.StartDate ? dayjs(item.StartDate).format("YYYY-MM-DD") : ""} - {(item.EndDate === "0001-01-01" || item.EndDate === "1901-01-01" || !item.EndDate) 
                ? t("PresentTime") 
                : dayjs(item.EndDate).format("YYYY-MM-DD")}
            </div>
            {item.Description && (
              <>
                <Divider style={{ margin: "12px 0", borderColor: "#e8e8e8" }} />
                <div
                  style={{
                    backgroundColor: "#e6f7ff",
                    color: "#1890ff",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {item.Description}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

export default ExperienceBlock;
