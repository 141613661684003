import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import { Button, message, Card, Row, Col, Divider, Spin, App, Popconfirm, Modal, Typography, Select, Form, Tabs } from "antd";
import { Test, UserTestAssignment } from "../../stores/TestsStore";
import { DeleteOutlined, EditOutlined, PlayCircleOutlined, UserAddOutlined, QuestionCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { TestUsersInfo } from "../../components/test/TestUsersInfo";
import { Roles } from "../../stores/EmployeesStore";
import { AssignmentStatus } from "../../stores/ApplicationStore";
import { UserTestInfo } from "../../components/test/UserTestInfo";
import { Skill } from "../../api/EmployeesApiClient";

const { Text } = Typography;
const { TabPane } = Tabs;

const Tests = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [publicTests, setPublicTests] = useState<Test[]>([]);
    const [filteredPublicTests, setFilteredPublicTests] = useState<Test[]>([]);
    const [companyTests, setCompanyTests] = useState<Test[]>([]);
    const [filteredCompanyTests, setFilteredCompanyTests] = useState<Test[]>([]);
    const [userAssignments, setUserAssignments] = useState<UserTestAssignment[]>([]);
    const { applicationStore, apiStore } = useStore();
    const { testsStore } = useStore();
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [modalTest, setModalTest] = useState<Test | null>();
    const [selectedLevels, setSelectedLevels] = useState<string[]>(["junior", "middle", "senior"]);

    const showModal = (test: Test) => {
        setIsModalVisible(true);
        setModalTest(test);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedUsers([]);
        setModalTest(null);
    };

    const handleLevelChange = (value: string[]) => {
        setSelectedLevels(value);

        if (value.length == 0) {
            setFilteredCompanyTests(companyTests);
            setFilteredPublicTests(publicTests);
        }
        else {
            setFilteredCompanyTests(companyTests.filter(t => value.includes(t.level ?? '')));
            setFilteredPublicTests(publicTests.filter(t => value.includes(t.level ?? '')));
        }
    };

    const handleAssign = async () => {

        let resultOperation = true;

        for (const userSelected of selectedUsers) {
            const addAssignment: UserTestAssignment = {
                id: 0,
                test: null,
                testId: modalTest?.id ?? 0,
                assignedDate: new Date().toISOString(),
                assignmentStatusId: 1,
                assignedToUser: null,
                assignmentStatus: null,
                assignedToUserId: Number(userSelected),
                assignedByUserId: Number(applicationStore.user.id),
                testAttempts: []
            };

            try {
                const result = await testsStore.addAssignment(addAssignment);

                if (result == null) {
                    resultOperation = false;
                    break;
                };

                setUserAssignments(prevState => [...prevState, result]);

            } catch (error) {
                resultOperation = false;
                break;
            }
        }

        setTimeout(() => {
            if (resultOperation) {
                message.success(t("Success"));
            } else {
                message.error(t("Error"));
            }
        }, 0);

        setIsModalVisible(false);
    };

    const handleUserChange = (value: number) => {
        setSelectedUsers((prevValue) => [...prevValue, value]);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const publicTests: Test[] = await testsStore.getAllPublicTests();
            const companyTests: Test[] = await testsStore.getAllCompanyTests(applicationStore.company.Id ?? "");
            let userAssignments: UserTestAssignment[] = [];

            if (applicationStore.user.roleId == Roles.User) {
                userAssignments = await testsStore.getAllAssignmentByUserId(applicationStore.user.id ?? "");
            }
            else {
                userAssignments = await testsStore.getAllAssignmentByCompanyId(applicationStore.company.Id ?? "");
            }

            const filteredPublicTests = publicTests.filter(publicTest =>
                !companyTests.some(companyTest => companyTest.id === publicTest.id)
            );

            setUserAssignments(userAssignments);
            setCompanyTests(companyTests);
            setFilteredCompanyTests(companyTests);
            setPublicTests(filteredPublicTests);
            setFilteredPublicTests(filteredPublicTests);
            setLoading(false);
        };

        fetchData();
    }, [testsStore]);

    const getCardImage = (skillId: string | null) => {
        const existSkill = applicationStore.skills.find(s => s.skillId == skillId)?.title || t("unknown_skill");

        if (existSkill == "unknown_skill") {
            return "/assets/img/test/Test.jpg";
        }
        else if (existSkill == "C#") {
            return "/assets/img/test/CSharp.jpg";
        }
        else {
            return `/assets/img/test/${existSkill}.jpg`;
        }
    }

    const handleDeleteTest = async (testId: number) => {
        const result = await testsStore.deleteById(testId.toString());

        if (result) {
            message.success(t("Success"));

            setCompanyTests(prevTests => prevTests.filter(test => test.id !== testId));
        } else {
            message.error(t("add_test.DeleteFailed"));
        }
    };

    const navigateToPassage = async (testId: number) => {
        navigate(`/${applicationStore.company.Tenaut}/tests/passage/${testId}`);
    }

    const renderTestCard = (test: Test) => (
        <Card
            bordered
            style={{
                width: 300,
                height: 400,
                margin: "10px",
                border: "1px solid #dcdcdc",
                position: "relative",
            }}
            hoverable
            actions={
                applicationStore.user.roleId === Roles.User
                    ? [
                        <Button
                            key="start"
                            icon={<PlayCircleOutlined />}
                            type="primary"
                            size="middle"
                            onClick={() => navigateToPassage(test.id)}
                        >
                            {t(`tests.passTestTitleButton`)}
                        </Button>
                    ]
                    : test.isPublic === false
                        ? [
                            <Button
                                key="start"
                                icon={<PlayCircleOutlined />}
                                type="primary"
                                size="middle"
                                onClick={() => navigateToPassage(test.id)}
                            >
                            </Button>,
                            <Button
                                key="purpose"
                                icon={<UserAddOutlined />}
                                type="primary"
                                size="middle"
                                style={{ backgroundColor: "green", borderColor: "green" }}
                                onClick={() => showModal(test)}
                            >
                            </Button>,
                            <Button
                                key="edit"
                                icon={<EditOutlined />}
                                size="middle"
                            >
                            </Button>,
                            <Popconfirm
                                key="delete"
                                title={t("add_test.DeleteTestConfirmation")}
                                onConfirm={async () => await handleDeleteTest(test.id)}
                                okText={t("add_test.Confirm")}
                                cancelText={t("add_test.Cancel")}
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    size="middle"
                                >
                                </Button>
                            </Popconfirm>
                        ]
                        : [
                            <Button
                                key="start"
                                icon={<PlayCircleOutlined />}
                                type="primary"
                                size="middle"
                                onClick={() => navigateToPassage(test.id)}
                            >
                            </Button>,
                            <Button
                                key="purpose"
                                icon={<UserAddOutlined />}
                                type="primary"
                                size="middle"
                                style={{ backgroundColor: "green", borderColor: "green" }}
                                onClick={() => showModal(test)}
                            >
                            </Button>
                        ]
            }
        >
            <div
                style={{
                    width: "100%",
                    height: "200px",
                    marginBottom: "10px",
                    overflow: "hidden",
                }}
            >
                <img
                    src={getCardImage(test.skillId?.toString() ?? "")}
                    alt="test-image"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </div>
            <Card.Meta
                title={test.name == null
                    ? test.skillId != null
                        ? applicationStore.skills.find(s => s.skillId == test.skillId?.toString())?.title || t("unknown_skill")
                        : t("no_skill")
                    : test.name}
                description={
                    <>
                        <p>{t("add_test.NumberQuestions")}: {test.numberOfQuestions}</p>
                        <p>{t("add_test.Level")}: {test.level}</p>
                        {test.language != null && (<p>{t("tests.Language")}: {test.language == "en" ? t("add_test.English") : test.language == "ua" ? t("add_test.Ukrainian") : t("add_test.Russian")}</p>)}
                        <p>{t("add_test.NumberAttempts")}: {test.numberOfAttempts}</p>
                        <p>{t("add_test.MaxTime")}: {test.maxTimeMinutes} {t("add_test.minutes")}</p>
                    </>
                }
            />
        </Card>
    );

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("add_test.tests")}>

            {applicationStore.user.roleId == Roles.User ? (
                <div style={{ margin: "20px" }}>
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={t("add_test.tests")} key="1" style={{ marginBottom: 50 }}>
                            <Spin spinning={loading} style={{ minHeight: 600 }}>
                                <Divider>{t("tests.assignedTests")}</Divider>
                                <Row gutter={[16, 16]} justify="start">
                                    {userAssignments.length > 0 ? (
                                        userAssignments.filter(ua => ua.assignedToUserId.toString() == applicationStore.user.id).filter(ua => ua.test != null).filter(ua => ua.assignmentStatusId != AssignmentStatus.Completed).map(ua => (
                                            <Col key={ua.test!.id}>
                                                {renderTestCard(ua.test!)}
                                            </Col>
                                        ))
                                    ) : (
                                        <p>{t("add_test.no_public_tests")}</p>
                                    )}
                                </Row>
                            </Spin>
                        </TabPane>

                        <TabPane tab={t("tests.Results")} key="2">
                            <div style={{ padding: "20px" }}>
                                <UserTestInfo userAssignments={userAssignments} setUserAssignments={setUserAssignments} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <div style={{ margin: "20px" }}>
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={t("add_test.tests")} key="1" style={{ marginBottom: 50 }}>
                            <Spin spinning={loading} style={{ minHeight: 600 }}>
                                <div style={{ margin: "20px", textAlign: "left", display: "flex", alignItems: "center", gap: "10px", width: '30%' }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            navigate(`/${applicationStore.company.Tenaut}/tests/create`);
                                        }}
                                        style={{ flexShrink: 0 }}
                                    >
                                        {t("add_test.create_test")}
                                    </Button>

                                    <Select
                                        mode="multiple"
                                        value={selectedLevels}
                                        style={{ color: "black", minWidth: "150px", flexGrow: 1 }}
                                        options={[
                                            { label: "Junior", value: "junior" },
                                            { label: "Middle", value: "middle" },
                                            { label: "Senior", value: "senior" }
                                        ]}
                                        onChange={handleLevelChange}
                                        dropdownStyle={{ color: "black" }}
                                    />
                                </div>

                                <Divider>{t("add_test.public_tests")}</Divider>
                                <Row gutter={[16, 16]} justify="start">
                                    {filteredPublicTests.length > 0 ? (
                                        filteredPublicTests.map(test => (
                                            <Col key={test.id}>
                                                {renderTestCard(test)}
                                            </Col>
                                        ))
                                    ) : (
                                        <p>{t("add_test.no_public_tests")}</p>
                                    )}
                                </Row>

                                {filteredCompanyTests.length > 0 && (
                                    <Divider>{t("add_test.company_tests")}</Divider>
                                )}

                                {filteredCompanyTests.length > 0 && (
                                    <Row gutter={[16, 16]} justify="start">
                                        {filteredCompanyTests.map(test => (
                                            <Col key={test.id}>
                                                {renderTestCard(test)}
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </Spin>
                        </TabPane>

                        <TabPane tab={t("Users")} key="2">
                            <div style={{ padding: "20px" }}>
                                <TestUsersInfo userAssignments={userAssignments} setUserAssignments={setUserAssignments} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            )}


            <Modal
                title={t("passage_test.test_details")}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        {t("add_test.Cancel")}
                    </Button>,
                    <Button
                        key="assign"
                        type="primary"
                        onClick={handleAssign}
                    >
                        {t("tests.assign")}
                    </Button>,
                ]}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {modalTest?.name && (
                        <Text>{t("add_test.Name")}: {modalTest?.name}</Text>
                    )}
                    <Text>{t("passage_test.topic")}: {applicationStore.skills.find(s => s.skillId == modalTest?.skillId?.toString())?.title || t("unknown_skill")}</Text>
                    <Text>{t("passage_test.number_of_attempts")}: {modalTest?.numberOfAttempts}</Text>
                    <Text>
                        <QuestionCircleOutlined /> {t("passage_test.number_of_questions")}: {modalTest?.numberOfQuestions}
                    </Text>
                    <Text>
                        <ClockCircleOutlined /> {t("passage_test.time_limit")}: {modalTest?.maxTimeMinutes} {t("passage_test.minutes")}
                    </Text>
                    {(modalTest?.description != null && modalTest.description.length > 0) && (
                        <Text>{t("add_test.Description")}: {modalTest?.description}</Text>
                    )}

                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                        <Form.Item label={t("tests.selectUsers")}>
                            <Select
                                mode="multiple"
                                style={{ width: "100%", height: "auto", overflow: 'auto' }}
                                onChange={handleUserChange}
                            >
                                {applicationStore.users
                                    .filter((u) => u.roleId == 3)
                                    .map((user) => (
                                        <Select.Option key={user.id} value={user.id} label={user.email}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <img
                                                    src={user.photoId
                                                        ? `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/${user.photoId}`
                                                        : `https://res.cloudinary.com/dnl3x07wo/image/upload/w_300/z03w7hlsy8hcoic9dlcn`}
                                                    alt="avatar"
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                <span>{user.email}</span>
                                            </div>
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </Layout>
    );
});

export default Tests;