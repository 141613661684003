import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout"
import { Button, Form, Input, Spin, message } from "antd";
import { useStore } from "../stores/root-store-context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateMessages } from "../helpers/validationMessages";
import { useTranslation } from "react-i18next";

type RegisterItem = {
    title: string,
    description: string,
    tenant: string,
    adress: string,
    email: string,
    password: string,
}
const Register = observer(() => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [registerItem, setRegisterItem] = useState<RegisterItem>();
    const [tenantError, setTenantError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const { applicationStore, apiStore } = useStore();
    const navigate = useNavigate();

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    async function handleSubmit() {
        setIsLoading(true)
        const title = registerItem?.title;
        const description = registerItem?.description;
        const tenant = registerItem?.tenant;
        const adress = registerItem?.adress;
        const email = registerItem?.email
        const password = registerItem?.password;
        try {
            const response = await apiStore.CommonApiClient.companyCreate(title as string, description as string, tenant as string, adress as string);
            console.log("response.id");
            console.log(response);
            const response2 = await apiStore.CommonApiClient.accountRegister(
                email as string,
                password as string,
                "" as string,
                "" as string,
                response.id,
                "owner"
            );
            message.open({
                type: 'success',
                content: t('Company register successful!'),
                key: 'checkingTenant'
            });
            navigate("/login");
        } catch (error) {
            message.open({
                type: 'error',
                content: t('Unexpected error!') + error,
                key: 'checkingTenant'
            });
        } finally {
            setIsLoading(false)
        }
    }

    const validateTenant = async (tenant: string) => {
        message.open({
            type: 'loading',
            content: t('Checking tenant...'),
            key: 'checkingTenant'
        });

        const res = await applicationStore.checkCompany(tenant);

        if (!res) {
            message.destroy("checkingTenant");
            message.open({
                type: 'success',
                content: t('Tenant is free!'),
                key: 'checkingTenant'
            });
            return true;
        } else {
            message.destroy("checkingTenant");
            message.open({
                type: 'error',
                content: t('Tenant already using!'),
                key: 'checkingTenant'
            });
            return false;
        }
    }

    const validateEmail = async (tenant: string) => {
        message.open({
            type: 'loading',
            content: t('Checking email...'),
            key: 'checkingTenant'
        });
        const res = await applicationStore.checkEmail(tenant);

        if (!res) {
            message.destroy("checkingTenant");
            message.open({
                type: 'success',
                content: t('Email is free!'),
                key: 'checkingTenant'
            });
            return true;
        } else {
            message.destroy("checkingTenant");
            message.open({
                type: 'error',
                content: t('Email already using!'),
                key: 'checkingTenant'
            });
            return false;
        }
    }

    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div>
                <section className="contact-section fix section-padding">
                    <div className="container">
                        <div className="contact-wrapper-2">
                            <div className="col-lg-12">
                                <div className="contact-content">
                                    <h2>{t('loginPrompt')}</h2>
                                    <p>
                                        {t('fillRequiredInfo')}
                                    </p>
                                    <Spin spinning={isLoading}>
                                        <Form
                                            className="contact-form-items"
                                            form={form}
                                            variant='outlined'
                                            layout="vertical"
                                            labelCol={{ span: 24 }}
                                            onValuesChange={(changedValues, allValues) => setRegisterItem(allValues)}
                                            validateMessages={validateMessages}
                                            requiredMark={customizeRequiredMark}
                                            autoComplete="off"
                                            onFinish={async (values) => {
                                                console.log("values");
                                                console.log(values);
                                                handleSubmit();
                                            }}
                                            onFinishFailed={(errorInfo) => {
                                                console.log("onFinishFailed");
                                                console.log(errorInfo);
                                                if (errorInfo.errorFields.length > 0) {
                                                    errorInfo.errorFields.forEach((field) => {
                                                        if (field.name[0] === "tenant") {
                                                            setTenantError( t('tenant is required!'));
                                                        }
                                                        if (field.name[0] === "email") {
                                                            setEmailError(t('email is required!'));
                                                        }
                                                    });
                                                }
                                            }}
                                            scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".3s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                                                        <Input placeholder={t('Company title')} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="tenant" style={{ width: "100%" }}
                                                        validateStatus={tenantError ? 'error' : ''}
                                                        help={tenantError}
                                                        rules={[{
                                                            required: true,
                                                            message: t('tenant is required!'),
                                                            validator: (_, value) => {
                                                                if (value.length > 3) {
                                                                    clearTimeout(timeoutId);
                                                                    const newTimeoutId = setTimeout(async () => {
                                                                        const res = await validateTenant(value);
                                                                        if (res) {
                                                                            setTenantError('');
                                                                        } else {
                                                                            setTenantError('Tenant already using!');
                                                                        }
                                                                    }, 750);
                                                                    setTimeoutId(newTimeoutId)
                                                                    if (tenantError.length === 0) {
                                                                        return Promise.resolve();
                                                                    } else {
                                                                        return Promise.reject('Tenant already using!');
                                                                    }
                                                                }
                                                            }
                                                        }]} >
                                                        <Input placeholder={t('Site tenant')} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="adress" style={{ width: "100%" }} rules={[{ required: true }]} >
                                                        <Input placeholder={t('Address')} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="description" style={{ width: "100%" }} rules={[{ required: true }]} >
                                                        <Input placeholder={t('Description')} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="email" style={{ width: "100%" }}
                                                        validateStatus={emailError ? 'error' : ''}
                                                        help={emailError}
                                                        rules={[{
                                                            required: true,
                                                            message: t('email is required!'),
                                                            validator: (_, value) => {
                                                                if (value.length > 3) {
                                                                    clearTimeout(timeoutId);

                                                                    const newTimeoutId = setTimeout(async () => {
                                                                        const res = await validateEmail(value);
                                                                        if (res) {
                                                                            setEmailError('');
                                                                        } else {
                                                                            setEmailError('Email already using!');
                                                                        }
                                                                    }, 750);


                                                                    setTimeoutId(newTimeoutId);
                                                                    if (emailError.length === 0) {
                                                                        return Promise.resolve();
                                                                    } else {
                                                                        return Promise.reject('Email already using!');
                                                                    }
                                                                }
                                                            }
                                                        }]} >
                                                        <Input placeholder={t('Email')} pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="form-clt">
                                                    <Form.Item<RegisterItem> name="password" style={{ width: "100%" }} rules={[
                                                        { 
                                                            required: true, 
                                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                            message: t('passwordValidation') 
                                                        }
                                                    ]} >
                                                        <Input placeholder={t('Password')} type="password" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 wow fadeInUp" data-wow-delay=".9s">
                                                <Form.Item>
                                                    <Button type="primary" className="theme-btn" htmlType="submit" style={{ height: 60 }} onClick={async () => {

                                                        try {
                                                            // await form.validateFields();
                                                            console.log("true")
                                                            // handleSubmit();
                                                        } catch (e) {
                                                            console.log("Not a valid form")
                                                        }
                                                        // console.log(res);
                                                        // handleSubmit()
                                                    }}>
                                                        {t('Register')} <i className="fa-solid fa-arrow-right-long" />
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Form>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<< Map Section Start >>*/}
                <div className="map-section">
                    <div className="map-items">
                        <div className="googpemap">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6678.7619084840835!2d144.9618311901502!3d-37.81450084255415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b4758afc1d%3A0x3119cc820fdfc62e!2sEnvato!5e0!3m2!1sen!2sbd!4v1641984054261!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
})
export default Register;