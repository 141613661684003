import { observer } from "mobx-react-lite";
import Layout from "../../components/layout/Layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { useEffect, useState } from "react";
import { Form, Input, Checkbox, Button, Select, Steps, InputNumber, App, Spin, Radio } from 'antd';
import { Skill } from "../../api/EmployeesApiClient";
import { Test, TestLevelSetting } from "../../stores/TestsStore";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AddTestFormQustions } from "../../components/test/AddTestFormQustions";
import { TypeTestQuestion } from "../../stores/ApplicationStore";
import { GoogleGenerativeAI } from "@google/generative-ai";

export interface QuestionForm {
    question: string;
    options?: string[];
    correctAnswer?: string;
    numberPoints?: number;
    type: "singleChoice" | "multipleChoice" | "writeCode" | "fixCode" | "openAnswer";
    prompt?: string;
    code?: string;
}

interface TestForm {
    name: string,
    description: string | null,
    numberOfTests: number,
    selectedSkill: string,
    selectedLanguage: string,
    isOneLanguage: boolean,
    withAI: boolean,
    level: string,
    numberOfAttempts: number,
    maxTime: number,
}

export interface LevelSettings {
    maxTimeMin: number;
    attemptsMin: number;
    numberQuestions: number;
}

export const questionPoints = {
    singleChoice: 1,
    multipleChoice: 2,
    writeCode: 3,
    fixCode: 3,
    openAnswer: 2
};

const defaultSettings: TestLevelSetting = {
    id: 0, 
    level: '',
    minNumberAttempts: 0,
    minNumberOfQuestons: 0,
    minTime: 0,
    singleChoicePercentage: 0,
    multipleChoicePercentage: 0,
    writeCodePercentage: 0,
    fixCodePercentage: 0,
    openAnswerPercentage: 0,
};

const { Step } = Steps;

const AddTest = observer(() => {
    const API_AI_KEY = 'AIzaSyAsWBPVCBCJOFKF9flVgLa0a4v9RggzKTo';
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { applicationStore, apiStore } = useStore();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState<TestForm>({
        name: '',
        description: '',
        numberOfTests: 0,
        selectedSkill: '',
        selectedLanguage: 'en',
        isOneLanguage: false,
        withAI: false,
        level: '',
        numberOfAttempts: 0,
        maxTime: 0,
    });
    const [questions, setQuestions] = useState<QuestionForm[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { message } = App.useApp();
    const [formTest] = Form.useForm();
    const { testsStore } = useStore();
    const [levelSettings, setLevelSettings] = useState<LevelSettings>({
        maxTimeMin: 1,
        attemptsMin: 1,
        numberQuestions: 10
    });
    const [testSettings, setTestSettings] = useState<{
        junior: TestLevelSetting;
        middle: TestLevelSetting;
        senior: TestLevelSetting;
    }>();

    useEffect(() => {
        const fetchData = async () => {
            const allSettings = await testsStore.getAllTestLevelSettings();
            console.log("Test level settings", allSettings);
            setTestSettings({
                junior: allSettings.find((setting) => setting.level === 'junior') || defaultSettings,
                middle: allSettings.find((setting) => setting.level === 'middle') || defaultSettings,
                senior: allSettings.find((setting) => setting.level === 'senior') || defaultSettings,
            });
        }
    
        fetchData();
    }, []);

    const generateQuestions = () => {
        let types = testSettings?.junior;

        if (formData.level === 'middle') {
            types = testSettings?.middle;
        } else if (formData.level === 'senior') {
            types = testSettings?.senior;
        }

        if (!types) {
            console.error("No test settings found for the selected level.");
            return; 
        }

        const typeArray = Object.entries(types).flatMap(([type, percentage]) =>
            Array(Math.round((percentage / 100) * formData.numberOfTests)).fill(type)
        );

        const shuffledTypes = typeArray.sort(() => Math.random() - 0.5);

        const generatedQuestions = shuffledTypes.map((type) => ({
            question: '',
            options: Array.from({ length: 3 }, () => ''),
            correctAnswer: '',
            type,
        }));

        setQuestions(generatedQuestions);
    };

    const handleNext = async () => {
        try {
            await formTest.validateFields([
                'numberOfTests',
                'name',
                'selectedSkill',
                'numberOfAttempts',
                'maxTime',
                'level',
            ]);
            if (currentStep === 0) {
                if (formData.withAI) {
                    await fetchQuestions();
                }
                else {
                    generateQuestions();
                }
                setCurrentStep(currentStep + 1);
            }
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };

    const fetchQuestions = async () => {
        setLoading(true);

        let language = "Ukrainian";

        if(formData.isOneLanguage){
            language = formData.selectedLanguage === "en" ? "English" : formData.selectedLanguage=== "ua" ? "Ukrainian" : "Russian";
        }
        else{
            language = i18n.language === "en" ? "English" : i18n.language === "uk" ? "Ukrainian" : "Russian";
        }

        if (!testSettings) {
            console.error("No test settings found for the selected level.");
            return; 
        }

        let difficultyLevel = "beginner";
        let types = testSettings["junior"];
        if (formData.level === "junior") {
            difficultyLevel = "beginner";
            types = testSettings["junior"];
        } else if (formData.level === "middle") {
            difficultyLevel = "intermediate";
            types = testSettings["middle"];
        } else if (formData.level === "senior") {
            difficultyLevel = "advanced";
            types = testSettings["senior"];
        }

        const requestAI = ` 
            Generate ${formData.numberOfTests} unique programming questions on the topic of ${formData.selectedSkill} at a ${difficultyLevel} level.

            Make sure the output strictly adheres to the following guidelines:

            1. Provide the output as a JSON array, with no additional text, comments, or explanations. The output should begin with "[" and end with "]".
            2. Avoid any control characters, such as newlines or tabs, within string values. All strings must be properly escaped and conform to JSON standards.
            3. Distribute the question types as follows:
            - ${types.singleChoicePercentage}% for single-choice questions.
            - ${types.multipleChoicePercentage}% for multiple-choice questions.
            - ${types.writeCodePercentage}% for questions requiring writing code.
            - ${types.fixCodePercentage}% for questions about fixing code.
            - ${types.openAnswerPercentage}% for open-ended questions.
        
            For single-choice and multiple-choice questions:
            - The "options" field should be an array containing the available options.
            - For single-choice questions:
                - Include exactly four options in the "options" array.
                - The "correctAnswer" field should match exactly one of the options.
            - For multiple-choice questions:
                - Include at least two options in the "options" array.
                - Ensure the "correctAnswer" field matches one or more of the options.
        
            For questions requiring code writing or code fixing:
            - For "writeCode" questions:
                - Include a prompt that specifies what needs to be written.
                - Ensure the "correctAnswer" field contains the complete solution code.
            - For "fixCode" questions:
                - The "question" field should be without code and only text question.
                - Include a "code" field with the code snippet containing one or more errors.
                - Ensure the "correctAnswer" field contains the corrected version of the code.
        
            For open-ended questions:
            - Provide a prompt that encourages a detailed written response.
            - The "correctAnswer" field should be left empty.
        
            Format the output as a JSON object with fields:
            - "question": The question text.
            - "options": An array of options (if applicable).
            - "correctAnswer": A string or array that matches the correct answer(s), or empty for open-ended questions.
            - "type": The type of question ("singleChoice", "multipleChoice", "writeCode", "fixCode", "openAnswer").
            - "code": For "writeCode" or "fixCode", include the relevant code snippet.
        
            Ensure all fields are fully validated for proper JSON escaping and formatting. Avoid any control characters like newlines or tabs within the strings.

            Ensure that the questions generated are different from the ones generated in the previous request.
        
            The response must be in ${language} and should reflect diverse and unique question formulations for this topic.

            Return only the JSON array, formatted correctly, without additional commentary or explanation.
        `;

        let success = false;
        const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        const getUniqueQuestions = (
            existingQuestions: QuestionForm[],
            newQuestions: QuestionForm[]
        ): QuestionForm[] => {
            const existingSet = new Set(existingQuestions.map(q => `${q.question}-${q.type}`));
        
            return newQuestions.filter(q => !existingSet.has(`${q.question}-${q.type}`));
        };

        let allQuestions: QuestionForm[] = [];

        while (!success) {
            try {
                for (let i = 0; i < 1; i++) {
                    console.log(`Fetching batch ${i + 1}...`);
                    const newQuestions = await fetchQuestionsToAI(requestAI);
                    const uniqueQuestions = getUniqueQuestions(allQuestions, newQuestions);

                    allQuestions = [...allQuestions, ...uniqueQuestions];
                }

                allQuestions.forEach((question) => {
                    question.numberPoints = questionPoints[question.type];

                    if (question.type !== 'singleChoice' && question.type !== 'multipleChoice') {
                        question.options = [];
                    }
                });

                console.log("Final unique questions:", allQuestions);
                setQuestions(allQuestions);
                success = true;
            } catch (error) {
                console.error("Error fetching questions:", error);
                allQuestions = [];
                await sleep(3000);
            }
        }

        setLoading(false);
    };

    const fetchQuestionsToAI = async (requestAI: string): Promise<QuestionForm[]> => {
        const genAI = new GoogleGenerativeAI(API_AI_KEY);
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

        const result = await model.generateContent(requestAI);

        const rawResponse = result.response.text().trim();
        const jsonStart = rawResponse.indexOf("[");
        const jsonEnd = rawResponse.lastIndexOf("]") + 1;

        if (jsonStart === -1 || jsonEnd === 0) {
            throw new Error("Response does not contain a valid JSON array.");
        }

        const cleanedResponse = rawResponse.substring(jsonStart, jsonEnd);

        return JSON.parse(cleanedResponse) as QuestionForm[];
    };

    const handleFormChange = (changedValues: any) => {
        setFormData((prev: any) => ({
            ...prev,
            ...changedValues,
        }));
    };

    const transformDataToTest = (formData: TestForm): Test => {
        const skillFounded = applicationStore.skills.find(s => s.title === formData.selectedSkill);

        return {
            id: 0,
            name: formData.name,
            level: formData.level,
            language: formData.isOneLanguage ? formData.selectedLanguage : 'ua',
            description: formData.description,
            numberOfQuestions: questions.length,
            skillId: Number(skillFounded?.skillId) ?? null,
            companyId: Number(applicationStore.company.Id) ?? null,
            isPublic: false,
            numberOfAttempts: formData.numberOfAttempts,
            maxTimeMinutes: formData.maxTime,
            testQuestions: questions.map((q, questionIndex) => ({
                id: 0,
                code: q.code ?? null,
                numberPoints: q.numberPoints ?? 1,
                correctAnswer: Array.isArray(q.correctAnswer) ? q.correctAnswer.join(", ") : q.correctAnswer ?? null,
                prompt: q.prompt ?? null,
                typeTestQuestionId: q.type === "singleChoice"
                    ? TypeTestQuestion.SingleChoice
                    : q.type === "multipleChoice"
                        ? TypeTestQuestion.MultipleChoice
                        : q.type === "writeCode"
                            ? TypeTestQuestion.WriteCode
                            : q.type === "fixCode"
                                ? TypeTestQuestion.FixCode
                                : q.type === "openAnswer"
                                    ? TypeTestQuestion.OpenAnswer
                                    : TypeTestQuestion.SingleChoice,
                name: q.question,
                testId: 0,
                testOptions: q.options == null ? [] : q.options.map((option, optionIndex) => ({
                    id: 0,
                    name: option,
                    isCorrect: Array.isArray(q.correctAnswer)
                        ? q.correctAnswer.includes(option)
                        : option == q.correctAnswer,
                    testQuestionId: 0,
                })),
            })),
        };
    };


    const handleFinish = async () => {
        if(questions.length >= levelSettings.numberQuestions){
            const addTestModel = transformDataToTest(formData);
            console.log(addTestModel);
    
            await testsStore.createNew(addTestModel);
    
            message.success("add_test.testCreatedSuccess");
            navigateToTests();
        }
        else{
            message.error(
                t("add_test.minimum_questions_error", { minimum: 5 })
            );
        }
    };

    const navigateToTests = () => {
        navigate(`/${applicationStore.company.Tenant}/tests`);
    }

    const handleLevelChange = (value: string) => {

        if (!testSettings) {
            return;
        }

        let level : TestLevelSetting = testSettings["junior"];

        switch (value) {
            case "junior":
                level = testSettings["junior"];
                break;
            case "middle":
                level = testSettings["middle"];
                break;
            case "senior":
                level = testSettings["senior"];
                break;
            default:
                break;
        }

        setLevelSettings({  maxTimeMin: level.minTime, attemptsMin: level.minNumberAttempts, numberQuestions: level.minNumberOfQuestons });
        formTest.setFieldsValue({
            maxTime: level.minTime,
            numberOfAttempts: level.minNumberAttempts,
            numberOfTests: level.minNumberOfQuestons
        });

        setFormData((prevValue) => ({
            ...prevValue,
            maxTime: level.minTime,
            numberOfAttempts: level.minNumberAttempts,
            numberOfTests: level.minNumberOfQuestons
        }));
    };

    return (
        <Layout
            headerStyle={1}
            footerStyle={2}
            breadcrumbTitle={t("add_test.addTest")}
        >
            <Spin spinning={loading} style={{ minHeight: 600 }}>
                <div className="text-center" style={{ margin: '20px 20px' }}>
                    <Steps current={currentStep}>
                        <Step title={t("add_test.Step1")} />
                        <Step title={t("add_test.Step2")} />
                    </Steps>

                    <div style={{ padding: "20px" }}>
                        {currentStep === 0 && (
                            <Form
                                form={formTest}
                                layout="vertical"
                                onFinish={handleFinish}
                                onValuesChange={handleFormChange}
                                initialValues={formData}
                                style={{ maxWidth: "600px", margin: "0 auto" }}
                            >
                                <Form.Item
                                    label={t("add_test.Name")}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("add_test.Please input the name!"),
                                        },
                                    ]}
                                >
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>

                                <Form.Item
                                    label={t("add_test.NumberQuestions")}
                                    name="numberOfTests"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("add_test.Please input the number of tests!"),
                                        },
                                        {
                                            type: "number",
                                            min: 1,
                                            message: t("add_test.NumberGreaterThan0"),
                                        },
                                        {
                                            type: "number",
                                            min: levelSettings.attemptsMin,
                                            message: t(`add_test.MinimumQuestionsIs ${levelSettings.attemptsMin}`),
                                        },
                                    ]}
                                >
                                    <InputNumber style={{ width: "100%" }} min={levelSettings.numberQuestions} />
                                </Form.Item>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                    }}
                                >
                                    <Form.Item
                                        label={t("add_test.SelectSkill")}
                                        name="selectedSkill"
                                        rules={[{ required: true, message: t("add_test.Please select a skill!") }]}
                                        style={{ flex: "1 1 48%" }}
                                    >
                                        <Select
                                            style={{ color: "black", width: "100%" }}
                                            placeholder={t("add_test.SelectSkill")}
                                            options={applicationStore.skills.map((skill: Skill) => ({
                                                label: skill.title,
                                                value: skill.title,
                                            }))}
                                            dropdownStyle={{ color: "black" }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t("add_test.Level")}
                                        name="level"
                                        rules={[{ required: true, message: t("add_test.Please select a level!") }]}
                                        style={{ flex: "1 1 48%" }}
                                    >
                                        <Select
                                            style={{ color: "black", width: "100%" }}
                                            options={[
                                                { label: "Junior", value: "junior" },
                                                { label: "Middle", value: "middle" },
                                                { label: "Senior", value: "senior" }
                                            ]}
                                            onChange={handleLevelChange}
                                            dropdownStyle={{ color: "black" }}
                                        />
                                    </Form.Item>


                                </div>

                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <Form.Item name="isOneLanguage" valuePropName="checked" style={{ textAlign: "left" }}>
                                        <Checkbox name="isOneLanguage">
                                            {t("add_test.GenerateForOneLanguage")}
                                        </Checkbox>
                                    </Form.Item>

                                    {formData.isOneLanguage && (
                                        <Form.Item
                                            name="selectedLanguage"
                                            rules={[{ required: true, message: t("add_test.SelectLanguage") }]}
                                        >
                                            <Select placeholder={t("add_test.ChooseLanguage")} style={{ width: "200px" }}>
                                                <Select.Option value="ua">{t("add_test.Ukrainian")}</Select.Option>
                                                <Select.Option value="ru">{t("add_test.Russian")}</Select.Option>
                                                <Select.Option value="en">{t("add_test.English")}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                </div>



                                <Form.Item
                                    name="withAI"
                                    valuePropName="checked"
                                    style={{ textAlign: "left" }}
                                >
                                    <Checkbox>{t("add_test.UseAIForGeneration")}</Checkbox>
                                </Form.Item>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                    }}
                                >
                                    <Form.Item
                                        label={t("add_test.NumberAttempts")}
                                        name="numberOfAttempts"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("add_test.Please input the number of attempts!"),
                                            },
                                            {
                                                type: "number",
                                                min: 1,
                                                message: t("add_test.NumberGreaterThan0"),
                                            },
                                            {
                                                type: "number",
                                                min: levelSettings.attemptsMin,
                                                message: t(`add_test.MinimumAttemptsIs ${levelSettings.attemptsMin}`),
                                            },
                                        ]}
                                        style={{ flex: "1 1 48%" }}
                                    >
                                        <InputNumber style={{ width: "100%" }} min={levelSettings.attemptsMin} />
                                    </Form.Item>

                                    <Form.Item
                                        label={`${t("add_test.MaxTime")} (${t("add_test.minutes")})`}
                                        name="maxTime"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("add_test.Please input the maximum time!"),
                                            },
                                            {
                                                type: "number",
                                                min: 1,
                                                message: t("add_test.NumberGreaterThan0"),
                                            },
                                            {
                                                type: "number",
                                                min: levelSettings.maxTimeMin,
                                                message: t(`add_test.MinimumTimeIs ${levelSettings.maxTimeMin} ${t("add_test.minutes")}`),
                                            },
                                        ]}
                                        style={{ flex: "1 1 48%" }}
                                    >
                                        <InputNumber style={{ width: "100%" }} min={levelSettings.maxTimeMin} />
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    label={t("add_test.Description")}
                                    name="description"
                                >
                                    <Input.TextArea
                                        rows={4}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        type="primary"
                                        onClick={navigateToTests}
                                        style={{ backgroundColor: "red", borderColor: "red", color: "#fff" }}
                                    >
                                        {t("add_test.Previous")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={async () => await handleNext()}
                                    >
                                        {t("add_test.Next")}
                                    </Button>
                                </div>
                            </Form>
                        )}

                        {currentStep === 1 && (
                            <AddTestFormQustions handleFinish={handleFinish} questions={questions} setQuestions={setQuestions} />
                        )}
                    </div>
                </div>
            </Spin>
        </Layout>
    );
});

export default AddTest;