import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout"
import { useStore } from "../stores/root-store-context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Roles } from "../stores/EmployeesStore";
import { Button, Checkbox, Col, Flex, Form, FormProps, Input, message, Row, Select, SelectProps, Spin, Tag, Upload, UploadFile } from "antd";
import { DeleteOutlined, SettingOutlined, EditOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import ImgCrop from "antd-img-crop";
import { useEffect, useRef, useState } from "react";
import { UploadChangeParam } from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import { Company } from "../stores/ApplicationStore";
import { customItemRender } from './../helpers/upload';

type TagRender = SelectProps['tagRender'];

const CompanySettings = observer(() => {
    const { applicationStore, employeesStore } = useStore();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [messageApi] = message.useMessage();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const navigate = useNavigate();
    const uploadRef = useRef<any>(null);
    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const onFinish: FormProps<any>["onFinish"] = async (
        company: Company
    ) => {
        console.log(company);
        const foo = form.getFieldsValue();
        console.log(foo);
        const prevCompany = applicationStore.company;

        messageApi.open({
            type: "loading",
            content: "Saving...",
            key: "saving",
        });

        if (fileList.length > 0 && fileList[0].uid !== "-1") {
            await applicationStore.uploadPhoto(fileList[0], applicationStore.company?.Id || "");
        }

        applicationStore.editCompany(company).then((res) => {
            messageApi.destroy("saving");
            messageApi.success("Successful saved!");
            applicationStore.updateCompany();
        }).finally(() => {

        })

    };

    const fileChange = (info: UploadChangeParam) => {
        let fileList = [...info.fileList];

        // Limit to one file
        fileList = fileList.slice(-1);

        // Only show the last file
        setFileList(fileList);

        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    useEffect(() => {
        form.setFieldsValue(applicationStore.company);

        if (applicationStore.company?.LogoId) {
            setFileList([
                {
                    uid: "-1",
                    name: `${applicationStore.company?.LogoId}`,
                    status: "done",
                    url: `https://res.cloudinary.com/dnl3x07wo/image/upload/${applicationStore.company?.LogoId}`,
                },
            ]);
        }
    }, [applicationStore.company]);

    const optionsDays: SelectProps['options'] = [
        { value: 0, label: 'Sunday' },
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
    ];

    const tagRender: TagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={"error"}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginInlineEnd: 4 }}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div className="container" style={{ marginTop: 20, marginBottom: 100, maxWidth: 600 }}>
                <Flex className="container" gap={10} style={{ marginTop: 20 }}>
                    <Button
                        style={{ float: "left", borderRadius: 0 }}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Back
                    </Button>
                </Flex>
                <Spin spinning={applicationStore.isLoading}>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item<any>
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Flex justify="center" vertical align="center">
                                <Upload
                                    ref={uploadRef}
                                    style={{ alignSelf: "center", width: 300, height: 300 }}
                                    accept="image/*"
                                    maxCount={1}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={fileChange}
                                    beforeUpload={() => false}
                                    showUploadList={{
                                        showPreviewIcon: true,
                                        showRemoveIcon: true,
                                        showDownloadIcon: true,
                                        downloadIcon: (
                                            <UploadOutlined style={{ color: "white" }} onClick={() => {

                                            }} />
                                        )
                                    }}
                                >
                                    {fileList.length === 0 && (
                                        <Button icon={<UploadOutlined />}></Button>
                                    )}
                                </Upload>
                                {fileList.length > 0 && (
                                    <Button style={{ marginTop: 10 }} icon={<UploadOutlined />} onClick={() => {
                                        if (uploadRef.current) {
                                            const input = document.querySelector('input[type="file"]') as HTMLElement;
                                            if (input) {
                                                input.click();
                                            }
                                        }
                                    }}>{t('Upload')}</Button>
                                )}
                            </Flex>
                        </Form.Item>
                        <Form.Item<any>
                            name="Id"
                        >
                            <Input hidden />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("CompanyName")}`}
                            name="Title"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder={`${t("CompanyName")}`} />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("Tenaut")}`}
                            name="Tenaut"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input disabled placeholder={`${t("Tenaut")}`} />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("CompanyDescription")}`}
                            name="Description"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <TextArea placeholder={`${t("CompanyDescription")}`} rows={4} />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("Address")}`}
                            name="Adress"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <TextArea placeholder={`${t("Address")}`} rows={4} />
                        </Form.Item>
                        <Form.Item
                            label={`${t("CompanyNotificationLanguage")}`}
                            name="NotificationLanguage"
                            style={{ width: "100%" }}
                            rules={[{ required: true, message: t("NotificationLanguageMessage") }]}>
                            <Select placeholder={`${t("NotificationLanguagePlaceHolder")}`}>
                                <Select.Option value="en">{t("EnLang")}</Select.Option>
                                <Select.Option value="ru">{t("RuLang")}</Select.Option>
                                <Select.Option value="uk">{t("UkLang")}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("Currency")}`}
                            name="Currency"
                            style={{ width: "100%" }}
                            rules={[{ required: true, message: t("CurrencyMessage") }]}
                        >
                            <Select placeholder={`${t("CurrencyPlaceHolder")}`}>
                                <Select.Option value="$">$ USD - United States Dollar</Select.Option>
                                <Select.Option value="€">€ EUR - Euro</Select.Option>
                                <Select.Option value="₴">₴ UAH - Ukrainian Hryvnia</Select.Option>
                                <Select.Option value="£">£ GBP - British Pound</Select.Option>
                                {/* Додайте інші валюти за необхідністю */}
                            </Select>
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("WeekendDays")}`}
                            name="WeekendDays"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Select
                                mode="multiple"
                                tagRender={tagRender}
                                style={{ width: '100%' }}
                                options={optionsDays}
                            />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("vacationWorkHours")}`}
                            name="HoursVacation"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder={`${t("vacationWorkHours")}`} type="number" max={24} />
                        </Form.Item>
                        <Form.Item<any>
                            label={`${t("sickLeaveWorkHours")}`}
                            name="HoursSickLeave"
                            style={{ width: "100%" }}
                            rules={[{ required: true }]}
                        >
                            <Input placeholder={`${t("sickLeaveWorkHours")}`} type="number" max={24} />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                className="theme-btn"
                                htmlType="submit"
                                style={{ height: 60, display: "flex", float: "right" }}
                            >
                                {`${t("Save")}`}{" "}
                                <SaveOutlined style={{ fontSize: 20, margin: 5 }} />
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
        </Layout>
    )
})
export default CompanySettings;