import { observer } from "mobx-react-lite";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Button, Form, Input, message, Modal, Select, Spin } from "antd";
import { LevelSettings, QuestionForm } from "../../pages/Tests/AddTest";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../stores/root-store-context";
import { TestAnswer, TestAttempt, UserTestAssignment } from "../../stores/TestsStore";
import { TypeTestQuestion } from "../../stores/ApplicationStore";
import Layout from "../../components/layout/Layout";

export const UserAnswersTest = observer(({ }: {}) => {
    const { t } = useTranslation();
    const [existAssignment, setExistAssignment] = useState<UserTestAssignment>();
    const [existAttempt, setExistAttempt] = useState<TestAttempt>();
    const [existAnswer, setExistAnswer] = useState<TestAnswer[]>();
    const { id, assignmentId } = useParams();
    const { testsStore, applicationStore } = useStore();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const assignment = await testsStore.getAssignmentById(assignmentId ?? "0");
            setExistAssignment(assignment);

            const existAttempt = assignment.testAttempts.find(attempt => attempt.id == Number(id));
            setExistAnswer(existAttempt?.testAnswers);
            setExistAttempt(existAttempt);
            setLoading(false);
        };

        fetchData();
    }, []);

    const getSelectedOptions = (questionId: number) => {
        const answers = existAnswer?.find(answer => answer.testQuestionId === questionId)?.answer;
        return answers ? answers.split(',').map(optionId => Number(optionId.trim())) : [];
    };

    const getAnswerForQuestion = (questionId: number) => {
        const answer = existAnswer?.find(answer => answer.testQuestionId === questionId);
        return answer ? answer.answer : "";
    };

    return (
        <Layout headerStyle={1} footerStyle={2} breadcrumbTitle={t("tests.result_test")}>
            <Spin spinning={loading} style={{ minHeight: 600 }}>
                <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px" }}>

                    {existAttempt && (
                        <div style={{ marginBottom: "20px", padding: "15px", border: "1px solid #e0e0e0", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
                            <p><strong>{t("tests.attemptNumber")}:</strong> {existAttempt.numberAttempt}</p>
                            <p><strong>{t("tests.grade")}:</strong> {existAttempt.percentage}%</p>
                            <p><strong>{t("Start Date")}:</strong> {new Date(existAttempt.startDate).toLocaleString('uk-UA', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            }).replace(/,/g, '')}</p>
                            <p><strong>{t("tests.finishDate")}:</strong> {new Date(existAttempt.finishDate).toLocaleString('uk-UA', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            }).replace(/,/g, '')}</p>
                            <p><strong>{t("tests.timeSpent")}:</strong> {existAttempt.timeSpentMinutes ? `${existAttempt.timeSpentMinutes} ${t("add_test.minutes")}` : t("tests.NotAvailable")}</p>
                        </div>
                    )}

                    {existAssignment?.test?.testQuestions
                        ?.filter((question) => 
                            existAnswer?.map((answer) => answer.testQuestionId).includes(question.id)
                        )
                        .map((question, index) => (
                            <div
                                key={index}
                                style={{
                                    marginBottom: "30px",
                                    padding: "20px",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "8px",
                                    backgroundColor: "#fafafa",
                                    position: "relative",
                                }}
                            >
                                <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                                    {`${t("add_test.Question")} ${index + 1}: ${question.name}`}
                                </div>

                                {(question.typeTestQuestionId === TypeTestQuestion.SingleChoice ||
                                    question.typeTestQuestionId === TypeTestQuestion.MultipleChoice) && (
                                        <div style={{ marginTop: "15px" }}>
                                            {question.testOptions?.map((option, optionIndex) => {
                                                const isSelected = getSelectedOptions(question.id).includes(option.id);
                                                return (
                                                    <div
                                                        key={optionIndex}
                                                        style={{
                                                            marginBottom: "5px",
                                                            padding: "10px",
                                                            border: "1px solid",
                                                            borderColor: isSelected ? "#28a745" : "#dc3545",
                                                            backgroundColor: isSelected ? "#d4edda" : "#f8d7da",
                                                            borderRadius: "4px",
                                                        }}
                                                    >
                                                        {`${t("add_test.Option")} ${optionIndex + 1}: ${option.name}`}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                {question.typeTestQuestionId === TypeTestQuestion.WriteCode && (
                                    <div style={{ marginTop: "10px" }}>
                                        <strong>{t("add_test.WriteCodePrompt")}:</strong>
                                        <pre style={{ backgroundColor: "#f4f4f4", padding: "10px", borderRadius: "4px" }}>
                                            {getAnswerForQuestion(question.id) || t("tests.NoAnswer")}
                                        </pre>
                                    </div>
                                )}

                                {question.typeTestQuestionId === TypeTestQuestion.OpenAnswer && (
                                    <div style={{ marginTop: "10px" }}>
                                        <strong>{t("add_test.WriteOpenAnswer")}:</strong>
                                        <p>{getAnswerForQuestion(question.id) || t("tests.NoAnswer")}</p>
                                    </div>
                                )}

                                {question.typeTestQuestionId === TypeTestQuestion.FixCode && (
                                    <div style={{ marginTop: "10px" }}>
                                        <strong>{t("add_test.Code")}:</strong>
                                        <pre style={{ backgroundColor: "#f4f4f4", padding: "10px", borderRadius: "4px" }}>
                                            {getAnswerForQuestion(question.id) || t("tests.NoAnswer")}
                                        </pre>
                                    </div>
                                )}
                            </div>
                        ))}

                    <div style={{ textAlign: "center" }}>
                        <Button style={{ marginBottom: 20 }} type="primary" onClick={() => navigate(`/${applicationStore.company.Tenant}/tests`)}>
                            {t("passage_test.back_to_tests")}
                        </Button>
                    </div>
                </div>
            </Spin>
        </Layout>
    );
});