import { observer } from "mobx-react-lite"
import Layout from "../components/layout/Layout"
import { useStore } from "../stores/root-store-context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Roles } from "../stores/EmployeesStore";
import { Button, Flex, Image } from "antd";
import { DeleteOutlined, SettingOutlined, EditOutlined, SaveOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useEffect } from "react";

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    spaceBetween: 30,
    speed: 1500,
    loop: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".array-prev",
        prevEl: ".array-next",
    },

    breakpoints: {
        1199: {
            slidesPerView: 4,
        },
        991: {
            slidesPerView: 2,
        },
        767: {
            slidesPerView: 2,
        },
        575: {
            slidesPerView: 2,
        },
        0: {
            slidesPerView: 1,
        },
    },
}
const CompanyHome = observer(() => {
    const { applicationStore, paymentStore} = useStore();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isTrialPeriod = dayjs(paymentStore.trialDate).isSame(dayjs(paymentStore.paymentDate));
    const dateMessage = isTrialPeriod
      ? `${t('trialPeriodEnds')} ${dayjs(paymentStore.trialDate).format('YYYY.MM.DD')}`
      : `${t('nextPaymentDate')} ${dayjs(paymentStore.paymentDate).format('YYYY.MM.DD')}`;

        useEffect(() => {
            if (applicationStore.company?.Id) {
                paymentStore.getPaymentDate(applicationStore.company.Id);
            }
        }, [applicationStore.company, paymentStore]);

    return (
        <Layout headerStyle={1} footerStyle={2} >
            <div className="text-center" style={{ margin: "100px 0" }}>
            </div>

            <section className="service-section fix section-padding bg-cover" style={{ backgroundImage: 'url("assets/img/service/service-bg.jpg")' }} id="service">
                <div className="container">
                    <Flex style={{ width: "100%" }} justify="flex-end">
                        {(applicationStore.user.roleId === Roles.Owner) &&
                            <Button size="large" type="primary" shape="circle" icon={<SettingOutlined />}
                                onClick={() => {
                                    navigate(`/${applicationStore.company.Tenant}/settings`);
                                }} />
                        }
                    </Flex>
                    {
                        applicationStore?.company.LogoId &&
                        <Flex justify="center" style={{ marginBottom: '20px' }}>
                            <Image
                                height={160}
                                style={{ objectFit: "cover", minWidth: 160 }}
                                src={
                                    `https://res.cloudinary.com/dnl3x07wo/image/upload/${applicationStore?.company.LogoId}`
                                }
                            />
                        </Flex>
                    }

                    <div className="section-title-area text-center">
                        <div className="section-title col-lg-4">
                            <span className="wow fadeInUp">{t("YourCompany")}</span>
                            <h3 className="wow fadeInUp" data-wow-delay=".3s">
                                {applicationStore.company.Title}
                            </h3>

                        </div>
                        <div className="section-title col-lg-4">
                            <span className="wow fadeInUp">{t("YourTenant")}</span>
                            <h3 className="wow fadeInUp" data-wow-delay=".3s">
                                {applicationStore.company.Tenant}
                            </h3>
                        </div>
                        <div className="section-title col-lg-4">
                            <span className="wow fadeInUp">{t("Description")}</span>
                            <h4 className="wow fadeInUp" data-wow-delay=".3s">
                                {applicationStore.company.Description}
                            </h4>
                        </div>

                    </div>
                    <div className="array-button">
                        <button className="array-prev"><i className="fal fa-arrow-right" /></button>
                        <button className="array-next"><i className="fal fa-arrow-left" /></button>
                    </div>

                    <div className="service-wrapper">
                        <div className="swiper service-slider">
                            <Swiper {...swiperOptions}  className="swiper-wrapper">
                                <SwiperSlide >
                                    <div className="service-box-items">
                                        <div className="icon">
                                            <img src="/assets/img/service/icon/s-icon-1.svg" alt="icon-img" />
                                        </div>
                                        <div className="content">
                                            <h4 style={{textTransform:"none", overflow:"hidden", textOverflow:"ellipsis"}}>
                                                <Link to={`/${applicationStore.company.Tenant}/employees/${applicationStore.user.id}`}>
                                                    {applicationStore.user.email}
                                                </Link>
                                            </h4>
                                            <p>
                                                {t("Read_and_update_information")}
                                            </p>
                                            <Link to={`/${applicationStore.company.Tenant}/employees/${applicationStore.user.id}`} className="theme-btn-2 mt-3">
                                                {t("Open_Your_Profile")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="service-box-items">
                                        <div className="icon">
                                            <img src="/assets/img/service/icon/s-icon-9.svg" alt="icon-img" />
                                        </div>
                                        <div className="content">
                                            <h4>
                                                <Link to={`/${applicationStore.company.Tenant}/employees`}>
                                                    <span>{applicationStore.company.UsersCount}</span> {t("Employees")}
                                                </Link>
                                            </h4>
                                            <p>
                                                {t("Profiles_employees_your_company")}
                                            </p>
                                            <Link to={`/${applicationStore.company.Tenant}/employees`} className="theme-btn-2 mt-3">
                                                {t("Open_All_Users")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="service-box-items">
                                        <div className="icon">
                                            <img src="/assets/img/service/icon/s-icon-10.svg" alt="icon-img" />
                                        </div>
                                        <div className="content">
                                            <h4>
                                                <Link to={`/${applicationStore.company.Tenant}/projects`}>
                                                    <span>{applicationStore.company.ProjectCount}</span> {t("Projectses")}
                                                </Link>
                                            </h4>
                                            <p>
                                                {t("Projects_yours_company")}
                                            </p>
                                            <Link to={`/${applicationStore.company.Tenant}/projects`} className="theme-btn-2 mt-3">
                                                {t("Open_All_Projects")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="service-box-items">
                                        <div className="icon">
                                            <img src="/assets/img/service/icon/s-icon-12.svg" alt="icon-img" />
                                        </div>
                                        <div className="content">
                                            <h4>
                                                <Link to={`/${applicationStore.company.Tenant}/tests`}>
                                                    {t("Skill_testing")}
                                                </Link>
                                            </h4>
                                            <p>
                                                {t("Approve_your_skills")}
                                            </p>
                                            <Link to={`/${applicationStore.company.Tenant}/user`} className="theme-btn-2 mt-3">
                                                {t("Approve_your_skills")}
                                                <i className="fa-solid fa-arrow-right-long" />
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            {(applicationStore.user.roleId === Roles.Owner && applicationStore.company.TariffPlanId !== 5)  && (
                                <div className="notification-message" style={{
                                        textAlign: 'center',
                                        backgroundColor: '#ffffff',
                                        padding: '20px',
                                        marginTop: '20px',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        color: '#0F0D1D'
                                    }}>
                                    {dateMessage}
                                </div>
                            )}
                        </div>
                        {/* <div className="service-text wow fadeInUp" data-wow-delay=".4s">
                            <h6>
                                Need Any Kind Of IT Solution For Your Business.  <Link to="/service">View Services </Link>
                            </h6>
                        </div> */}
                    </div>
                </div>
            </section>
        </Layout>
    )
})
export default CompanyHome;