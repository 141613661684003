import React, { useEffect, useState } from "react";
import { Button, Card, Space, Typography, Checkbox } from "antd";
import { Test } from "../../stores/TestsStore";
import { useTranslation } from "react-i18next";
import { TypeTestQuestion } from "../../stores/ApplicationStore";

const { Text, Title } = Typography;

interface StepTwoProps {
    test: Test;
    onFinish: (answers: Array<{ questionId: number; answer: string | number | number[]; }>, minutesSpent: number) => void;
}

const TestStepTwo: React.FC<StepTwoProps> = ({ test, onFinish }) => {
    const { t } = useTranslation();
    const [currentTime, setCurrentTime] = useState(test.maxTimeMinutes * 60);
    const [answers, setAnswers] = useState<
        Array<{
            questionId: number;
            answer: string | number | number[];
        }>
    >([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(timer);
                    handleFinish();
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleFinish = () => {
        const minutesSpent = Math.round((test.maxTimeMinutes * 60 - currentTime) / 60);
        onFinish(answers, minutesSpent);
    };

    const handleAnswerChange = (questionId: number, newAnswer: string | number | number[]) => {
        setAnswers((prev) => {
            const existingAnswer = prev.find((a) => a.questionId === questionId);
            if (existingAnswer) {
                return prev.map((a) => (a.questionId === questionId ? { ...a, answer: newAnswer } : a));
            } else {
                return [...prev, { questionId, answer: newAnswer }];
            }
        });
    };

    const currentQuestion = test.testQuestions[currentQuestionIndex];

    return (
        <Card style={{ maxWidth: '60%', margin: "0 auto", textAlign: "left", padding: "20px 15px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <div style={{ textAlign: "center" }}>
                    <Text strong style={{ fontSize: "16px", color: "#555" }}>
                        {t("passage_test.time_remaining")}: {new Date(currentTime * 1000).toISOString().substr(14, 5)}
                    </Text>
                </div>

                <div key={currentQuestion.id} style={{ marginBottom: "20px" }}>
                    <Text style={{ fontWeight: 500, fontSize: "16px", color: "#333" }}>
                        {currentQuestion.name}
                    </Text>
                    <div style={{ marginTop: "20px" }}></div>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {(() => {
                            const currentAnswer = answers.find((a) => a.questionId === currentQuestion.id)?.answer;

                            switch (currentQuestion.typeTestQuestionId) {
                                case TypeTestQuestion.SingleChoice:
                                    return currentQuestion.testOptions.map((option) => (
                                        <Button
                                            key={option.id}
                                            onClick={() =>
                                                setAnswers((prev) =>
                                                    prev.some((a) => a.questionId === currentQuestion.id)
                                                        ? prev.map((a) =>
                                                            a.questionId === currentQuestion.id
                                                                ? { ...a, answer: option.id }
                                                                : a
                                                        )
                                                        : [...prev, { questionId: currentQuestion.id, answer: option.id }]
                                                )
                                            }
                                            type={currentAnswer === option.id ? "primary" : "default"}
                                            size="middle"
                                            style={{
                                                fontSize: "14px",
                                                width: "40%",
                                                height: "auto",
                                                whiteSpace: "normal",
                                                wordWrap: "break-word",
                                                wordBreak: "break-word",
                                                textAlign: "left",
                                            }}
                                        >
                                            {option.name}
                                        </Button>
                                    ));
                                case TypeTestQuestion.MultipleChoice:
                                    return currentQuestion.testOptions.map((option) => (
                                        <Checkbox
                                            key={option.id}
                                            checked={(currentAnswer as number[] | undefined)?.includes(option.id)}
                                            onChange={() =>
                                                setAnswers((prev) =>
                                                    prev.some((a) => a.questionId === currentQuestion.id)
                                                        ? prev.map((a) =>
                                                            a.questionId === currentQuestion.id
                                                                ? {
                                                                    ...a,
                                                                    answer: (a.answer as number[]).includes(option.id)
                                                                        ? (a.answer as number[]).filter((id) => id !== option.id)
                                                                        : [...(a.answer as number[]), option.id],
                                                                }
                                                                : a
                                                        )
                                                        : [
                                                            ...prev,
                                                            { questionId: currentQuestion.id, answer: [option.id] },
                                                        ]
                                                )
                                            }
                                            style={{ fontSize: "14px", color: "#333" }}
                                        >
                                            {option.name}
                                        </Checkbox>
                                    ));
                                case TypeTestQuestion.WriteCode:
                                case TypeTestQuestion.FixCode:
                                case TypeTestQuestion.OpenAnswer:
                                    return (
                                        <textarea
                                            style={{
                                                width: "100%",
                                                height: currentQuestion.typeTestQuestionId === TypeTestQuestion.OpenAnswer ? "100px" : "150px",
                                                fontSize: "14px",
                                            }}
                                            value={
                                                typeof currentAnswer === "string"
                                                    ? currentAnswer
                                                    : currentQuestion.typeTestQuestionId === TypeTestQuestion.FixCode ||
                                                    currentQuestion.typeTestQuestionId === TypeTestQuestion.WriteCode
                                                        ? currentQuestion.code || ""
                                                        : ""
                                            }
                                            onChange={(e) =>
                                                setAnswers((prev) =>
                                                    prev.some((a) => a.questionId === currentQuestion.id)
                                                        ? prev.map((a) =>
                                                            a.questionId === currentQuestion.id
                                                                ? { ...a, answer: e.target.value }
                                                                : a
                                                        )
                                                        : [...prev, { questionId: currentQuestion.id, answer: e.target.value }]
                                                )
                                            }
                                            placeholder={
                                                currentQuestion.typeTestQuestionId === TypeTestQuestion.FixCode
                                                    ? "Исправьте код здесь..."
                                                    : currentQuestion.typeTestQuestionId === TypeTestQuestion.WriteCode
                                                        ? "Введите ваш код здесь..."
                                                        : "Введите ваш ответ здесь..."
                                            }
                                        />
                                    );
                                default:
                                    return <Text style={{ color: "red" }}>Неизвестный тип вопроса</Text>;
                            }
                        })()}
                    </Space>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <Button
                        onClick={() => setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))}
                        disabled={currentQuestionIndex === 0}
                    >
                        {t("passage_test.previous")}
                    </Button>
                    <Button
                        onClick={() => setCurrentQuestionIndex((prev) => Math.min(prev + 1, test.testQuestions.length - 1))}
                        disabled={currentQuestionIndex === test.testQuestions.length - 1}
                    >
                        {t("passage_test.next")}
                    </Button>
                </div>

                {currentQuestionIndex === test.testQuestions.length - 1 && (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            type="primary"
                            size="middle"
                            style={{ marginTop: "20px", fontSize: "18px", width: "auto" }}
                            onClick={handleFinish}
                        >
                            {t("passage_test.finish")}
                        </Button>
                    </div>
                )}
            </Space>
        </Card>
    );
};

export default TestStepTwo;